export enum EffectCategory {
  INFLAMMATION = 'INFLAMMATION',

  OXIDATIVE_STRESS = 'OXIDATIVE_STRESS',

  GENOTOXICITY = 'GENOTOXICITY',

  // CELLULAR_PARAMETER_ACUTE_TOXICITY = 'CELLULAR_PARAMETER_ACUTE_TOXICITY',

  // MACROMOLECULE_REGULATION = 'MACROMOLECULE_REGULATION',

  // ENZYME_ACTIVITY = 'ENZYME_ACTIVITY',

  // CELLULAR_PROCESS = 'CELLULAR_PROCESS',

  STRESS_RESPONSE = 'STRESS_RESPONSE',

  // SIGNALLING_PATHWAY = 'SIGNALLING_PATHWAY',

  SYSTEMIC_MECHANISM = 'SYSTEMIC_MECHANISM',
}

export const EffectCategoryPath = {
  INFLAMMATION: 'inflammation',
  OXIDATIVE_STRESS: 'oxidative-stress',
  GENOTOXICITY: 'genotoxicity',
  CELLULAR_PARAMETER_ACUTE_TOXICITY: 'cellular-parameter-acute-toxicity',
  MACROMOLECULE_REGULATION: 'macromolecule-regulation',
  ENZYME_ACTIVITY: 'enzyme-activity',
  CELLULAR_PROCESS: 'cellular-process',
  STRESS_RESPONSE: 'stress-response',
  SIGNALLING_PATHWAY: 'signalling-pathway',
  SYSTEMIC_MECHANISM: 'systemic-mechanism',
};
