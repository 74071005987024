import { IUser } from 'app/shared/model/user.model';
import { ITissueType } from 'app/shared/model/tissue-type.model';
import { IOrgan } from 'app/shared/model/organ.model';
import { IAdvancedMaterial } from 'app/shared/model/advanced-material.model';
import { IOrigin } from 'app/shared/model/origin.model';
import { IApplication } from 'app/shared/model/application.model';
import { ICellCulture } from 'app/shared/model/cell-culture.model';
import { Shape } from 'app/shared/model/enumerations/shape.model';
import { AnimalOrCell } from 'app/shared/model/enumerations/animal-or-cell.model';
import { QualityScore } from './enumerations/quality-score.model';

export interface ISearchQuery {
  id?: number;
  materialShape?: Shape[] | null;
  materialSizeFrom?: number | null;
  materialSizeTo?: number | null;
  materialLayersFrom?: number | null;
  materialLayersTo?: number | null;
  materialLengthFrom?: number | null;
  materialLengthTo?: number | null;
  agglomerationSizeFrom?: number | null;
  agglomerationSizeTo?: number | null;
  betFrom?: number | null;
  betTo?: number | null;
  crystallinity?: string | null;
  coatingOrFunctionalization?: string | null;
  animalOrCell?: keyof typeof AnimalOrCell | null;
  qualityScore?: QualityScore[] | null;
  exposurePathway?: string | null;
  variant?: string[] | null;
  synthesis?: string[] | null;
  onlyGuidelineExperiments?: boolean | null;
  user?: IUser | null;
  materials?: IAdvancedMaterial[] | null;
  origins?: IOrigin[] | null;
  applications?: IApplication[] | null;
  cellCultures?: ICellCulture[] | null;
  tissueTypes?: ITissueType[] | null;
  organs?: IOrgan[] | null;
}

export interface ISearchOption<T> {
  value: T;
  numberOfDatasets: number;
}

export const defaultValue: Readonly<ISearchQuery> = {
  onlyGuidelineExperiments: false,
};
