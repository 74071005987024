import { IDatasetToAssay } from 'app/shared/model/dataset-to-assay.model';
import { IDataset } from 'app/shared/model/dataset.model';

export interface IAssay {
  id?: number;
  name?: string;
  datasetToAssays?: IDatasetToAssay[] | null;
  datasets?: IDataset[] | null;
}

export const defaultValue: Readonly<IAssay> = {};
