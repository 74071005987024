/* eslint-disable no-console */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAssay } from 'app/shared/model/assay.model';
import React, { useState } from 'react';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import { EffectNameValue } from './effect-name-value';
import { AttributeNameValue } from './attribute-name-value';
import { ModalAssociateAssay } from './modal-associate-assay';
import { IDatasetToAssay } from 'app/shared/model/dataset-to-assay.model';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';
import { IBiologicalModel } from 'app/shared/model/biological-model.model';

export type IEffectCategoryOverviewCard = {
  datasetId: number;
  effectCategory: EffectCategory;
  editable?: boolean;
  editingEnabled?: boolean;
  assays?: IDatasetToAssay[];
  allAssays?: readonly IAssay[];
  biologicalModel?: IBiologicalModel;
  effects: any[];
  effectsEditTarget?: string;
};

export const EffectCategoryOverviewCard = ({
  datasetId,
  effectCategory,
  editable = true,
  editingEnabled = false,
  assays,
  allAssays,
  biologicalModel,
  effects,
  effectsEditTarget,
}: IEffectCategoryOverviewCard) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const assaysOfThisEffectCategory = assays?.filter(assay => assay.effect === effectCategory);

  if (!editable && assaysOfThisEffectCategory?.length === 0 && effects.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardHeader tag="h5" className={`${effectCategory}-effect-header`}>
        <Translate contentKey={`coconApp.EffectCategory.${effectCategory}`}></Translate>{' '}
        <Translate contentKey={`coconApp.effect.home.title`}></Translate>{' '}
      </CardHeader>
      <CardBody tag={Row}>
        {assaysOfThisEffectCategory?.length > 0 && (
          <AttributeNameValue contentKey="coconApp.dataset.assays">
            <span style={{ paddingTop: '0.5rem' }}>{assaysOfThisEffectCategory?.map(assay => assay.assay.name).join(', ')}</span>
          </AttributeNameValue>
        )}
        {allAssays && (
          <Col>
            <ModalAssociateAssay
              datasetId={datasetId}
              effectCategory={effectCategory}
              isOpen={modalIsOpen}
              toggle={() => setModalIsOpen(!modalIsOpen)}
              datasetAssays={assays}
              assays={allAssays}
            />
            <Label>&nbsp;</Label>
            <Button block color="primary" outline disabled={!editingEnabled} onClick={() => setModalIsOpen(true)}>
              <FontAwesomeIcon icon="pencil-alt" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit"></Translate> <Translate contentKey="coconApp.dataset.assays"></Translate>
              </span>
            </Button>
          </Col>
        )}
        {effects
          ?.sort((e1, e2) => e1.name.displayOrder - e2.name.displayOrder)
          ?.map(effect => <EffectNameValue key={effect.id} {...effect} />)}
        {effectCategory === EffectCategory.STRESS_RESPONSE &&
          biologicalModel?.effectiveDoseLabel &&
          biologicalModel?.effectiveDoseValue && (
            <EffectNameValue
              observedLevel={
                biologicalModel?.effectiveDoseValue?.startsWith('<')
                  ? 'LOEL'
                  : biologicalModel?.effectiveDoseValue?.startsWith('>')
                  ? 'UNOEL'
                  : 'NOAEL'
              }
              name={{ name: biologicalModel?.effectiveDoseLabel }}
              value={biologicalModel?.effectiveDoseValue}
            />
          )}
        {editable && (
          <Col xs="auto" className="ms-auto">
            <div className="form-label">&nbsp;</div>
            <Button tag={Link} to={effectsEditTarget} color="primary" outline disabled={!editingEnabled}>
              <FontAwesomeIcon icon="pencil-alt" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>{' '}
                <Translate contentKey="coconApp.dataset.effects">Effects</Translate>
              </span>
            </Button>
          </Col>
        )}
      </CardBody>
    </Card>
  );
};
