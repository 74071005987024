import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { translate, Translate, ValidatedField } from 'react-jhipster';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FieldError, FieldValues, useForm } from 'react-hook-form';

export const ModalCreateEntity = ({ createEntity, entityKey }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();

  const updating = useAppSelector(state => state[entityKey].updating);
  const updateSuccess = useAppSelector(state => state[entityKey].updateSuccess);

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields, dirtyFields },
  } = useForm<FieldValues>({ mode: 'onTouched' });

  // https://github.com/react-hook-form/react-hook-form/issues/1005
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // this part is for stopping parent forms to trigger their submit
    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        // prevent any outer forms from receiving the event too
        event.stopPropagation();
      }
    }

    return handleSubmit(async values => {
      const entity = {
        ...values,
      };

      return await dispatch(createEntity(entity));
    })(event);
  };

  useEffect(() => {
    if (updateSuccess && isOpen) {
      toggle();
    }
  }, [updateSuccess]);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Modal isOpen={isOpen} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Translate contentKey={`coconApp.${entityKey}.home.createLabel`}></Translate>
        </ModalHeader>
        <ModalBody>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Form id="ModalCreateEntity" onSubmit={onSubmit}>
            <ValidatedField
              label={translate(`coconApp.${entityKey}.name`)}
              id={`${entityKey}-name`}
              name="name"
              register={register}
              error={errors.controlParticle as FieldError}
              isTouched={touchedFields.controlParticle}
              isDirty={dirtyFields.controlParticle}
              data-cy="name"
              type="text"
              validate={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button
            color="primary"
            id="save-entity"
            data-cy="entityCreateSaveButton"
            type="submit"
            form="ModalCreateEntity"
            disabled={updating}
          >
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button>
        </ModalFooter>
      </Modal>

      <Button block color="primary" onClick={toggle}>
        <Translate contentKey={`coconApp.${entityKey}.home.createButton`}></Translate>
      </Button>
    </>
  );
};
