import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PathwayOfToxicity from './pathway-of-toxicity';
import PathwayOfToxicityDetail from './pathway-of-toxicity-detail';
import PathwayOfToxicityUpdate from './pathway-of-toxicity-update';
import PathwayOfToxicityDeleteDialog from './pathway-of-toxicity-delete-dialog';

const PathwayOfToxicityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PathwayOfToxicity />} />
    <Route path="new" element={<PathwayOfToxicityUpdate />} />
    <Route path=":id">
      <Route index element={<PathwayOfToxicityDetail />} />
      <Route path="edit" element={<PathwayOfToxicityUpdate />} />
      <Route path="delete" element={<PathwayOfToxicityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PathwayOfToxicityRoutes;
