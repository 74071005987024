import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import StorageSite from './storage-site';
import StorageSiteDetail from './storage-site-detail';
import StorageSiteUpdate from './storage-site-update';
import StorageSiteDeleteDialog from './storage-site-delete-dialog';

const StorageSiteRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<StorageSite />} />
    <Route path="new" element={<StorageSiteUpdate />} />
    <Route path=":id">
      <Route index element={<StorageSiteDetail />} />
      <Route path="edit" element={<StorageSiteUpdate />} />
      <Route path="delete" element={<StorageSiteDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default StorageSiteRoutes;
