import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AdvancedMaterial from './advanced-material';
import AdvancedMaterialDetail from './advanced-material-detail';
import AdvancedMaterialUpdate from './advanced-material-update';
import AdvancedMaterialDeleteDialog from './advanced-material-delete-dialog';

const AdvancedMaterialRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AdvancedMaterial />} />
    <Route path="new" element={<AdvancedMaterialUpdate />} />
    <Route path=":id">
      <Route index element={<AdvancedMaterialDetail />} />
      <Route path="edit" element={<AdvancedMaterialUpdate />} />
      <Route path="delete" element={<AdvancedMaterialDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AdvancedMaterialRoutes;
