import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './comment.reducer';

export const Comment = () => {
  const dispatch = useAppDispatch();

  const { id: datasetId } = useParams<'id'>();

  const commentList = useAppSelector(state => state.comment.entities);
  const loading = useAppSelector(state => state.comment.loading);

  useEffect(() => {
    dispatch(getEntities({ datasetId }));
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem active tag={Link} to={`/dataset${location.search}`}>
          Datasets
        </BreadcrumbItem>
        <BreadcrumbItem active tag={Link} to={`/dataset/${datasetId}/edit${location.search}`}>
          {datasetId}
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardHeader tag="h5">
          <Translate contentKey="coconApp.comment.home.title">Comments</Translate>
        </CardHeader>
        <CardBody>
          {commentList && commentList.length > 0 ? (
            <Table size="sm" responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <Translate contentKey="coconApp.comment.timestamp">Timestamp</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {commentList.map((comment, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{comment.comment}</td>
                    <td>{comment.timestamp ? <TextFormat type="date" value={comment.timestamp} format={APP_DATE_FORMAT} /> : null}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${comment.id}/edit${location.search}`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${comment.id}/delete${location.search}`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="coconApp.comment.home.notFound">No Comments found</Translate>
              </div>
            )
          )}
          <span className="d-flex justify-content-end">
            <Link to={`new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="coconApp.comment.home.createLabel">Create new Comment</Translate>
            </Link>
          </span>
        </CardBody>
      </Card>
    </div>
  );
};

export default Comment;
