import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DataImport from './data-import';
import DataImportDeleteDialog from './data-import-delete-dialog';

const DataImportRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DataImport />} />
    <Route path=":id">
      <Route path="delete" element={<DataImportDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DataImportRoutes;
