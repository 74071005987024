import React from 'react';
import { ValidatedInput, ValidatedInputProps } from 'react-jhipster';
import { Col, FormGroup, InputGroup, InputGroupText, Label } from 'reactstrap';

export interface ValidatedFieldInputGroupProps extends ValidatedInputProps {
  label?: string;
  labelClass?: string;
  labelHidden?: boolean;
  check?: boolean;
  inputClass?: string;
  inputTag?: React.ElementType;
  inputGroupText: string;
}

/**
 * A copy of react-jhipster's ValidatedField with support for InputGroups
 *
 * @param ValidatedFieldProps
 * @returns JSX.Element
 */
export function ValidatedFieldInputGroup({
  children,
  name,
  id,
  disabled,
  className,
  check,
  label,
  labelClass,
  labelHidden,
  inputClass,
  inputTag,
  inputGroupText,
  tag,
  hidden,
  ...attributes
}: ValidatedFieldInputGroupProps): JSX.Element {
  const input = (
    <ValidatedInput name={name} id={id} disabled={disabled} className={inputClass} hidden={hidden} tag={inputTag} {...attributes}>
      {children}
    </ValidatedInput>
  );

  const inputGroup = (
    <InputGroup>
      {input}
      <InputGroupText>{inputGroupText}</InputGroupText>
    </InputGroup>
  );
  return (
    <FormGroup check={check} disabled={disabled} className={className} hidden={hidden} tag={tag}>
      {check && inputGroup}
      {label && (
        <Label id={`${name}Label`} check={check} for={id} className={labelClass} hidden={labelHidden || hidden}>
          {label}
        </Label>
      )}
      {!check && inputGroup}
    </FormGroup>
  );
}
