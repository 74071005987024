import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AnimalSpecies from './animal-species';
import AnimalSpeciesDetail from './animal-species-detail';
import AnimalSpeciesUpdate from './animal-species-update';
import AnimalSpeciesDeleteDialog from './animal-species-delete-dialog';

const AnimalSpeciesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AnimalSpecies />} />
    <Route path="new" element={<AnimalSpeciesUpdate />} />
    <Route path=":id">
      <Route index element={<AnimalSpeciesDetail />} />
      <Route path="edit" element={<AnimalSpeciesUpdate />} />
      <Route path="delete" element={<AnimalSpeciesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AnimalSpeciesRoutes;
