import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, duplicateEntity } from './dataset.reducer';

export const DatasetDuplicateDialog = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(id));
    setLoadModal(true);
  }, []);

  const datasetEntity = useAppSelector(state => state.dataset.entity);
  const updateSuccess = useAppSelector(state => state.dataset.updateSuccess);

  const handleClose = () => {
    if (updateSuccess) {
      navigate('/dataset/' + datasetEntity.id + '/edit');
    } else {
      navigate('/dataset' + pageLocation.search);
    }
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  const confirmDuplicate = () => {
    dispatch(duplicateEntity(datasetEntity.id));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="datasetDuplicateDialogHeading">
        <Translate contentKey="entity.duplicate.title">Confirm duplicate operation</Translate>
      </ModalHeader>
      <ModalBody id="coconApp.dataset.duplicate.question">
        <Translate contentKey="coconApp.dataset.duplicate.question" interpolate={{ id: datasetEntity.id }}>
          Are you sure you want to duplicate this Dataset?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-duplicate-dataset" data-cy="entityConfirmDuplicateButton" color="success" onClick={confirmDuplicate}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.duplicate">Duplicate</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DatasetDuplicateDialog;
