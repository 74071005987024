import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Assay from './assay';
import AssayDetail from './assay-detail';
import AssayUpdate from './assay-update';
import AssayDeleteDialog from './assay-delete-dialog';

const AssayRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Assay />} />
    <Route path="new" element={<AssayUpdate />} />
    <Route path=":id">
      <Route index element={<AssayDetail />} />
      <Route path="edit" element={<AssayUpdate />} />
      <Route path="delete" element={<AssayDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AssayRoutes;
