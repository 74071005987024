import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { NavDropdown } from './menu-components';
import { Translate, translate } from 'react-jhipster';

export const DataManagementMenu = () => (
  <NavDropdown icon="th-list" name={translate('global.menu.data-management.main')} id="data-management-menu" data-cy="dataManagementMenu">
    <MenuItem icon="upload" to="/data-import">
      <Translate contentKey="global.menu.data-management.show-imports"></Translate>
    </MenuItem>
  </NavDropdown>
);

export default DataManagementMenu;
