import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publication.reducer';
import { hasDataManagementAuthority } from 'app/shared/auth/private-route';

export const PublicationDetail = () => {
  const dispatch = useAppDispatch();

  const isAllowedToManageData = useAppSelector(hasDataManagementAuthority);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publicationEntity = useAppSelector(state => state.publication.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publicationDetailsHeading">
          <Translate contentKey="coconApp.publication.detail.title">Publication</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.id}</dd>
          <dt>
            <span id="authors">
              <Translate contentKey="coconApp.publication.authors">Authors</Translate>
            </span>
            <UncontrolledTooltip target="authors">
              <Translate contentKey="coconApp.publication.help.authors" />
            </UncontrolledTooltip>
          </dt>
          <dd>{publicationEntity.authors}</dd>
          <dt>
            <span id="year">
              <Translate contentKey="coconApp.publication.year">Year</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.year}</dd>
          <dt>
            <span id="doi">
              <Translate contentKey="coconApp.publication.doi">Doi</Translate>
            </span>
          </dt>
          <dd>
            <a target="_blank" rel="noreferrer" href={`https://doi.org/${publicationEntity?.doi}`}>
              {publicationEntity.doi}
            </a>
          </dd>
        </dl>
        <Button tag={Link} to={`/dataset?publicationId.equals=${publicationEntity.id}`} color="primary" data-cy="entityDetailsButton">
          <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Show Datasets</span>
        </Button>
        &nbsp;
        {isAllowedToManageData && (
          <Button tag={Link} to={`/publication/${publicationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default PublicationDetail;
