export enum QualityScore {
  NA = 'NA',

  ZERO = 'ZERO',

  POINT_FIVE = 'POINT_FIVE',

  POINT_EIGHT = 'POINT_EIGHT',

  ONE = 'ONE',
}
