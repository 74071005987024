import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import React from 'react';
import { Route } from 'react-router-dom';

import DataImport from './data-import/data-import';

const Routes = () => (
  <ErrorBoundaryRoutes>
    <Route path={`data-import`} element={<DataImport />} />
  </ErrorBoundaryRoutes>
);

export default Routes;
