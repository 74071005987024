import { IBiologicalModel } from 'app/shared/model/biological-model.model';
import { ISearchQuery } from 'app/shared/model/search-query.model';

export interface ITissueType {
  id?: number;
  name?: string;
  biologicalModels?: IBiologicalModel[] | null;
  searchQueries?: ISearchQuery[] | null;
}

export const defaultValue: Readonly<ITissueType> = {};
