/* eslint-disable no-console */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { translate, Translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { partialUpdateEntity } from '../dataset.reducer';
import { IRelevanceCriteria } from 'app/shared/model/relevance-criteria.model';

export const ModalRelevanceCriteriaSelection = ({ isOpen, toggle, dataset }) => {
  const dispatch = useAppDispatch();

  const relevanceCriteria: IRelevanceCriteria[] = useAppSelector(state => state.relevanceCriteria.entities);
  const updating = useAppSelector(state => state.dataset.updating);
  const updateSuccess = useAppSelector(state => state.dataset.updateSuccess);

  const saveEntity = values => {
    const selectedCriteria = Object.keys(values)
      .filter(key => values[key])
      .map(id => ({ id: parseInt(id, 10) }));

    const entity = {
      id: dataset.id,
      relevanceCriteria: selectedCriteria,
    };

    dispatch(partialUpdateEntity(entity));
  };

  useEffect(() => {
    if (updateSuccess && isOpen) {
      toggle();
    }
  }, [updateSuccess]);

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Translate contentKey={`coconApp.relevanceCriteria.home.createLabel`}></Translate>
      </ModalHeader>
      <ModalBody>
        <ValidatedForm id="ModalCreateEntity" defaultValues={{}} onSubmit={saveEntity}>
          {relevanceCriteria.map(criteria => (
            <ValidatedField
              key={criteria.id}
              label={`${criteria.name}`}
              id={`${criteria.id}`}
              name={`${criteria.id}`}
              type="checkbox"
              defaultChecked={dataset.relevanceCriteria?.find(c => criteria.id === c.id)}
              check
            />
          ))}
        </ValidatedForm>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button
          color="primary"
          id="save-entity"
          data-cy="entityCreateSaveButton"
          type="submit"
          form="ModalCreateEntity"
          disabled={updating}
        >
          <FontAwesomeIcon icon="save" />
          &nbsp;
          <Translate contentKey="entity.action.save">Save</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
