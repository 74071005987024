/* eslint-disable no-console */
import axios from 'axios';
import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IDataImport } from 'app/shared/model/data-import.model';

type ImportMessage = {
  type: 'Warning' | 'Error';
  message: string;
  sheetName: string;
  columnName: string;
  rowNumber: string;
  value: string;
};

const initialState = {
  successMessage: null,
  loading: false,
  errorMessage: null,
  importMessages: [] as ImportMessage[],
  dataImport: null as IDataImport,
};

export type DataManagementState = Readonly<typeof initialState>;

// Actions

export const uploadDataImport = createAsyncThunk<
  any,
  any,
  {
    rejectValue: { messages: ImportMessage[] };
  }
>(
  'dataManagement/upload_data_import',
  async ({ file, approvalState }: any, thunkAPI) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    return axios
      .post(`api/data-management/data-import?approvalState=${approvalState}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => thunkAPI.rejectWithValue(error.response.data));
  },
  {
    serializeError: serializeAxiosError,
  },
);

export const DataManagementSlice = createSlice({
  name: 'dataManagement',
  initialState: initialState as DataManagementState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(uploadDataImport.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = 'data-management.data-import.messages.success';
        state.dataImport = action.payload.data;
      })
      .addMatcher(isPending(uploadDataImport), state => {
        state.errorMessage = null;
        state.loading = true;
        state.importMessages = [];
        state.dataImport = null;
      })
      .addMatcher(isRejected(uploadDataImport), (state, action) => {
        state.errorMessage = action.error.message;
        if (action.error.message) {
          state.importMessages = action.payload.messages;
        }
        state.loading = false;
      });
  },
});

export const { reset } = DataManagementSlice.actions;

// Reducer
export default DataManagementSlice.reducer;
