import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AdverseOutcomePathway from './adverse-outcome-pathway';
import AdverseOutcomePathwayDetail from './adverse-outcome-pathway-detail';
import AdverseOutcomePathwayUpdate from './adverse-outcome-pathway-update';
import AdverseOutcomePathwayDeleteDialog from './adverse-outcome-pathway-delete-dialog';

const AdverseOutcomePathwayRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AdverseOutcomePathway />} />
    <Route path="new" element={<AdverseOutcomePathwayUpdate />} />
    <Route path=":id">
      <Route index element={<AdverseOutcomePathwayDetail />} />
      <Route path="edit" element={<AdverseOutcomePathwayUpdate />} />
      <Route path="delete" element={<AdverseOutcomePathwayDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AdverseOutcomePathwayRoutes;
