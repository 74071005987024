import React from 'react';
import { Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { AttributeNameValue } from './attribute-name-value';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';
import { IEffect } from '../../../shared/model/effect.model';
import { IDatasetToAssay } from 'app/shared/model/dataset-to-assay.model';
import { IBiologicalModel } from 'app/shared/model/biological-model.model';

export function EffectRows({
  effects,
  assays,
  biologicalModel,
}: {
  effects: Array<IEffect>;
  assays?: Array<IDatasetToAssay>;
  biologicalModel?: IBiologicalModel;
}) {
  return (
    <>
      {Object.keys(EffectCategory).map((effectCategory: EffectCategory) => {
        const effectsInCategory = effects?.filter(effect => effect.name.category === effectCategory);

        const assaysInCategory = assays?.filter(assay => assay.effect === effectCategory);

        if (effectsInCategory?.length === 0 && assaysInCategory?.length === 0) {
          return null;
        }

        return (
          <Row key={effectCategory} className={`${effectCategory}-effect-header`}>
            <Col xs="auto">
              <span className="form-label">
                <Translate contentKey={`coconApp.EffectCategory.${effectCategory}`}></Translate>:
              </span>
            </Col>
            {assaysInCategory?.length > 0 && (
              <AttributeNameValue contentKey="coconApp.dataset.assays">
                {assaysInCategory?.map(assay => assay.assay.name).join(', ')}
              </AttributeNameValue>
            )}

            {effectsInCategory
              ?.sort((e1, e2) => e1.name.displayOrder - e2.name.displayOrder)
              ?.map(({ id, observedLevel, name, value }) => {
                const prefix = observedLevel === 'NOAEL' ? '' : observedLevel === 'LOEL' ? '<' : observedLevel === 'UNOEL' ? '>' : '';

                return <AttributeNameValue key={id} label={name.name} value={`${prefix}${value}`} />;
              })}

            {effectCategory === EffectCategory.STRESS_RESPONSE &&
              biologicalModel?.effectiveDoseLabel &&
              biologicalModel?.effectiveDoseValue && (
                <AttributeNameValue label={biologicalModel?.effectiveDoseLabel} value={biologicalModel?.effectiveDoseValue} />
              )}
          </Row>
        );
      })}
    </>
  );
}
