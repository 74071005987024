import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert, Button, Table } from 'reactstrap';

import { useAppSelector } from 'app/config/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function QueryOverview() {
  const queryList = [
    { id: '1', material: { trivialName: 'Silica', molecularFormula: 'SiO2' }, size: '35nm' },
    { id: '2', material: { trivialName: 'Titanium dioxide', molecularFormula: 'TiO2' }, size: '50nm' },
  ];
  const loading = false;

  return (
    <div className="table-responsive">
      {queryList && queryList.length > 0 ? (
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Translate contentKey="coconApp.advancedMaterial.molecularFormula"></Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.advancedMaterial.trivialName"></Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.materialProperties.dimension1"></Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {queryList.map((query, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>{query.material.molecularFormula}</td>
                <td>{query.material.trivialName}</td>
                <td>{query.size}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/query/${query.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="home.query.table.show"></Translate>
                      </span>
                    </Button>
                    <Button tag={Link} to={`/query/${query.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                      <FontAwesomeIcon icon="pencil-alt" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </Button>
                    <Button tag={Link} to={`/query/${query.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                      <FontAwesomeIcon icon="trash" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="coconApp.advancedMaterial.home.notFound">No Advanced Materials found</Translate>
          </div>
        )
      )}
    </div>
  );
}

function CreateQuery() {
  return (
    <Button color="primary" tag={Link} to="/search-query/new">
      <Translate contentKey="query.query-composer.title"></Translate>
    </Button>
  );
}

export const Home = () => {
  const account = useAppSelector(state => state.authentication.account);

  return (
    <Row>
      <Col md="1" className="pad">
        <span className="hipster rounded" />
      </Col>
      <Col md="11">
        <h2>
          <Translate contentKey="home.title"></Translate>
        </h2>
        {false && (
          <p className="lead">
            <Translate contentKey="home.subtitle"></Translate>
          </p>
        )}
        {account?.login ? (
          <>
            <div>
              <Alert color="success">
                <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                  You are logged in as user {account.login}.
                </Translate>
              </Alert>
            </div>
            <div>
              <p>
                <CreateQuery />
              </p>
              {false && (
                <h3>
                  <Translate contentKey="home.query.table.title"></Translate>
                </h3>
              )}
              {false && <QueryOverview />}
            </div>
          </>
        ) : (
          <div>
            <Alert color="warning">
              <Translate contentKey="global.messages.info.authenticated.prefix">If you want to </Translate>

              <Link to="/login" className="alert-link">
                <Translate contentKey="global.messages.info.authenticated.link"> sign in</Translate>.
              </Link>
            </Alert>

            <Alert color="warning">
              <Translate contentKey="global.messages.info.register.noaccount">You do not have an account yet?</Translate>&nbsp;
              <Link to="/account/register" className="alert-link">
                <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
              </Link>
            </Alert>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Home;
