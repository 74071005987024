import { IMaterialProperties } from 'app/shared/model/material-properties.model';
import { IBiologicalModel } from 'app/shared/model/biological-model.model';
import { IEffect } from 'app/shared/model/effect.model';
import { IComment } from 'app/shared/model/comment.model';
import { IApprovalHistory } from 'app/shared/model/approval-history.model';
import { IDatasetToAssay } from 'app/shared/model/dataset-to-assay.model';
import { IPathwayOfToxicity } from 'app/shared/model/pathway-of-toxicity.model';
import { IAdverseOutcomePathway } from 'app/shared/model/adverse-outcome-pathway.model';
import { IRelevanceCriteria } from 'app/shared/model/relevance-criteria.model';
import { ITechnicalApplication } from 'app/shared/model/technical-application.model';
import { IPublication } from 'app/shared/model/publication.model';
import { IAnimalSpecies } from 'app/shared/model/animal-species.model';
import { ICellCulture } from 'app/shared/model/cell-culture.model';
import { IReferenceMaterial } from 'app/shared/model/reference-material.model';
import { IDataImport } from 'app/shared/model/data-import.model';
import { Relevance } from 'app/shared/model/enumerations/relevance.model';
import { QualityScore } from 'app/shared/model/enumerations/quality-score.model';
import { StudyType } from 'app/shared/model/enumerations/study-type.model';

export interface IDataset {
  id?: number | string;
  relevance?: keyof typeof Relevance | null;
  qualityScore?: keyof typeof QualityScore | null;
  type?: keyof typeof StudyType | null;
  material?: IMaterialProperties | null;
  biologicalModel?: IBiologicalModel | null;
  effects?: IEffect[] | null;
  comments?: IComment[] | null;
  approvalHistories?: IApprovalHistory[] | null;
  assays?: IDatasetToAssay[] | null;
  pots?: IPathwayOfToxicity[] | null;
  aops?: IAdverseOutcomePathway[] | null;
  relevanceCriteria?: IRelevanceCriteria[] | null;
  technicalApplications?: ITechnicalApplication[] | null;
  publication?: IPublication | null;
  inVivo?: IAnimalSpecies | null;
  inVitro?: ICellCulture | null;
  controlParticle?: IReferenceMaterial | null;
  dataImport?: IDataImport | null;
}

export const defaultValue: Readonly<IDataset> = {};
