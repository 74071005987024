import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ReferenceMaterial from './reference-material';
import ReferenceMaterialDetail from './reference-material-detail';
import ReferenceMaterialUpdate from './reference-material-update';
import ReferenceMaterialDeleteDialog from './reference-material-delete-dialog';

const ReferenceMaterialRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ReferenceMaterial />} />
    <Route path="new" element={<ReferenceMaterialUpdate />} />
    <Route path=":id">
      <Route index element={<ReferenceMaterialDetail />} />
      <Route path="edit" element={<ReferenceMaterialUpdate />} />
      <Route path="delete" element={<ReferenceMaterialDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReferenceMaterialRoutes;
