import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row, Button } from 'reactstrap';

const openFeedbackLick = () => {
  const href = `mailto:info@nanocase.ch?subject=CoCoN%20Feedback&body=Dear%20NanoCASE%0D%0A%0D%0A(Your%20feedback%20here)%0D%0A%0D%0AURL:%20${encodeURIComponent(
    window.location.href,
  )}%0D%0A%0D%0ABest%20regards`;

  window.open(href, '_self');
};

const Footer = () => {
  return (
    <div className="footer page-content">
      <Button onClick={openFeedbackLick} id="feedback-button" color="primary" outline>
        <Translate contentKey="feedback">Feedback</Translate>
      </Button>
      <Row>
        <Col md="12">
          <p>
            <Translate contentKey="footer">Your footer</Translate>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
