/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount, translate, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typeahead } from 'react-bootstrap-typeahead';
import omit from 'lodash/omit';

import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams, getFilterState } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity as getDataImportEntity, reset as resetDataImportEntity } from '../data-import/data-import.reducer';
import { ReviewState } from './components/review-state-display';
import { getEntities } from './dataset.reducer';
import { RelevanceLabel } from './components/relevance-label';
import { IDataset } from 'app/shared/model/dataset.model';
import { DatasetDetailRows } from './components/dataset-detail-rows';
import { hasDataManagementAuthority } from 'app/shared/auth/private-route';

export const Dataset = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const isAllowedToManageData = useAppSelector(hasDataManagementAuthority);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'publication.authors'), pageLocation.search),
  );

  const [filterState, setFilterState] = useState(getFilterState(pageLocation.search));

  const filterQueryString = Object.entries(filterState)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const [collapse, setCollapse] = useState([]);

  const toggleCollapse = index => {
    const collapseCopy = [...collapse];
    collapseCopy[index] = !collapseCopy[index];
    setCollapse(collapseCopy);
  };

  const datasetList: IDataset[] = useAppSelector(state => state.dataset.entities);
  const loading = useAppSelector(state => state.dataset.loading);
  const totalItems = useAppSelector(state => state.dataset.totalItems);
  const dataImportEntity = useAppSelector(state => state.dataImport.entity);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: filterQueryString,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&${filterQueryString}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, filterQueryString]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
    setFilterState(getFilterState(pageLocation.search));
  }, [pageLocation.search]);

  const dataImportId = filterState['dataImportId.equals'];
  const publicationId = filterState['publicationId.equals'];

  useEffect(() => {
    if (dataImportId) {
      dispatch(getDataImportEntity(dataImportId));
    } else {
      dispatch(resetDataImportEntity());
    }
    return () => {
      dispatch(resetDataImportEntity());
    };
  }, [dataImportId]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  // TODO make sure that both work and unify if possible

  const handleRelevanceFilterChanged = (selected: any[]) => {
    if (selected.length > 0) {
      setPaginationState({
        ...paginationState,
        activePage: 0,
      });
      setFilterState({
        ...filterState,
        'approval-state': selected.map(s => s.value).join(','),
      });
    } else {
      setFilterState(omit(filterState, 'approval-state'));
    }
  };

  const handleFilterChanged = (key: string) => event => {
    event.preventDefault();
    const value = event.target[key].value;
    const objectKey = `${key}.equals`;

    if (value && value.length > 0) {
      setFilterState({
        ...filterState,
        [objectKey]: value,
      });
    } else {
      setFilterState(omit(filterState, objectKey));
    }
  };

  return (
    <div>
      <h2 id="dataset-heading" data-cy="DatasetHeading">
        <Translate contentKey="coconApp.dataset.home.title">Datasets</Translate>
        {dataImportEntity.file && <> imported from {dataImportEntity.file}</>}
        {publicationId && datasetList.length > 0 && (
          <>
            {' '}
            of {datasetList[0].publication?.authors} ({datasetList[0].publication?.year}){' '}
          </>
        )}
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="coconApp.dataset.home.refreshListLabel">Refresh List</Translate>
          </Button>
          {isAllowedToManageData && (
            <Link
              to={`new${pageLocation.search}`}
              className="btn btn-primary jh-create-entity"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="coconApp.dataset.home.createLabel">Create new Dataset</Translate>
            </Link>
          )}
        </div>
      </h2>
      <div className="table-responsive">
        <Table hover size="sm" responsive>
          <thead>
            <tr>
              <th></th>
              {isAllowedToManageData && (
                <th className="hand">
                  <span style={{ paddingRight: '1em' }} onClick={sort('id')}>
                    <Translate contentKey="coconApp.dataset.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </span>
                  <form style={{ display: 'inline' }} onSubmit={handleFilterChanged('id')}>
                    <Input
                      style={{ display: 'inline', width: 'unset' }}
                      name="id"
                      defaultValue={filterState['id.equals']}
                      bsSize="sm"
                      placeholder="Filter..."
                    />
                  </form>
                </th>
              )}
              <th className="hand" onClick={sort('publication.authors')}>
                <Translate contentKey="coconApp.dataset.publication">Publication</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.relevance" />
                {false && (
                  <Typeahead
                    id="relevance"
                    placeholder="Relevance"
                    size="sm"
                    onChange={handleRelevanceFilterChanged}
                    defaultSelected={filterState['relevance.in']
                      ?.split(',')
                      .map(key => ({ value: key, label: translate(`coconApp.Relevance.${key}`) }))}
                    options={Object.keys([] /* Relevance */).map(key => ({ value: key, label: translate(`coconApp.Relevance.${key}`) }))}
                  />
                )}
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.qualityScore">Quality Score</Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.inVivo">In Vivo</Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.inVitro">In Vitro</Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.controlParticle">Control Particle</Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.technicalApplications">Technical Application</Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.type">Type</Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.material">Material</Translate>
              </th>
              <th>
                <Translate contentKey="coconApp.dataset.approvalState">State</Translate>
                {isAllowedToManageData && (
                  <Typeahead
                    id="approval-state"
                    multiple
                    placeholder={translate('coconApp.dataset.approvalState')}
                    size="sm"
                    onChange={handleRelevanceFilterChanged}
                    options={[
                      { value: 'DRAFT', label: translate(`coconApp.ApprovalState.DRAFT`) },
                      { value: 'CURATED', label: translate(`coconApp.ApprovalState.CURATED`) },
                    ]}
                  />
                )}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {datasetList.map(datasetEntity => (
              <Fragment key={`entity-${datasetEntity.id}`}>
                <tr
                  className={`header ${collapse[datasetEntity.id] ? 'expanded' : 'collapsed'}`}
                  onClick={() => toggleCollapse(datasetEntity.id)}
                  data-cy="entityTable"
                >
                  <td>
                    <FontAwesomeIcon
                      size="sm"
                      style={{ width: '1em' }}
                      icon={collapse[datasetEntity.id] ? 'chevron-down' : 'chevron-right'}
                    />
                  </td>
                  {isAllowedToManageData && (
                    <td>
                      <Link to={`${datasetEntity.id}${pageLocation.search}`}>{datasetEntity.id}</Link>
                    </td>
                  )}
                  <td>
                    {datasetEntity.publication ? (
                      <>
                        <Link to={`/publication/${datasetEntity.publication.id}`}>
                          {datasetEntity.publication.authors} ({datasetEntity.publication.year})
                        </Link>
                        <br />
                        <a target="_blank" href={`https://doi.org/${datasetEntity?.publication?.doi}`} rel="noreferrer">
                          {datasetEntity.publication.doi}
                        </a>
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <RelevanceLabel dataset={datasetEntity} />
                  </td>
                  <td>
                    <Translate contentKey={`coconApp.QualityScore.${datasetEntity.qualityScore}`} />
                  </td>
                  <td>{datasetEntity.inVivo?.name}</td>
                  <td>{datasetEntity.inVitro?.name}</td>

                  <td>{datasetEntity.controlParticle?.name}</td>
                  <td>{datasetEntity.technicalApplications.map(ta => ta.name).join(', ')}</td>
                  <td>
                    <Translate contentKey={`coconApp.StudyType-Short.${datasetEntity.type}`} />
                  </td>
                  <td>{datasetEntity.material?.engineeredNanoMaterial?.molecularFormula}</td>
                  <td>
                    {isAllowedToManageData ? (
                      <Link to={`${datasetEntity.id}/approval-history`}>
                        <Button className="pill" size="sm" color="success" disabled>
                          <ReviewState dataset={datasetEntity} />
                        </Button>
                      </Link>
                    ) : (
                      <Button className="pill" size="sm" color="success" disabled>
                        <ReviewState dataset={datasetEntity} />
                      </Button>
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`${datasetEntity.id}${pageLocation.search}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      {isAllowedToManageData && (
                        <Button
                          tag={Link}
                          to={`${datasetEntity.id}/edit${pageLocation.search}`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                      )}
                      {isAllowedToManageData && (
                        <Button
                          tag={Link}
                          to={`${datasetEntity.id}/duplicate?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="secondary"
                          size="sm"
                          data-cy="duplicateEditButton"
                        >
                          <FontAwesomeIcon icon="clone" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.duplicate">Duplicate</Translate>
                          </span>
                        </Button>
                      )}
                      {isAllowedToManageData && (
                        <Button
                          tag={Link}
                          to={`${datasetEntity.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
                <tr className={`details ${collapse[datasetEntity.id] ? 'expanded' : 'collapsed'}`}>
                  <td>{/* placeholder for alignment with the toggle icon */}</td>
                  <td colSpan={12}>
                    <DatasetDetailRows dataset={datasetEntity} />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
        {!loading && datasetList.length === 0 && (
          <div className="alert alert-warning">
            <Translate contentKey="coconApp.dataset.home.notFound">No Datasets found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={datasetList && datasetList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount
              key={totalItems}
              page={paginationState.activePage}
              total={totalItems}
              itemsPerPage={paginationState.itemsPerPage}
              i18nEnabled
            />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Dataset;
