/* eslint-disable no-console */
import React from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import { getOptionLabel } from 'react-bootstrap-typeahead/types/utils';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { FormGroup, Input, InputGroup, InputGroupText, Label, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { Range, getTrackBackground } from 'react-range';
import { debounce } from 'lodash';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-jhipster';

type RangeInputProps = {
  id: string;
  name: string;
  minval?: number;
  maxval?: number;
  control: Control<FieldValues, any>;
  label;
  tooltip?: string;
  rules?;
};

class Log {
  minpos: number;
  maxpos: number;
  minval: number;
  maxval: number;
  scale: number;

  constructor(opts) {
    this.minpos = opts.minpos || 0;
    this.maxpos = opts.maxpos || 100;

    this.minval = Math.log(opts.minval || 1);
    this.maxval = Math.log(opts.maxval || 9000);

    this.scale = (this.maxval - this.minval) / (this.maxpos - this.minpos);
  }

  values(positions: number[]): number[] {
    const scaled = positions.map(position => Math.round(Math.exp((position - this.minpos) * this.scale + this.minval)));

    return scaled;
  }

  positions(values: number[]): number[] {
    return values.map(value => Math.round(this.minpos + (Math.log(value) - this.minval) / this.scale));
  }
}

export const RangeInput = ({ id, control, name, minval = 1, maxval = 9999, rules, label, tooltip }: RangeInputProps) => {
  const logSlider = new Log({ minpos: 0, maxpos: 1000, minval, maxval });

  // console.log('control._defaultValues', control._defaultValues[name]);

  const defaultValue =
    control._defaultValues[name] && control._defaultValues[name][0]
      ? logSlider.positions(control._defaultValues[name])
      : [logSlider.minpos, logSlider.maxpos];

  const defaultEnabled = !!(control._defaultValues[name] && control._defaultValues[name][0]);

  const [value, setValue] = useState(defaultValue);
  const [enabled, setEnabled] = useState(defaultEnabled);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState, formState }) => {
        return (
          <>
            <FormGroup switch={true} disabled={!enabled}>
              <Input
                id={id}
                type="switch"
                role="switch"
                checked={enabled}
                onChange={() => {
                  const isNowEnabled = !enabled;
                  if (isNowEnabled) {
                    field.onChange(logSlider.values(value));
                  } else {
                    field.onChange(null);
                  }
                  setEnabled(isNowEnabled);
                }}
              />
              {label && tooltip && (
                <label
                  style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}
                  htmlFor={id}
                  className="form-label"
                >
                  {label}
                  <FontAwesomeIcon id={`${id}-label`} size="lg" icon="circle-info" />
                  <UncontrolledPopover placement="top" target={`${id}-label`} trigger="hover">
                    <PopoverHeader>
                      <Translate contentKey={`${tooltip}.header`} />
                    </PopoverHeader>
                    <PopoverBody>
                      <Translate contentKey={`${tooltip}.body`} />
                    </PopoverBody>
                  </UncontrolledPopover>
                </label>
              )}
              {label && !tooltip && (
                <Label htmlFor={id} className="form-label">
                  {label}
                </Label>
              )}
            </FormGroup>
            <InputGroup>
              <Range
                step={1}
                min={logSlider.minpos}
                max={logSlider.maxpos}
                disabled={!enabled}
                values={value}
                onChange={setValue}
                onFinalChange={v => field.onChange(logSlider.values(v))}
                renderTrack={({ props, children, disabled }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: '39px',
                      display: 'flex',
                      width: '100%',
                      paddingLeft: '21px',
                      paddingRight: '21px',
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: '5px',
                        width: '100%',
                        borderRadius: '4px',
                        background: getTrackBackground({
                          values: value,
                          colors: ['#ccc', disabled ? '#ccc' : 'rgb(1, 70, 129)', '#ccc'],
                          min: logSlider.minpos,
                          max: logSlider.maxpos,
                          rtl: false,
                        }),
                        alignSelf: 'center',
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ index, props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '2rem',
                      width: '48px',
                      backgroundColor: '#FFF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ced4da',
                      borderRadius: '0.25rem',
                      fontSize: '0.875rem',
                    }}
                  >
                    {logSlider.values(value)[index].toFixed(0)}
                  </div>
                )}
              />
            </InputGroup>
            <p id={`${id}-typeaheadError`} className="invalid-feedback">
              {fieldState.error?.message}
            </p>
          </>
        );
      }}
    />
  );
};
