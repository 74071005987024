/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable complexity */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Label,
  Spinner,
  Card,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldError, FieldValues, useForm } from 'react-hook-form';

import { getEntity as getBiologicalModel } from 'app/entities/biological-model/biological-model.reducer';

import { getEntity as getMaterialProperties } from 'app/entities/material-properties/material-properties.reducer';
import { getEntities as getAssays } from 'app/entities/assay/assay.reducer';
import { getEntities as getPathwayOfToxicities } from 'app/entities/pathway-of-toxicity/pathway-of-toxicity.reducer';
import { getEntities as getAdverseOutcomePathways } from 'app/entities/adverse-outcome-pathway/adverse-outcome-pathway.reducer';
import { getEntities as getPublications } from 'app/entities/publication/publication.reducer';
import { getEntities as getAnimalSpecies } from 'app/entities/animal-species/animal-species.reducer';
import { getEntities as getCellCultures } from 'app/entities/cell-culture/cell-culture.reducer';
import { getEntities as getReferenceMaterials } from 'app/entities/reference-material/reference-material.reducer';
import { getEntities as getTechnicalApplications } from 'app/entities/technical-application/technical-application.reducer';
import { getEntities as getRelevanceCriteria } from 'app/entities/relevance-criteria/relevance-criteria.reducer';
import { getEntity, updateEntity, createEntity, reset as resetEntity } from './dataset.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { QualityScore } from 'app/shared/model/enumerations/quality-score.model';
import { StudyType } from 'app/shared/model/enumerations/study-type.model';
import { ModalAnimalSpeciesCreate } from './components/modal-animal-species-create';
import { ModalCellCultureCreate } from './components/modal-cell-culture-create';
import { ModalReferenceMaterialCreate } from './components/modal-reference-material-create';
import { EffectCategoryOverviewCard } from './components/effect-category-overview-card';
import { AttributeNameValue } from './components/attribute-name-value';
import { PathwaysOfToxicityCard } from './components/pathways-of-toxicity-card';
import { AdverseOutcomePathwaysCard } from './components/adverse-outcome-pathways-card';
import { getReviewState, ReviewState } from './components/review-state-display';
import { markAsCurated } from './dataset.reducer';
import { ApprovalState } from 'app/shared/model/enumerations/approval-state.model';
import { MaterialPropertiesColumns } from './components/material-properties-columns';
import { defaultTo, filter, isObjectLike } from 'lodash';
import { EffectCategory, EffectCategoryPath } from 'app/shared/model/enumerations/effect-category.model';
import { BiologicalModelColumns } from './components/biological-model-columns';
import { ModalRelevanceCriteriaSelection } from './components/modal-relevance-criteria-selection';
import { RelevanceLabel } from './components/relevance-label';
import { TypeaheadField } from '../material-properties/components/typeahead-field';
import { ModalTechnicalApplicationCreate } from './components/modal-technical-application-create';
import { Relevance } from 'app/shared/model/enumerations/relevance.model';
import { IPublication } from 'app/shared/model/publication.model';
import { ITechnicalApplication } from '../../shared/model/technical-application.model';

export const DatasetUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const pageLocation = useLocation();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const params = new URLSearchParams(pageLocation.search);
  const publicationId = parseInt(params.get('publicationId.equals'), 10);

  // We start with undefined and use to track it if we have dispatched all
  // loading of dependencies, so we can set the default values once we
  // have these all.
  const [loadingOfDependenciesStarted, setLoadingOfDependenciesStarted] = useState<boolean>(undefined);

  const assays = useAppSelector(state => state.assay.entities);
  const pathwayOfToxicities = useAppSelector(state => state.pathwayOfToxicity.entities);
  const adverseOutcomePathways = useAppSelector(state => state.adverseOutcomePathway.entities);
  const publications = useAppSelector(state => state.publication.entities);
  const animalSpecies = useAppSelector(state => state.animalSpecies.entities);
  const cellCultures = useAppSelector(state => state.cellCulture.entities);
  const referenceMaterials = useAppSelector(state => state.referenceMaterial.entities);
  const technicalApplications = useAppSelector(state => state.technicalApplication.entities);
  const datasetEntity = useAppSelector(state => state.dataset.entity);

  const dependenciesAreStillLoading = useAppSelector(
    state =>
      state.dataset.loading ||
      state.assay.loading ||
      state.pathwayOfToxicity.loading ||
      state.adverseOutcomePathway.loading ||
      state.publication.loading ||
      state.animalSpecies.loading ||
      state.cellCulture.loading ||
      state.referenceMaterial.loading ||
      state.relevanceCriteria.loading ||
      state.technicalApplication.loading,
  );

  const loading = useAppSelector(state => state.dataset.loading);
  const updating = useAppSelector(state => state.dataset.updating);
  const qualityScoreValues = Object.keys(QualityScore);
  const studyTypeValues = Object.keys(StudyType);

  // Modals

  const [relevanceCriteriaModalIsOpen, setRelevanceCriteriaModalIsOpen] = useState(false);
  const handleClose = () => {
    navigate('/dataset' + pageLocation.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id))
        .unwrap()
        .then(({ data: { material, biologicalModel } }) => {
          return Promise.all([
            material ? dispatch(getMaterialProperties(material.id)) : Promise.resolve(),
            biologicalModel ? dispatch(getBiologicalModel(biologicalModel.id)) : Promise.resolve(),
          ]);
        });
    }

    dispatch(getAssays({}));
    dispatch(getPathwayOfToxicities({}));
    dispatch(getAdverseOutcomePathways({}));
    // To "overcome" pagination:
    dispatch(getPublications({ sort: 'authors', size: 2 ** 23 }));
    dispatch(getAnimalSpecies({}));
    dispatch(getCellCultures({}));
    dispatch(getReferenceMaterials({}));
    dispatch(getRelevanceCriteria({}));
    dispatch(getTechnicalApplications({}));
    setLoadingOfDependenciesStarted(true);

    return () => {
      dispatch(resetEntity());
    };
  }, []);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...datasetEntity,
      ...values,
      inVivo: animalSpecies.find(it => it.id.toString() === values.inVivo.toString()),
      inVitro: cellCultures.find(it => it.id.toString() === values.inVitro.toString()),
      controlParticle: referenceMaterials.find(it => it.id.toString() === values.controlParticle.toString()),
      // Always use the values from the original dataset, because they are not
      // managed by the form and could be overwritten by the form values with
      // stale data:
      assays: datasetEntity.assays,
      effects: datasetEntity.effects,
      pots: datasetEntity.pots,
      aops: datasetEntity.aops,
      comments: datasetEntity.comments,
    };

    if (!entity.id) {
      dispatch(createEntity(entity))
        .unwrap()
        .then(({ data: { id } }) =>
          // fetch so we get the "full" DTO with empty arrays for associations instead of null
          dispatch(getEntity(id))
            .unwrap()
            .then(() => navigate(`/dataset/${id}/edit` + pageLocation.search, { replace: true })),
        );
    } else {
      // console.log("dispatch(updateEntity(entity));", values, "datasetEntity", datasetEntity);
      dispatch(updateEntity(entity));
    }
  };

  const promoteToCurated = () => {
    dispatch(markAsCurated(datasetEntity));
  };

  const defaultValues =
    isNew && publicationId
      ? {
          publication: publications.find(p => p.id === publicationId),
        }
      : isNew
      ? {}
      : {
          qualityScore: 'NA',
          type: 'MECHANISTIC',
          ...datasetEntity,
          inVivo: datasetEntity?.inVivo?.id,
          inVitro: datasetEntity?.inVitro?.id,
          controlParticle: datasetEntity?.controlParticle?.id,
        };

  // These are not managed by the form but set/saved in modal dialogs,
  // so to make sure that they don't get overwritten, we remove them
  // from the default values:
  delete defaultValues.relevanceCriteria;
  delete defaultValues.aops;
  delete defaultValues.pots;

  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    formState: { errors, touchedFields, dirtyFields },
  } = useForm<FieldValues>({ mode: 'onTouched', defaultValues });

  useEffect(() => {
    if (loadingOfDependenciesStarted && !dependenciesAreStillLoading) {
      console.log('All dependencies finished loading! Setting default form values now.');
      setLoadingOfDependenciesStarted(false);
      reset(defaultValues);
    } else if (loadingOfDependenciesStarted === false && dependenciesAreStillLoading) {
      console.log(
        'A dependency has changed! Resetting the form with the current (possibly unsaved) values, so that new options are picked up.',
      );
      reset(getValues());
    }
  }, [loadingOfDependenciesStarted, dependenciesAreStillLoading]);

  if (loadingOfDependenciesStarted === undefined || (loadingOfDependenciesStarted && dependenciesAreStillLoading)) {
    return (
      <Row className="justify-content-center">
        <Spinner type="grow">Loading...</Spinner>
      </Row>
    );
  }

  const datasetReviewState = getReviewState(datasetEntity);

  return (
    <>
      {/* <Prompt when={Object.keys(dirtyFields).length > 0} message="There are unsaved changes. Are you sure you want to leave?" /> */}
      {/* The modal dialogs, which contain forms, must be kept outside
       * of the main form hierarchy: */}
      <ModalRelevanceCriteriaSelection
        isOpen={relevanceCriteriaModalIsOpen}
        toggle={() => setRelevanceCriteriaModalIsOpen(!relevanceCriteriaModalIsOpen)}
        dataset={datasetEntity}
      />

      <Breadcrumb>
        <BreadcrumbItem active tag={Link} to={`/dataset${pageLocation.search}`}>
          Datasets
        </BreadcrumbItem>
        {datasetEntity.id && (
          <BreadcrumbItem active tag={Link} to={`/dataset/${datasetEntity.id}${pageLocation.search}`}>
            {datasetEntity.id}
          </BreadcrumbItem>
        )}
      </Breadcrumb>

      <Row className="mb-4">
        <Col md="6">
          <h2 id="coconApp.dataset.home.createOrEditLabel" data-cy="DatasetCreateUpdateHeading">
            <Translate contentKey="coconApp.dataset.home.createOrEditLabel">Create or edit a Dataset</Translate>
          </h2>
        </Col>
        <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
          <Link to={id ? `../approval-history${pageLocation.search}` : pageLocation.search}>
            <Button className="pill" color="success" disabled>
              Review State: <ReviewState dataset={datasetEntity} />
            </Button>
          </Link>
          &nbsp;
          {datasetReviewState !== ApprovalState.CURATED && (
            <Button color="primary" onClick={promoteToCurated} disabled={updating || !id}>
              Promote to Curated &nbsp;
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
          )}
        </Col>
      </Row>
      {loading && (
        <Row className="justify-content-center">
          <Col md="8">
            <p>Loading...</p>
          </Col>
        </Row>
      )}
      <Card>
        <CardHeader tag="h5">Overall / General Information</CardHeader>
        <CardBody>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Form id="dataset-update-form" onSubmit={handleSubmit(saveEntity)}>
            <Row>
              <Col md="8">
                <TypeaheadField
                  id="dataset-publication"
                  name="publication"
                  control={control}
                  label={translate('coconApp.dataset.publication')}
                  labelKey={(p: IPublication) => `${p.authors} (${p.year}) ${p.doi}`}
                  options={publications}
                />
              </Col>
              <Col md="1">
                <FormGroup>
                  <Label>
                    <Translate contentKey="coconApp.dataset.relevance"></Translate>
                  </Label>
                  <br />
                  <Button className="pill" color="secondary" disabled>
                    <RelevanceLabel key={datasetEntity.relevanceCriteria?.length} dataset={datasetEntity} />
                  </Button>
                </FormGroup>
              </Col>
              <Col md="1">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <Button block color="primary" onClick={() => setRelevanceCriteriaModalIsOpen(true)}>
                    <Translate contentKey="entity.action.edit"></Translate>
                  </Button>
                </FormGroup>
              </Col>
              <Col md="2">
                <ValidatedField
                  label={translate('coconApp.dataset.qualityScore')}
                  id="dataset-qualityScore"
                  name="qualityScore"
                  data-cy="qualityScore"
                  type="select"
                  register={register}
                  error={errors.qualityScore as FieldError}
                  isTouched={touchedFields.qualityScore}
                  isDirty={dirtyFields.qualityScore}
                >
                  {qualityScoreValues.map(qualityScore => (
                    <option value={qualityScore} key={qualityScore}>
                      {translate('coconApp.QualityScore.' + qualityScore)}
                    </option>
                  ))}
                </ValidatedField>
                <UncontrolledTooltip target="qualityScoreLabel">
                  <Translate contentKey="coconApp.dataset.help.qualityScore" />
                </UncontrolledTooltip>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <ValidatedField
                  id="dataset-inVivo"
                  name="inVivo"
                  data-cy="inVivo"
                  label={translate('coconApp.dataset.inVivo')}
                  register={register}
                  error={errors.inVivo as FieldError}
                  isTouched={touchedFields.inVivo}
                  isDirty={dirtyFields.inVivo}
                  validate={{
                    validate: () =>
                      getValues('inVivo') !== '' && getValues('inVitro') !== '' ? 'Cannot set both In Vivo and In Vitro' : undefined,
                  }}
                  type="select"
                >
                  <option value="" key="0" />
                  {animalSpecies
                    ? animalSpecies.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </ValidatedField>
              </Col>

              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalAnimalSpeciesCreate />
                </FormGroup>
              </Col>

              <Col md="3">
                <ValidatedField
                  id="dataset-inVitro"
                  name="inVitro"
                  data-cy="inVitro"
                  label={translate('coconApp.dataset.inVitro')}
                  type="select"
                  register={register}
                  error={errors.inVitro as FieldError}
                  isTouched={touchedFields.inVitro}
                  isDirty={dirtyFields.inVitro}
                  validate={{
                    validate: () =>
                      getValues('inVivo') !== '' && getValues('inVitro') !== '' ? 'Cannot set both In Vivo and In Vitro' : undefined,
                  }}
                >
                  <option value="" key="0" />
                  {cellCultures
                    ? cellCultures.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </ValidatedField>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalCellCultureCreate />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <ValidatedField
                  id="dataset-controlParticle"
                  name="controlParticle"
                  data-cy="controlParticle"
                  label={translate('coconApp.dataset.controlParticle')}
                  type="select"
                  register={register}
                  error={errors.controlParticle as FieldError}
                  isTouched={touchedFields.controlParticle}
                  isDirty={dirtyFields.controlParticle}
                >
                  <option value="" key="0" />
                  {referenceMaterials
                    ? referenceMaterials.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </ValidatedField>
              </Col>

              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalReferenceMaterialCreate />
                </FormGroup>
              </Col>
              <Col md="3">
                <TypeaheadField
                  id="dataset-technicalApplications"
                  name="technicalApplications"
                  control={control}
                  label={translate('coconApp.dataset.technicalApplications')}
                  multiple
                  labelKey={(t: ITechnicalApplication) => t.name}
                  options={technicalApplications}
                />
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalTechnicalApplicationCreate />
                </FormGroup>
              </Col>
              <Col md="2">
                <label
                  style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}
                  htmlFor="dataset-type"
                  id="dataset-type-label"
                  className="form-label"
                >
                  <Translate contentKey="coconApp.dataset.type" />
                  <FontAwesomeIcon size="lg" icon="circle-info" />
                  <UncontrolledPopover placement="top" target="dataset-type-label" trigger="hover">
                    <PopoverHeader>
                      <Translate contentKey="coconApp.StudyType.help.header" />
                    </PopoverHeader>
                    <PopoverBody>
                      <Translate contentKey="coconApp.StudyType.help.body" />
                    </PopoverBody>
                  </UncontrolledPopover>
                </label>
                <ValidatedField
                  id="dataset-type"
                  name="type"
                  data-cy="type"
                  type="select"
                  register={register}
                  error={errors.type as FieldError}
                  isTouched={touchedFields.type}
                  isDirty={dirtyFields.type}
                >
                  {studyTypeValues.map(studyType => (
                    <option value={studyType} key={studyType}>
                      {translate('coconApp.StudyType.' + studyType)}
                    </option>
                  ))}
                </ValidatedField>
              </Col>
            </Row>
            <Row>
              <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardHeader tag="h5" className="material-properties">
          <Translate contentKey="coconApp.materialProperties.detail.title">MaterialProperties</Translate>
        </CardHeader>

        <CardBody tag={Row}>
          <MaterialPropertiesColumns datasetEntity={datasetEntity} />

          <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
            <Button
              tag={Link}
              to={
                datasetEntity.material
                  ? `../material-properties/${datasetEntity.material.id}/edit${pageLocation.search}`
                  : `../material-properties/new${pageLocation.search}`
              }
              color="primary"
              disabled={updating || !id}
            >
              <FontAwesomeIcon icon="pencil-alt" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>{' '}
                <Translate contentKey="coconApp.materialProperties.detail.title">MaterialProperties</Translate>
              </span>
            </Button>
            <br />
          </Col>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardHeader tag="h5" className="biological-model">
          <Translate contentKey="coconApp.biologicalModel.detail.title">Biological Model</Translate>
          {datasetEntity.biologicalModel?.animalOrCell && (
            <span>: {translate('coconApp.AnimalOrCell.' + datasetEntity.biologicalModel.animalOrCell)}</span>
          )}
        </CardHeader>
        <CardBody tag={Row}>
          <BiologicalModelColumns datasetEntity={datasetEntity} />
          <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
            <Button
              tag={Link}
              to={
                datasetEntity.biologicalModel
                  ? `../biological-model/${datasetEntity.biologicalModel.id}/edit${pageLocation.search}`
                  : `../biological-model/new${pageLocation.search}`
              }
              color="primary"
              disabled={updating || !id}
            >
              <FontAwesomeIcon icon="pencil-alt" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>{' '}
                <Translate contentKey="coconApp.biologicalModel.detail.title">Biological Model</Translate>
              </span>
            </Button>
            <br />
          </Col>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardHeader tag="h5" className="comments">
          <Translate contentKey="coconApp.dataset.comments" />
        </CardHeader>
        <CardBody tag={Row}>
          <Col xs="auto">
            <>{datasetEntity.comments?.map(comment => <div key={comment.id}>{comment.comment}</div>)}</>
          </Col>
          <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
            <Button tag={Link} to={`/dataset/${id}/comment${pageLocation.search}`} color="primary" disabled={updating || !id}>
              <FontAwesomeIcon icon="pencil-alt" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>{' '}
                <Translate contentKey="coconApp.dataset.comments">Comments</Translate>
              </span>
            </Button>
          </Col>
        </CardBody>
      </Card>
      <br />
      <Row className="gy-4">
        {Object.keys(EffectCategory).map((effectCategory: EffectCategory) => (
          <Col key={effectCategory} xs="auto">
            <EffectCategoryOverviewCard
              datasetId={datasetEntity.id}
              effectCategory={effectCategory}
              editingEnabled={!!id}
              assays={datasetEntity.assays}
              allAssays={assays}
              effects={datasetEntity.effects?.filter(effect => effect.name.category === effectCategory)}
              effectsEditTarget={`/dataset/${id}/${EffectCategoryPath[effectCategory]}${pageLocation.search}`}
            />
          </Col>
        ))}
        <Col xs="auto">
          <PathwaysOfToxicityCard
            datasetId={datasetEntity.id}
            editingEnabled={!!id}
            pathwayOfToxicities={datasetEntity.pots}
            allPathwayOfToxicities={pathwayOfToxicities}
          />
        </Col>
        <Col xs="auto">
          <AdverseOutcomePathwaysCard
            datasetId={datasetEntity.id}
            editingEnabled={!!id}
            adverseOutcomePathways={datasetEntity.aops}
            allAdverseOutcomePathways={adverseOutcomePathways}
          />
        </Col>
      </Row>
    </>
  );
};

export default DatasetUpdate;
