import { IEffect } from 'app/shared/model/effect.model';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';

export interface IEffectName {
  id?: number;
  name?: string;
  category?: keyof typeof EffectCategory;
  displayOrder?: number | null;
  effects?: IEffect[] | null;
}

export const defaultValue: Readonly<IEffectName> = {};
