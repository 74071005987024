import { IDataset } from 'app/shared/model/dataset.model';
import { IEffectName } from 'app/shared/model/effect-name.model';
import { ObservedAdverseEffectLevel } from 'app/shared/model/enumerations/observed-adverse-effect-level.model';

export interface IEffect {
  id?: number;
  observedLevel?: keyof typeof ObservedAdverseEffectLevel | null;
  value?: number | null;
  comment?: string | null;
  dataset?: IDataset;
  name?: IEffectName | null;
}

export const defaultValue: Readonly<IEffect> = {};
