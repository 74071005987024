import { IUser } from 'app/shared/model/user.model';
import { IDataset } from 'app/shared/model/dataset.model';
import { ApprovalState } from 'app/shared/model/enumerations/approval-state.model';

export interface IApprovalHistory {
  id?: number;
  timestamp?: string;
  approvalState?: keyof typeof ApprovalState;
  user?: IUser | null;
  dataset?: IDataset | null;
}

export const defaultValue: Readonly<IApprovalHistory> = {};
