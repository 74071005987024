/* eslint-disable no-console */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IAssay } from 'app/shared/model/assay.model';
import { TypeaheadField } from 'app/entities/material-properties/components/typeahead-field';
import { FieldValues, useForm } from 'react-hook-form';
import { partialUpdateEntity } from '../dataset.reducer';
import { IDatasetToAssay } from 'app/shared/model/dataset-to-assay.model';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';
import { partition } from 'lodash';

export type IModalAssociateAssayProps = {
  datasetId: number;
  effectCategory: EffectCategory;
  isOpen: boolean;
  toggle: () => void;
  datasetAssays?: IDatasetToAssay[];
  assays: readonly IAssay[];
};

export const ModalAssociateAssay = ({ datasetId, effectCategory, isOpen, toggle, datasetAssays, assays }: IModalAssociateAssayProps) => {
  const dispatch = useAppDispatch();

  const updating = useAppSelector(state => state.dataset.updating);
  const updateSuccess = useAppSelector(state => state.dataset.updateSuccess);

  useEffect(() => {
    if (updateSuccess && isOpen) {
      toggle();
    }
  }, [updateSuccess]);

  const { handleSubmit, reset, control } = useForm<FieldValues>({ mode: 'onTouched' });

  // https://github.com/react-hook-form/react-hook-form/issues/1005
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // this part is for stopping parent forms to trigger their submit
    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        // prevent any outer forms from receiving the event too
        event.stopPropagation();
      }
    }

    return handleSubmit(async values => {
      // The `defaultSelected` variable contains the assets from all categories, so we need to separate those of the other categories, and merge them with the changed selection `values`

      const newAssaysForCategory =
        values.assays?.map(assay => ({
          effect: effectCategory,
          dataset: { id: datasetId },
          assay: { id: assay.id },
        })) || [];

      const [_, others] = partition(datasetAssays, assay => assay.effect === effectCategory);

      await dispatch(partialUpdateEntity({ id: datasetId, assays: [...newAssaysForCategory, ...others] }));
    })(event);
  };

  const defaultValues = {
    assays: datasetAssays
      ?.filter(assay => assay.effect === effectCategory)
      .map(assay => ({
        id: assay.assay.id,
        value: assay.assay.name,
        label: assay.assay.name,
      })),
  };

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Translate contentKey={`coconApp.EffectCategory.${effectCategory}`}></Translate>{' '}
        <Translate contentKey={`coconApp.assay.home.title`}></Translate>{' '}
      </ModalHeader>
      <ModalBody>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Form id={`ModalAssociateAssay-${effectCategory}`} onSubmit={onSubmit}>
          <TypeaheadField
            label={`Select ${translate('coconApp.dataset.assays')}`}
            id={`dataset-${effectCategory}`}
            name="assays"
            control={control}
            defaultSelected={defaultValues.assays}
            multiple
            options={assays.map(assay => ({ id: assay.id, value: assay.name, label: assay.name }))}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button
          color="primary"
          id="save-entity"
          data-cy="entityCreateSaveButton"
          type="submit"
          form={`ModalAssociateAssay-${effectCategory}`}
          disabled={updating}
        >
          <FontAwesomeIcon icon="save" />
          &nbsp;
          <Translate contentKey="entity.action.save">Save</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
