import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IApprovalHistory, defaultValue } from 'app/shared/model/approval-history.model';

const initialState: EntityState<IApprovalHistory> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/approval-histories';

// Actions

export const getEntities = createAsyncThunk(
  'approvalHistory/fetch_entity_list',
  async ({ page, size, sort, datasetId }: IQueryParams & { datasetId: string }) => {
    const requestUrl = `${apiUrl}?${sort ? `sort=${sort}&` : ''}datasetId=${datasetId}&cacheBuster=${new Date().getTime()}`;
    return axios.get<IApprovalHistory[]>(requestUrl);
  },
);

export const getEntity = createAsyncThunk(
  'approvalHistory/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IApprovalHistory>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

// slice

export const ApprovalHistorySlice = createEntitySlice({
  name: 'approvalHistory',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data.sort((a, b) => {
            if (!action.meta?.arg?.sort) {
              return 1;
            }
            const order = action.meta.arg.sort.split(',')[1];
            const predicate = action.meta.arg.sort.split(',')[0];
            return order === ASC ? (a[predicate] < b[predicate] ? -1 : 1) : b[predicate] < a[predicate] ? -1 : 1;
          }),
        };
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = ApprovalHistorySlice.actions;

// Reducer
export default ApprovalHistorySlice.reducer;
