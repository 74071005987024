import { IDataset } from 'app/shared/model/dataset.model';
import React from 'react';
import { Translate } from 'react-jhipster';

export const RelevanceLabel = ({ dataset: { relevanceCriteria, relevance } }: { dataset: IDataset }) => {
  if (relevanceCriteria && relevanceCriteria?.length > 0) {
    const satisfiedCriteria = relevanceCriteria?.length;
    return (
      <>
        <span>
          {satisfiedCriteria === 1 ? (
            <Translate contentKey="coconApp.Relevance.NONE" />
          ) : satisfiedCriteria === 2 ? (
            <Translate contentKey="coconApp.Relevance.LOW" />
          ) : satisfiedCriteria === 3 ? (
            <Translate contentKey="coconApp.Relevance.MEDIUM" />
          ) : satisfiedCriteria === 4 ? (
            <Translate contentKey="coconApp.Relevance.HIGH" />
          ) : null}
        </span>
      </>
    );
  } else if (relevance) {
    // imported data might not have individual relevance criteria
    // but just an aggregated score, so use that instead:
    return <Translate contentKey={`coconApp.Relevance.${relevance}`} />;
  } else {
    return <Translate contentKey="coconApp.Relevance.NONE" />;
  }
};
