import { IConcentration } from 'app/shared/model/concentration.model';
import { IMaterialSource } from 'app/shared/model/material-source.model';
import { IAdvancedMaterial } from 'app/shared/model/advanced-material.model';
import { IDataset } from 'app/shared/model/dataset.model';
import { Shape } from 'app/shared/model/enumerations/shape.model';
import { MaterialClassification } from 'app/shared/model/enumerations/material-classification.model';

export interface IMaterialProperties {
  id?: number;
  variant?: string | null;
  synthesis?: string | null;
  synthesisReceiptIngredients?: string | null;
  shape?: keyof typeof Shape | null;
  aspectRatio?: string | null;
  dimension1?: number | null;
  dimension2?: number | null;
  dimension3?: string | null;
  maxCalculatedDepos?: number | null;
  surfaceChargeInWater?: number | null;
  surfaceChargeInMediumProtein?: number | null;
  surfaceChargeInWaterSerumFree?: number | null;
  bet?: number | null;
  surfaceReactivity?: number | null;
  surfaceReactivityUnit?: string | null;
  crystallinity?: string | null;
  solubility?: string | null;
  agglomerationSizeMediumWithProtein?: number | null;
  agglomerationSizeMediumWithoutProteinOrWater?: number | null;
  agglomerationSizeMmadInAerosol?: number | null;
  coatingOrFunctionalization?: string | null;
  classification?: keyof typeof MaterialClassification | null;
  testedConcentrations?: IConcentration[] | null;
  source?: IMaterialSource | null;
  engineeredNanoMaterial?: IAdvancedMaterial | null;
  dataset?: IDataset | null;
}

export const defaultValue: Readonly<IMaterialProperties> = {};
