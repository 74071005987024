/* eslint-disable no-console */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import Typeahead, { TypeaheadComponentProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import { getOptionLabel } from 'react-bootstrap-typeahead/types/utils';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Translate } from 'react-jhipster';
import { Button, InputGroup, InputGroupText, Label, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';

type TypeaheadFieldProps = TypeaheadComponentProps & {
  id: string;
  name: string;
  control: Control<FieldValues, any>;
  label?;
  tooltip?: string;
  rules?;
  inputGroupText?: string;
};

function transformResultDefault(e, multiple) {
  if (multiple) {
    // instead of an empty array, we return null
    if (Array.isArray(e) && e.length === 0) {
      return null;
    }

    return e;
  } else {
    let result = e;

    // unwrap array
    if (Array.isArray(e)) {
      if (e.length > 0) {
        result = e[0];
      } else {
        result = null;
      }
    }

    // unwrap object
    if (result && result['customOption']) {
      result = result['label'];
    }

    return result;
  }
}

function filterBy(option, state) {
  if (state.selected.length) {
    return true;
  }
  const optionLabel = getOptionLabel(option, state.labelKey);
  return optionLabel.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
}

function renderMenuItemChildrenDefault(option, props) {
  return <Highlighter search={props.text}>{getOptionLabel(option, props.labelKey)}</Highlighter>;
}

export const TypeaheadField = ({
  id,
  control,
  name,
  rules,
  inputGroupText,
  label,
  tooltip,
  renderMenuItemChildren = renderMenuItemChildrenDefault,
  ...typeaheadProps
}: TypeaheadFieldProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState, formState }) => {
        const defaultValueFromForm = formState.defaultValues[name];

        let value = fieldState.isDirty ? field.value : defaultValueFromForm;

        if (value && !Array.isArray(value)) {
          if (typeaheadProps.multiple) {
            console.error('Typeahead multiple is set to non-array value');
          }
          value = [value];
        }

        const selected = value || [];
        /*
        if(typeaheadProps.multiple && typeaheadProps.allowNew ) {
          selected = [...typeaheadProps.options, ...selected]
        }*/
        /*
        console.log(
          'field ' + name + ' has value:',
          selected,
          'options from typeaheadProps are',
          typeaheadProps,
          'field is dirty?',
          fieldState.isDirty
        );*/

        return (
          <>
            {label && tooltip && (
              <label
                style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}
                htmlFor={id}
                className="form-label"
              >
                {label}
                <FontAwesomeIcon id={`${id}-label`} size="lg" icon="circle-info" />
                <UncontrolledPopover placement="top" target={`${id}-label`} trigger="hover">
                  <PopoverHeader>
                    <Translate contentKey={`${tooltip}.header`} />
                  </PopoverHeader>
                  <PopoverBody>
                    <Translate contentKey={`${tooltip}.body`} />
                  </PopoverBody>
                </UncontrolledPopover>
              </label>
            )}
            {label && !tooltip && (
              <Label htmlFor={id} className="form-label">
                {label}
              </Label>
            )}
            <InputGroup>
              <Typeahead
                {...field}
                flip
                filterBy={typeaheadProps.multiple ? undefined : filterBy}
                id={id}
                data-cy={name}
                className={fieldState.invalid ? 'is-invalid' : ''}
                aria-describedby={`${id}-typeaheadError`}
                newSelectionPrefix="Add new entry: "
                emptyLabel={typeaheadProps.allowNew ? 'Start typing to add a new entry' : 'No entries found'}
                clearButton
                onInputChange={e => {
                  if (typeaheadProps.allowNew) {
                    field.onChange(transformResultDefault(e, typeaheadProps.multiple));
                  }
                }}
                onChange={e => field.onChange(transformResultDefault(e, typeaheadProps.multiple))}
                isValid={fieldState.isTouched && !fieldState.error}
                {...typeaheadProps}
                selected={selected}
                renderMenuItemChildren={renderMenuItemChildren}
                inputProps={typeaheadProps.inputProps}
              ></Typeahead>
              {inputGroupText && <InputGroupText>{inputGroupText}</InputGroupText>}
            </InputGroup>
            <p id={`${id}-typeaheadError`} className="invalid-feedback">
              {fieldState.error?.message}
            </p>
          </>
        );
      }}
    />
  );
};
