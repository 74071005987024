import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EffectName from './effect-name';
import EffectNameDetail from './effect-name-detail';
import EffectNameUpdate from './effect-name-update';
import EffectNameDeleteDialog from './effect-name-delete-dialog';

const EffectNameRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EffectName />} />
    <Route path="new" element={<EffectNameUpdate />} />
    <Route path=":id">
      <Route index element={<EffectNameDetail />} />
      <Route path="edit" element={<EffectNameUpdate />} />
      <Route path="delete" element={<EffectNameDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EffectNameRoutes;
