import { IDataset } from 'app/shared/model/dataset.model';

export interface IComment {
  id?: number;
  comment?: string;
  timestamp?: string;
  dataset?: IDataset;
}

export const defaultValue: Readonly<IComment> = {};
