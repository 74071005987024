import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ModalCreateEntity } from 'app/shared/components/modal-create-entity';
import React, { useEffect } from 'react';
import { Translate, ValidatedForm, ValidatedField, translate } from 'react-jhipster';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { createEntity } from '../../advanced-material/advanced-material.reducer';

export const ModalAdvancedMaterialCreate = ({ isOpen, toggle }) => {
  const dispatch = useAppDispatch();

  const updating = useAppSelector(state => state.advancedMaterial.updating);
  const updateSuccess = useAppSelector(state => state.advancedMaterial.updateSuccess);

  const saveEntity = values => {
    const entity = {
      ...values,
    };

    dispatch(createEntity(entity));
  };

  useEffect(() => {
    if (updateSuccess && isOpen) {
      toggle();
    }
  }, [updateSuccess]);

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Translate contentKey={`coconApp.advancedMaterial.home.createLabel`}></Translate>
      </ModalHeader>
      <ModalBody>
        <ValidatedForm id="ModalCreateEntity" defaultValues={{}} onSubmit={saveEntity}>
          <ValidatedField
            label={translate(`coconApp.advancedMaterial.molecularFormula`)}
            id={`advancedMaterial-molecularFormula`}
            name="molecularFormula"
            data-cy="molecularFormula"
            type="text"
            validate={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          />
          <ValidatedField
            label={translate(`coconApp.advancedMaterial.trivialName`)}
            id={`advancedMaterial-trivialName`}
            name="trivialName"
            data-cy="trivialName"
            type="text"
          />
        </ValidatedForm>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button
          color="primary"
          id="save-entity"
          data-cy="entityCreateSaveButton"
          type="submit"
          form="ModalCreateEntity"
          disabled={updating}
        >
          <FontAwesomeIcon icon="save" />
          &nbsp;
          <Translate contentKey="entity.action.save">Save</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
