/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities as getDatasets } from 'app/entities/dataset/dataset.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ObservedAdverseEffectLevel } from 'app/shared/model/enumerations/observed-adverse-effect-level.model';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';

import { getEntity, getEntities, updateEntity, createEntity, deleteEntity, reset } from 'app/entities/dataset/effect/effect.reducer';

import { getEntitiesByCategory as getEffectNames } from 'app/entities/effect-name/effect-name.reducer';

export type EffectUpdateProps = {
  category: EffectCategory;
};

export const EffectUpdate = (props: EffectUpdateProps) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const { id: datasetId } = useParams<'id'>();
  const { effectId } = useParams<'effectId'>();
  const isNew = effectId === undefined;

  const names = useAppSelector(state => state.effectName.entities);

  const effectEntity = useAppSelector(state => state.effect.entity);
  const loading = useAppSelector(state => state.effect.loading);
  const updating = useAppSelector(state => state.effect.updating);
  const updateSuccess = useAppSelector(state => state.effect.updateSuccess);

  const observedAdverseEffectLevelValues = Object.keys(ObservedAdverseEffectLevel);

  const handleClose = () => {
    navigate('../' + pageLocation.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(effectId));
    }
    dispatch(getEffectNames({ category: props.category }));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...effectEntity,
      ...values,
      dataset: { id: datasetId },
      name: names.find(it => it.id.toString() === values.name.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          observedLevel: 'NOAEL',
          ...effectEntity,
          dataset: effectEntity?.dataset?.id,
          name: effectEntity?.name?.id,
        };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem active tag={Link} to={`/dataset${pageLocation.search}`}>
          Datasets
        </BreadcrumbItem>
        <BreadcrumbItem active tag={Link} to={`/dataset/${datasetId}/edit${pageLocation.search}`}>
          {datasetId}
        </BreadcrumbItem>
        <BreadcrumbItem active tag={Link} to={'../' + pageLocation.search}>
          <Translate contentKey={`coconApp.effect.home.title`} />
        </BreadcrumbItem>
      </Breadcrumb>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id={`coconApp.effect.home.createOrEditLabel`} data-cy="EffectCreateUpdateHeading">
            <Translate contentKey={`coconApp.effect.home.createOrEditLabel`}></Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate(`coconApp.effect.observedLevel`)}
                id={`$effect-observedLevel`}
                name="observedLevel"
                data-cy="observedLevel"
                type="select"
              >
                {observedAdverseEffectLevelValues.map(observedAdverseEffectLevel => (
                  <option value={observedAdverseEffectLevel} key={observedAdverseEffectLevel}>
                    {translate('coconApp.ObservedAdverseEffectLevel.' + observedAdverseEffectLevel)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate(`coconApp.effect.value`)}
                id={`effect-value`}
                name="value"
                data-cy="value"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate(`coconApp.effect.comment`)}
                id={`effect-comment`}
                name="comment"
                data-cy="comment"
                type="textarea"
              />
              <ValidatedField id={`effect-name`} name="name" label={translate(`coconApp.effect.name`)} type="select" required>
                <option value="" key="0" />
                {names
                  ? names.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>

              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EffectUpdate;
