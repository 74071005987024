import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Publication from './publication';
import RelevanceCriteria from './relevance-criteria';
import AnimalSpecies from './animal-species';
import CellCulture from './cell-culture';
import ReferenceMaterial from './reference-material';
import TechnicalApplication from './technical-application';
import Dataset from './dataset';
import AdvancedMaterial from './advanced-material';
import MaterialSource from './material-source';
import Origin from './origin';
import Organ from './organ';
import Application from './application';
import Assay from './assay';
import EffectName from './effect-name';
import PathwayOfToxicity from './pathway-of-toxicity';
import AdverseOutcomePathway from './adverse-outcome-pathway';
import DataImport from './data-import';
import TissueType from './tissue-type';
import StorageSite from './storage-site';
import SearchQuery from './search-query';

/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="publication/*" element={<Publication />} />
        <Route path="relevance-criteria/*" element={<RelevanceCriteria />} />
        <Route path="animal-species/*" element={<AnimalSpecies />} />
        <Route path="cell-culture/*" element={<CellCulture />} />
        <Route path="reference-material/*" element={<ReferenceMaterial />} />
        <Route path="technical-application/*" element={<TechnicalApplication />} />
        <Route path="dataset/*" element={<Dataset />} />
        <Route path="advanced-material/*" element={<AdvancedMaterial />} />
        <Route path="material-source/*" element={<MaterialSource />} />
        <Route path="tissue-type/*" element={<TissueType />} />
        <Route path="origin/*" element={<Origin />} />
        <Route path="organ/*" element={<Organ />} />
        <Route path="application/*" element={<Application />} />
        <Route path="assay/*" element={<Assay />} />
        <Route path="effect-name/*" element={<EffectName />} />
        <Route path="pathway-of-toxicity/*" element={<PathwayOfToxicity />} />
        <Route path="adverse-outcome-pathway/*" element={<AdverseOutcomePathway />} />
        <Route path="data-import/*" element={<DataImport />} />
        <Route path="storage-site/*" element={<StorageSite />} />
        <Route path="search-query/*" element={<SearchQuery />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
