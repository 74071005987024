/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Spinner,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities as getMaterialSources } from 'app/entities/material-source/material-source.reducer';
import { getEntities as getAdvancedMaterials } from 'app/entities/advanced-material/advanced-material.reducer';
import { partialUpdateEntity as partialUpdateDatasetEntity } from 'app/entities/dataset/dataset.reducer';
import { getEntity, getEntities, reset as resetEntity } from './material-properties.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Shape } from 'app/shared/model/enumerations/shape.model';
import { MaterialClassification } from 'app/shared/model/enumerations/material-classification.model';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { ModalAdvancedMaterialCreate } from './components/modal-engineered-nano-material-create';
import { ModalMaterialSourceCreate } from './components/modal-material-source-create';
import { TypeaheadField } from './components/typeahead-field';
import { sortBy, sortedUniq, without } from 'lodash';
import { CardHeader } from 'reactstrap';
import { ValidatedFieldInputGroup } from 'app/shared/validated-field-input-group';
import { getShapeTranslationKey } from 'app/shared/util/i18n-utils';
import { IConcentration } from 'app/shared/model/concentration.model';
import { TypeaheadFieldMultiEdit } from './components/typeahead-field-multi-edit';

export const MaterialPropertiesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const pageLocation = useLocation();

  const { id: datasetId } = useParams<'id'>();
  const { materialPropertiesId: id } = useParams<'materialPropertiesId'>();
  const isNew = id === undefined;

  // We start with undefined and use to track it if we have dispatched all
  // loading of dependencies, so we can set the default values once we
  // have these all.
  const [loadingOfDependenciesStarted, setLoadingOfDependenciesStarted] = useState<boolean>(undefined);

  const materialSources = useAppSelector(state => state.materialSource.entities);
  const advancedMaterials = useAppSelector(state => state.advancedMaterial.entities);
  const datasets = useAppSelector(state => state.dataset.entities);
  const materialPropertiesEntity = useAppSelector(state => state.materialProperties.entity);

  const synthesisValues = useAppSelector(state =>
    without(sortedUniq(sortBy(state.materialProperties.entities.map(materialProperties => materialProperties.synthesis))), null, ''),
  );

  const crystallinityValues = useAppSelector(state =>
    without(sortedUniq(sortBy(state.materialProperties.entities.map(materialProperties => materialProperties.crystallinity))), null, ''),
  );

  const variantValues = useAppSelector(state =>
    without(sortedUniq(sortBy(state.materialProperties.entities.map(materialProperties => materialProperties.variant))), null, ''),
  );

  const coatingOrFunctionalizationValues = useAppSelector(state =>
    without(
      sortedUniq(sortBy(state.materialProperties.entities.map(materialProperties => materialProperties.coatingOrFunctionalization))),
      null,
      '',
    ),
  );

  const dependenciesAreStillLoading = useAppSelector(
    state =>
      state.materialSource.loading ||
      state.advancedMaterial.loading ||
      state.materialProperties.loadingEntity ||
      state.materialProperties.loadingEntities,
  );

  const updating = useAppSelector(state => state.materialProperties.updating);

  const shapeValues = Object.keys(Shape);
  const materialClassificationValues = Object.keys(MaterialClassification);

  // Modals
  const [engineeredNanoMaterialModalIsOpen, setAdvancedMaterialModalIsOpen] = useState(false);

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }
    // needed for typehead
    dispatch(getEntities({}));
    dispatch(getMaterialSources({}));
    dispatch(getAdvancedMaterials({}));
    setLoadingOfDependenciesStarted(true);
  }, []);

  const saveEntity = values => {
    /* CHECK, needed?
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.dimension1 !== undefined && typeof values.dimension1 !== 'number') {
      values.dimension1 = Number(values.dimension1);
    }
    if (values.dimension2 !== undefined && typeof values.dimension2 !== 'number') {
      values.dimension2 = Number(values.dimension2);
    }
    if (values.maxCalculatedDepos !== undefined && typeof values.maxCalculatedDepos !== 'number') {
      values.maxCalculatedDepos = Number(values.maxCalculatedDepos);
    }
    if (values.surfaceChargeInWater !== undefined && typeof values.surfaceChargeInWater !== 'number') {
      values.surfaceChargeInWater = Number(values.surfaceChargeInWater);
    }
    if (values.surfaceChargeInMediumProtein !== undefined && typeof values.surfaceChargeInMediumProtein !== 'number') {
      values.surfaceChargeInMediumProtein = Number(values.surfaceChargeInMediumProtein);
    }
    if (values.surfaceChargeInWaterSerumFree !== undefined && typeof values.surfaceChargeInWaterSerumFree !== 'number') {
      values.surfaceChargeInWaterSerumFree = Number(values.surfaceChargeInWaterSerumFree);
    }
    if (values.bet !== undefined && typeof values.bet !== 'number') {
      values.bet = Number(values.bet);
    }
    if (values.surfaceReactivity !== undefined && typeof values.surfaceReactivity !== 'number') {
      values.surfaceReactivity = Number(values.surfaceReactivity);
    }
    if (values.agglomerationSizeMediumWithProtein !== undefined && typeof values.agglomerationSizeMediumWithProtein !== 'number') {
      values.agglomerationSizeMediumWithProtein = Number(values.agglomerationSizeMediumWithProtein);
    }
    if (
      values.agglomerationSizeMediumWithoutProteinOrWater !== undefined &&
      typeof values.agglomerationSizeMediumWithoutProteinOrWater !== 'number'
    ) {
      values.agglomerationSizeMediumWithoutProteinOrWater = Number(values.agglomerationSizeMediumWithoutProteinOrWater);
    }
    if (values.agglomerationSizeMmadInAerosol !== undefined && typeof values.agglomerationSizeMmadInAerosol !== 'number') {
      values.agglomerationSizeMmadInAerosol = Number(values.agglomerationSizeMmadInAerosol);
    }*/

    const entity = {
      ...materialPropertiesEntity,
      ...values,
      source: materialSources.find(it => it.id.toString() === values.source.toString()),
      engineeredNanoMaterial: advancedMaterials.find(it => it.id.toString() === values.engineeredNanoMaterial.toString()),
    };

    if (isNew) {
      // Instead of creating just a new entity, we patch the dataset to get
      // the one-to-one association updated as well. We then re-fetch the
      // material properties so that the redux store is updated. Finally,
      // we replace the browser history.

      dispatch(
        partialUpdateDatasetEntity({
          id: datasetId,
          material: entity,
        }),
      )
        .unwrap()
        .then(({ data: { material } }) =>
          dispatch(getEntity(material.id))
            .unwrap()
            .then(() => navigate(`/dataset/${datasetId}/material-properties/${material.id}/edit${pageLocation.search}`, { replace: true })),
        );
    } else {
      dispatch(
        partialUpdateDatasetEntity({
          id: datasetId,
          material: entity,
        }),
      );
      dispatch(getEntity(id));
    }
  };

  const defaultValues = isNew
    ? {}
    : {
        shape: 'ROUND',
        classification: 'HARN',
        ...materialPropertiesEntity,
        source: materialPropertiesEntity?.source?.id,
        engineeredNanoMaterial: materialPropertiesEntity?.engineeredNanoMaterial?.id,
      };

  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    watch,
    formState: { errors, touchedFields, dirtyFields },
  } = useForm<FieldValues>({ mode: 'onTouched', defaultValues });

  useEffect(() => {
    if (loadingOfDependenciesStarted && !dependenciesAreStillLoading) {
      console.log('All dependencies finished loading! Setting default form values now.');
      setLoadingOfDependenciesStarted(false);
      reset(defaultValues);
    } else if (loadingOfDependenciesStarted === false && dependenciesAreStillLoading) {
      console.log(
        'A dependency has changed! Resetting the form with the current (possibly unsaved) values, so that new options are picked up.',
      );
      reset(getValues());
    }
  }, [loadingOfDependenciesStarted, dependenciesAreStillLoading]);

  if (loadingOfDependenciesStarted === undefined || (loadingOfDependenciesStarted && dependenciesAreStillLoading)) {
    return (
      <Row className="justify-content-center">
        <Spinner type="grow">Loading...</Spinner>
      </Row>
    );
  }

  return (
    <>
      {/* <Prompt when={Object.keys(dirtyFields).length > 0} message="There are unsaved changes. Are you sure you want to leave?" /> */}
      <ModalAdvancedMaterialCreate
        isOpen={engineeredNanoMaterialModalIsOpen}
        toggle={() => setAdvancedMaterialModalIsOpen(!engineeredNanoMaterialModalIsOpen)}
      />

      <Breadcrumb>
        <BreadcrumbItem active tag={Link} to={`/dataset${pageLocation.search}`}>
          Datasets
        </BreadcrumbItem>
        <BreadcrumbItem active tag={Link} to={`/dataset/${datasetId}/edit${pageLocation.search}`}>
          {datasetId}
        </BreadcrumbItem>
      </Breadcrumb>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Form onSubmit={handleSubmit(saveEntity)}>
        <Row>
          <Col md="8">
            <h2 id="coconApp.materialProperties.home.createOrEditLabel" data-cy="MaterialPropertiesCreateUpdateHeading">
              <Translate contentKey="coconApp.materialProperties.home.createOrEditLabel">Create or edit ENM Properties</Translate>
            </h2>
          </Col>
        </Row>

        <Card className="material-properties">
          <CardBody>
            <Row>
              <Col md="3">
                <ValidatedField
                  id="material-properties-engineeredNanoMaterial"
                  name="engineeredNanoMaterial"
                  data-cy="engineeredNanoMaterial"
                  label={translate('coconApp.materialProperties.engineeredNanoMaterial')}
                  type="select"
                  register={register}
                  error={errors.engineeredNanoMaterial as FieldError}
                  isTouched={touchedFields.engineeredNanoMaterial}
                  isDirty={dirtyFields.engineeredNanoMaterial}
                >
                  <option value="" key="0" />
                  {advancedMaterials
                    ? advancedMaterials.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.molecularFormula} {otherEntity.trivialName && `(${otherEntity.trivialName})`}
                        </option>
                      ))
                    : null}
                </ValidatedField>
              </Col>

              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <Button block color="primary" onClick={() => setAdvancedMaterialModalIsOpen(true)}>
                    <Translate contentKey="coconApp.advancedMaterial.home.createButton"></Translate>
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <ValidatedField
                  label={translate('coconApp.materialProperties.shape')}
                  id="material-properties-shape"
                  name="shape"
                  data-cy="shape"
                  type="select"
                  register={register}
                  error={errors.shape as FieldError}
                  isTouched={touchedFields.shape}
                  isDirty={dirtyFields.shape}
                >
                  {shapeValues.map(shape => (
                    <option value={shape} key={shape}>
                      {translate('coconApp.Shape.' + shape)}
                    </option>
                  ))}
                </ValidatedField>
              </Col>
              <Col md="2">
                <ValidatedField
                  label={translate('coconApp.materialProperties.aspectRatio')}
                  id="material-properties-aspectRatio"
                  name="aspectRatio"
                  data-cy="aspectRatio"
                  type="text"
                  register={register}
                  error={errors.aspectRatio as FieldError}
                  isTouched={touchedFields.aspectRatio}
                  isDirty={dirtyFields.aspectRatio}
                />
              </Col>
              <Col md="2">
                <ValidatedFieldInputGroup
                  label={translate(getShapeTranslationKey(watch('shape'), 'dimension1'))}
                  id="material-properties-dimension1"
                  name="dimension1"
                  data-cy="dimension1"
                  type="number"
                  step="0.01"
                  register={register}
                  error={errors.dimension1 as FieldError}
                  isTouched={touchedFields.dimension1}
                  isDirty={dirtyFields.dimension1}
                  inputGroupText={translate('coconApp.materialProperties.dimension1Unit')}
                />
              </Col>
              <Col md="2">
                <ValidatedFieldInputGroup
                  label={translate(getShapeTranslationKey(watch('shape'), 'dimension2'))}
                  disabled={
                    (watch('shape') === Shape.ROUND ||
                      watch('shape') === Shape.SPINDLES ||
                      watch('shape') === Shape.NANOHORNS ||
                      watch('shape') === Shape.HOLLOW_BUBBLES ||
                      watch('shape') === Shape.IRREGULAR ||
                      watch('shape') === Shape.RATTLES) &&
                    !watch('dimension2')
                  }
                  id="material-properties-dimension2"
                  name="dimension2"
                  data-cy="dimension2"
                  type="number"
                  step="0.01"
                  register={register}
                  error={errors.dimension2 as FieldError}
                  isTouched={touchedFields.dimension2}
                  isDirty={dirtyFields.dimension2}
                  inputGroupText={translate('coconApp.materialProperties.dimension2Unit')}
                />
              </Col>
              <Col md="1">
                <ValidatedField
                  label={translate(getShapeTranslationKey(watch('shape'), 'dimension3'))}
                  disabled={watch('shape') !== Shape.PLATELET && !watch('dimension3')}
                  id="material-properties-dimension3"
                  name="dimension3"
                  data-cy="dimension3"
                  type="text"
                  register={register}
                  error={errors.dimension3 as FieldError}
                  isTouched={touchedFields.dimension3}
                  isDirty={dirtyFields.dimension3}
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <ValidatedField
                  id="material-properties-source"
                  name="source"
                  data-cy="source"
                  label={translate('coconApp.materialProperties.source')}
                  type="select"
                  register={register}
                  error={errors.source as FieldError}
                  isTouched={touchedFields.source}
                  isDirty={dirtyFields.source}
                >
                  <option value="" key="0" />
                  {materialSources
                    ? materialSources.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                </ValidatedField>
              </Col>

              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalMaterialSourceCreate />
                </FormGroup>
              </Col>

              <Col md="7">
                <TypeaheadField
                  label={translate('coconApp.materialProperties.variant')}
                  id="material-properties-variant"
                  name="variant"
                  control={control}
                  allowNew
                  options={variantValues}
                />
              </Col>
              <Col md="3">
                <TypeaheadField
                  label={translate('coconApp.materialProperties.synthesis')}
                  id="material-properties-synthesis"
                  name="synthesis"
                  control={control}
                  allowNew
                  options={synthesisValues}
                />
              </Col>
              <Col md="9">
                <ValidatedField
                  label={translate('coconApp.materialProperties.synthesisReceiptIngredients')}
                  id="material-properties-synthesisReceiptIngredients"
                  name="synthesisReceiptIngredients"
                  data-cy="synthesisReceiptIngredients"
                  type="text"
                  register={register}
                  error={errors.synthesisReceiptIngredients as FieldError}
                  isTouched={touchedFields.synthesisReceiptIngredients}
                  isDirty={dirtyFields.synthesisReceiptIngredients}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody>
            <Row>
              <Col md="3">
                <TypeaheadFieldMultiEdit
                  id="materia-properties-testedConcentrations"
                  name="testedConcentrations"
                  control={control}
                  rules={{
                    validate: values => values && (values.some(v => isNaN(v.value)) ? 'Concentrations must be numbers' : undefined),
                  }}
                  label={translate('coconApp.materialProperties.testedConcentration')}
                  valueAsNumber
                  defaultSelected={defaultValues['testedConcentrations']?.map(c => ({ ...c, label: `${c.value}` }))}
                  options={defaultValues['testedConcentrations']?.map(c => ({ ...c, label: `${c.value}` })) || []}
                  inputGroupText={translate('coconApp.materialProperties.testedConcentrationUnit')}
                />
              </Col>
              <Col md="3">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.materialProperties.maxCalculatedDepos')}
                  id="material-properties-maxCalculatedDepos"
                  name="maxCalculatedDepos"
                  data-cy="maxCalculatedDepos"
                  type="number"
                  step="0.01"
                  register={register}
                  error={errors.maxCalculatedDepos as FieldError}
                  isTouched={touchedFields.maxCalculatedDepos}
                  isDirty={dirtyFields.maxCalculatedDepos}
                  inputGroupText={translate('coconApp.materialProperties.maxCalculatedDeposUnit')}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardHeader>Surface Charge</CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.materialProperties.surfaceChargeInWater')}
                  id="material-properties-surfaceChargeInWater"
                  name="surfaceChargeInWater"
                  data-cy="surfaceChargeInWater"
                  type="number"
                  step="0.01"
                  register={register}
                  error={errors.surfaceChargeInWater as FieldError}
                  isTouched={touchedFields.surfaceChargeInWater}
                  isDirty={dirtyFields.surfaceChargeInWater}
                  inputGroupText={translate('coconApp.materialProperties.surfaceChargeInWaterUnit')}
                />
              </Col>
              <Col md="4">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.materialProperties.surfaceChargeInMediumProtein')}
                  id="material-properties-surfaceChargeInMediumProtein"
                  name="surfaceChargeInMediumProtein"
                  data-cy="surfaceChargeInMediumProtein"
                  type="number"
                  step="0.01"
                  register={register}
                  error={errors.surfaceChargeInMediumProtein as FieldError}
                  isTouched={touchedFields.surfaceChargeInMediumProtein}
                  isDirty={dirtyFields.surfaceChargeInMediumProtein}
                  inputGroupText={translate('coconApp.materialProperties.surfaceChargeInMediumProteinUnit')}
                />
              </Col>
              <Col md="4">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.materialProperties.surfaceChargeInWaterSerumFree')}
                  id="material-properties-surfaceChargeInWaterSerumFree"
                  name="surfaceChargeInWaterSerumFree"
                  data-cy="surfaceChargeInWaterSerumFree"
                  type="number"
                  step="0.01"
                  register={register}
                  error={errors.surfaceChargeInWaterSerumFree as FieldError}
                  isTouched={touchedFields.surfaceChargeInWaterSerumFree}
                  isDirty={dirtyFields.surfaceChargeInWaterSerumFree}
                  inputGroupText={translate('coconApp.materialProperties.surfaceChargeInWaterSerumFreeUnit')}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody>
            <Row>
              <Col md="2">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.materialProperties.bet')}
                  id="material-properties-bet"
                  name="bet"
                  data-cy="bet"
                  type="number"
                  step="0.01"
                  register={register}
                  error={errors.bet as FieldError}
                  isTouched={touchedFields.bet}
                  isDirty={dirtyFields.bet}
                  inputGroupText={translate('coconApp.materialProperties.betUnit')}
                />
              </Col>
              <Col md="2">
                <ValidatedField
                  label={translate('coconApp.materialProperties.surfaceReactivity')}
                  id="material-properties-surfaceReactivity"
                  name="surfaceReactivity"
                  data-cy="surfaceReactivity"
                  type="number"
                  step="0.01"
                  register={register}
                  error={errors.surfaceReactivity as FieldError}
                  isTouched={touchedFields.surfaceReactivity}
                  isDirty={dirtyFields.surfaceReactivity}
                />
              </Col>
              <Col md="4">
                <ValidatedField
                  label={translate('coconApp.materialProperties.surfaceReactivityUnit')}
                  id="material-properties-surfaceReactivityUnit"
                  name="surfaceReactivityUnit"
                  data-cy="surfaceReactivityUnit"
                  type="text"
                  register={register}
                  error={errors.surfaceReactivityUnit as FieldError}
                  isTouched={touchedFields.surfaceReactivityUnit}
                  isDirty={dirtyFields.surfaceReactivityUnit}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody>
            <Row>
              <Col md="4">
                <TypeaheadField
                  label={translate('coconApp.materialProperties.crystallinity')}
                  id="material-properties-crystallinity"
                  name="crystallinity"
                  control={control}
                  allowNew
                  options={crystallinityValues}
                  filterBy={() => true}
                />
              </Col>
              <Col md="4">
                <ValidatedField
                  label={translate('coconApp.materialProperties.solubility')}
                  id="material-properties-solubility"
                  name="solubility"
                  data-cy="solubility"
                  type="text"
                  register={register}
                  error={errors.solubility as FieldError}
                  isTouched={touchedFields.solubility}
                  isDirty={dirtyFields.solubility}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardHeader>
            <Translate contentKey="coconApp.materialProperties.agglomerationSize" />
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.materialProperties.agglomerationSizeMediumWithoutProteinOrWater')}
                  id="material-properties-agglomerationSizeMediumWithoutProteinOrWater"
                  name="agglomerationSizeMediumWithoutProteinOrWater"
                  data-cy="agglomerationSizeMediumWithoutProteinOrWater"
                  type="number"
                  step="0.1"
                  register={register}
                  error={errors.agglomerationSizeMediumWithoutProteinOrWater as FieldError}
                  isTouched={touchedFields.agglomerationSizeMediumWithoutProteinOrWater}
                  isDirty={dirtyFields.agglomerationSizeMediumWithoutProteinOrWater}
                  inputGroupText={translate('coconApp.materialProperties.agglomerationSizeUnit')}
                />
              </Col>
              <Col md="4">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.materialProperties.agglomerationSizeMediumWithProtein')}
                  id="material-properties-agglomerationSizeMediumWithProtein"
                  name="agglomerationSizeMediumWithProtein"
                  data-cy="agglomerationSizeMediumWithProtein"
                  type="number"
                  step="0.1"
                  register={register}
                  error={errors.agglomerationSizeMediumWithProtein as FieldError}
                  isTouched={touchedFields.agglomerationSizeMediumWithProtein}
                  isDirty={dirtyFields.agglomerationSizeMediumWithProtein}
                  inputGroupText={translate('coconApp.materialProperties.agglomerationSizeUnit')}
                />
              </Col>
              <Col md="4">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.materialProperties.agglomerationSizeMmadInAerosol')}
                  id="material-properties-agglomerationSizeMmadInAerosol"
                  name="agglomerationSizeMmadInAerosol"
                  data-cy="agglomerationSizeMmadInAerosol"
                  type="number"
                  step="0.1"
                  register={register}
                  error={errors.agglomerationSizeMmadInAerosol as FieldError}
                  isTouched={touchedFields.agglomerationSizeMmadInAerosol}
                  isDirty={dirtyFields.agglomerationSizeMmadInAerosol}
                  inputGroupText={translate('coconApp.materialProperties.agglomerationSizeUnit')}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody>
            <Row>
              <Col md="4">
                <TypeaheadField
                  label={translate('coconApp.materialProperties.coatingOrFunctionalization')}
                  id="material-properties-coatingOrFunctionalization"
                  name="coatingOrFunctionalization"
                  control={control}
                  allowNew
                  options={coatingOrFunctionalizationValues}
                />
              </Col>
              <Col md="2">
                <ValidatedField
                  label={translate('coconApp.materialProperties.classification')}
                  id="material-properties-classification"
                  name="classification"
                  data-cy="classification"
                  type="select"
                  register={register}
                  error={errors.classification as FieldError}
                  isTouched={touchedFields.classification}
                  isDirty={dirtyFields.classification}
                >
                  {materialClassificationValues.map(materialClassification => (
                    <option value={materialClassification} key={materialClassification}>
                      {translate('coconApp.MaterialClassification.' + materialClassification)}
                    </option>
                  ))}
                </ValidatedField>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </>
  );
};

export default MaterialPropertiesUpdate;
