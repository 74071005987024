import { IMaterialProperties } from 'app/shared/model/material-properties.model';
import { ISearchQuery } from 'app/shared/model/search-query.model';

export interface IAdvancedMaterial {
  id?: number;
  molecularFormula?: string;
  trivialName?: string | null;
  materialProperties?: IMaterialProperties[] | null;
  searchQueries?: ISearchQuery[] | null;
}

export const defaultValue: Readonly<IAdvancedMaterial> = {};
