import React, { Fragment, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './dataset.reducer';
import { DatasetColumns } from './components/dataset-columns';
import { translate, Translate } from 'react-jhipster';
import { MaterialPropertiesColumns } from './components/material-properties-columns';
import { BiologicalModelColumns } from './components/biological-model-columns';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';
import { AdverseOutcomePathwaysCard } from './components/adverse-outcome-pathways-card';
import { EffectCategoryOverviewCard } from './components/effect-category-overview-card';
import { PathwaysOfToxicityCard } from './components/pathways-of-toxicity-card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReviewState } from './components/review-state-display';
import { hasDataManagementAuthority } from 'app/shared/auth/private-route';

export const DatasetDetail = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const datasetEntity = useAppSelector(state => state.dataset.entity);
  const loading = useAppSelector(state => state.dataset.loading);

  const isAllowedToManageData = useAppSelector(hasDataManagementAuthority);

  if (loading || !datasetEntity.id) {
    return (
      <Row className="justify-content-center">
        <Spinner type="grow">Loading...</Spinner>
      </Row>
    );
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem active tag={Link} to={`/dataset${pageLocation.search}`}>
          Datasets
        </BreadcrumbItem>
      </Breadcrumb>

      <Row className="mb-4">
        <Col md="6">
          <h2>
            <Translate contentKey="coconApp.dataset.detail.title">Dataset</Translate>
          </h2>
        </Col>
        <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
          {isAllowedToManageData ? (
            <Link to={`${datasetEntity.id}/approval-history${pageLocation.search}`}>
              <Button className="pill" color="success" disabled>
                Review State: <ReviewState dataset={datasetEntity} />
              </Button>
            </Link>
          ) : (
            <Button className="pill" color="success" disabled>
              Review State: <ReviewState dataset={datasetEntity} />
            </Button>
          )}
          &nbsp;
          {isAllowedToManageData && (
            <Button tag={Link} to={`edit${pageLocation.search}`} color="primary" data-cy="entityEditButton">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="coconApp.dataset.home.editLabel"></Translate>
              </span>
            </Button>
          )}
        </Col>
      </Row>

      <Card>
        <CardHeader tag="h5">Overall / General Information</CardHeader>
        <CardBody tag={Row}>
          <DatasetColumns datasetEntity={datasetEntity} />
        </CardBody>
      </Card>
      <Card>
        <CardHeader tag="h5" className="material-properties">
          <Translate contentKey="coconApp.materialProperties.detail.title">MaterialProperties</Translate>
        </CardHeader>

        <CardBody tag={Row}>
          <MaterialPropertiesColumns datasetEntity={datasetEntity} />
        </CardBody>
      </Card>
      <Card>
        <CardHeader tag="h5" className="biological-model">
          <Translate contentKey="coconApp.biologicalModel.detail.title">Biological Model</Translate>
          {datasetEntity.biologicalModel?.animalOrCell && (
            <span>: {translate('coconApp.AnimalOrCell.' + datasetEntity.biologicalModel.animalOrCell)}</span>
          )}
        </CardHeader>
        <CardBody tag={Row}>
          <BiologicalModelColumns datasetEntity={datasetEntity} />
        </CardBody>
      </Card>
      {datasetEntity.comments.length > 0 && (
        <Card>
          <CardHeader tag="h5" className="comments">
            <Translate contentKey="coconApp.dataset.comments" />
          </CardHeader>
          <CardBody tag={Row}>
            <Col xs="auto">
              <>{datasetEntity.comments?.map(comment => <div key={comment.id}>{comment.comment}</div>)}</>
            </Col>
          </CardBody>
        </Card>
      )}
      {Object.keys(EffectCategory).map((effectCategory: EffectCategory) => (
        <Fragment key={effectCategory}>
          <EffectCategoryOverviewCard
            datasetId={datasetEntity.id}
            effectCategory={effectCategory}
            editable={false}
            assays={datasetEntity.assays}
            effects={datasetEntity.effects?.filter(effect => effect.name.category === effectCategory)}
            biologicalModel={datasetEntity.biologicalModel}
          />
        </Fragment>
      ))}
      <PathwaysOfToxicityCard datasetId={datasetEntity.id} pathwayOfToxicities={datasetEntity.pots} />
      <AdverseOutcomePathwaysCard datasetId={datasetEntity.id} adverseOutcomePathways={datasetEntity.aops} />
    </>
  );
};

export default DatasetDetail;
