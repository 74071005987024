import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { AttributeNameValue } from './attribute-name-value';
import { MaterialPropertiesColumns } from './material-properties-columns';
import { BiologicalModelColumns } from './biological-model-columns';
import { IDataset } from 'app/shared/model/dataset.model';
import { EffectRows } from './effect-rows';

export function DatasetDetailRows({ dataset }: { dataset: IDataset }) {
  return (
    <Container className="dataset-table-detail-container" fluid>
      <Row className="material-properties">
        <Col xs="auto">
          <span className="form-label">
            <Translate contentKey="coconApp.materialProperties.detail.title"></Translate>:
          </span>
        </Col>
        <MaterialPropertiesColumns datasetEntity={dataset} />
      </Row>
      <Row className="biological-model">
        <Col xs="auto">
          <span className="form-label">
            <Translate contentKey="coconApp.biologicalModel.detail.title"></Translate>:
          </span>
        </Col>
        <BiologicalModelColumns datasetEntity={dataset} />
      </Row>
      <EffectRows biologicalModel={dataset.biologicalModel} effects={dataset.effects} assays={dataset.assays} />
      {dataset.pots?.length > 0 && (
        <Row className="pots">
          <Col xs="auto">
            <span className="form-label">
              <Translate contentKey="coconApp.pathwayOfToxicity.abbreviated"></Translate>:
            </span>
          </Col>
          <AttributeNameValue values={dataset.pots?.map(pot => pot.name)} />
        </Row>
      )}
      {dataset.aops?.length > 0 && (
        <Row className="aops">
          <Col xs="auto">
            <span className="form-label">
              <Translate contentKey="coconApp.adverseOutcomePathway.abbreviated"></Translate>:
            </span>
          </Col>
          <AttributeNameValue values={dataset.aops?.map(aop => aop.name)} />
        </Row>
      )}
      {dataset.comments?.length > 0 && (
        <Row className="comments">
          <Col xs="auto">
            <span className="form-label">
              <Translate contentKey="coconApp.dataset.comments"></Translate>:
            </span>
          </Col>
          <Col xs="auto">
            <span className="form-label"></span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {dataset.comments?.map(comment => (
                <Fragment key={comment.id}>
                  <span>{comment.comment}</span>
                  <br />
                </Fragment>
              ))}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}
