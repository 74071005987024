import { IDataset } from 'app/shared/model/dataset.model';
import React from 'react';
import { translate } from 'react-jhipster';
import { AttributeNameValue } from './attribute-name-value';
import { RelevanceLabel } from './relevance-label';

export const DatasetColumns = ({ datasetEntity }: { datasetEntity: IDataset }) => {
  return (
    <>
      <AttributeNameValue
        contentKey="coconApp.dataset.publication"
        value={`${datasetEntity.publication?.authors} (${datasetEntity.publication?.year}) ${datasetEntity.publication?.doi}`}
      />

      <AttributeNameValue contentKey="coconApp.dataset.relevance">
        <RelevanceLabel key={datasetEntity.relevanceCriteria?.length} dataset={datasetEntity} />
      </AttributeNameValue>

      <AttributeNameValue
        contentKey="coconApp.dataset.qualityScore"
        value={translate('coconApp.QualityScore.' + datasetEntity.qualityScore)}
      />

      <AttributeNameValue contentKey="coconApp.dataset.inVivo" value={datasetEntity.inVivo?.name} />

      <AttributeNameValue contentKey="coconApp.dataset.inVitro" value={datasetEntity.inVitro?.name} />

      <AttributeNameValue contentKey="coconApp.dataset.controlParticle" value={datasetEntity.controlParticle?.name} />

      <AttributeNameValue
        contentKey="coconApp.dataset.technicalApplications"
        values={datasetEntity.technicalApplications?.map(ta => ta.name)}
      />

      <AttributeNameValue contentKey="coconApp.dataset.type" value={translate('coconApp.StudyType.' + datasetEntity.type)} />
    </>
  );
};
