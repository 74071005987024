import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}

      <MenuItem icon="asterisk" to="/advanced-material">
        <Translate contentKey="global.menu.entities.advancedMaterial" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/adverse-outcome-pathway">
        <Translate contentKey="global.menu.entities.adverseOutcomePathway" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/animal-species">
        <Translate contentKey="global.menu.entities.animalSpecies" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/application">
        <Translate contentKey="global.menu.entities.application" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/assay">
        <Translate contentKey="global.menu.entities.assay" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/cell-culture">
        <Translate contentKey="global.menu.entities.cellCulture" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/effect-name">
        <Translate contentKey="global.menu.entities.effectName" />
      </MenuItem>

      <MenuItem icon="asterisk" to="/material-source">
        <Translate contentKey="global.menu.entities.materialSource" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/origin">
        <Translate contentKey="global.menu.entities.origin" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organ">
        <Translate contentKey="global.menu.entities.organ" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/pathway-of-toxicity">
        <Translate contentKey="global.menu.entities.pathwayOfToxicity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/reference-material">
        <Translate contentKey="global.menu.entities.referenceMaterial" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/relevance-criteria">
        <Translate contentKey="global.menu.entities.relevanceCriteria" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/storage-site">
        <Translate contentKey="global.menu.entities.storageSite" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/technical-application">
        <Translate contentKey="global.menu.entities.technicalApplication" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/tissue-type">
        <Translate contentKey="global.menu.entities.tissueType" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
