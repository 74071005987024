import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MaterialSource from './material-source';
import MaterialSourceDetail from './material-source-detail';
import MaterialSourceUpdate from './material-source-update';
import MaterialSourceDeleteDialog from './material-source-delete-dialog';

const MaterialSourceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MaterialSource />} />
    <Route path="new" element={<MaterialSourceUpdate />} />
    <Route path=":id">
      <Route index element={<MaterialSourceDetail />} />
      <Route path="edit" element={<MaterialSourceUpdate />} />
      <Route path="delete" element={<MaterialSourceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MaterialSourceRoutes;
