import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TissueType from './tissue-type';
import TissueTypeUpdate from './tissue-type-update';
import TissueTypeDeleteDialog from './tissue-type-delete-dialog';

const TissueTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TissueType />} />
    <Route path="new" element={<TissueTypeUpdate />} />
    <Route path=":id">
      <Route path="edit" element={<TissueTypeUpdate />} />
      <Route path="delete" element={<TissueTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TissueTypeRoutes;
