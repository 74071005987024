import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IEffectName } from 'app/shared/model/effect-name.model';
import { IEffect } from 'app/shared/model/effect.model';
import { ObservedAdverseEffectLevel } from 'app/shared/model/enumerations/observed-adverse-effect-level.model';
import React from 'react';
import { Button, Col, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';

export const EffectNameValue = ({
  id,
  observedLevel,
  name,
  value,
  comment,
}: {
  id?: number;
  observedLevel?: keyof typeof ObservedAdverseEffectLevel | null;
  value?: number | string | null;
  comment?: string | null;
  name?: IEffectName | null;
}) => {
  const style =
    observedLevel === 'NOAEL'
      ? {
          backgroundColor: '#8DB4E2',
          borderColor: '#8DB4E2',
        }
      : observedLevel === 'LOEL'
      ? {
          backgroundColor: '#FFB900',
          borderColor: '#FFB900',
        }
      : observedLevel === 'UNOEL'
      ? {
          backgroundColor: '#88C92F',
          borderColor: '#88C92F',
        }
      : {};

  const prefix = observedLevel === 'NOAEL' ? '' : observedLevel === 'LOEL' ? '<' : observedLevel === 'UNOEL' ? '>' : '';

  const hasValue = !!value;

  const textToShow = hasValue ? `${prefix}${value}` : comment;

  return (
    <Col id={`effect-${id}`} xs="auto">
      {name && <div className="form-label">{name.name}</div>}

      <Button disabled style={style}>
        {textToShow}
      </Button>
      {comment && hasValue && (
        <>
          {' '}
          <Button className="btn-circle" color="primary" id="PopoverFocus" type="button" outline>
            <FontAwesomeIcon icon="comment-dots" />
          </Button>
          <UncontrolledPopover placement="right" target="PopoverFocus" trigger="focus">
            <PopoverHeader>Comment</PopoverHeader>
            <PopoverBody>{comment}</PopoverBody>
          </UncontrolledPopover>
        </>
      )}
    </Col>
  );
};
