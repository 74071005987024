/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
import { TypeaheadField } from 'app/entities/material-properties/components/typeahead-field';
import { IEffectName } from 'app/shared/model/effect-name.model';
import { IEffect } from 'app/shared/model/effect.model';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';
import axios from 'axios';
import { property, uniq } from 'lodash';
import React from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { translate, Translate, ValidatedInput } from 'react-jhipster';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

export type IExportSearchResultsProps = {
  searchQueryId: string;
  effectNamesPerCategory: {
    effectCategory: EffectCategory;
    effectsNamesOfCategory: IEffectName[];
  }[];
};

const ExportSearchResults = ({ searchQueryId, effectNamesPerCategory }: IExportSearchResultsProps) => {
  const submitQuery = values => {
    // The filter removes the false values (which are the effect names that are not selected)
    const allEffectIds = values.effects
      .filter(effect => effect.id)
      .map(effect => effect.id)
      .flat();

    // console.log(allEffectIds);
    values.effects = uniq(allEffectIds).map(id => ({ id }));
    // console.log(values.effects);

    const requestUrl = `api/search-queries/${searchQueryId}/export`;

    axios
      .post(requestUrl, values, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `cocon-export-${searchQueryId}.${values.format}`);
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 0);
      });
  };

  const { handleSubmit, register, control, setValue } = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues: {
      bibliography: true,
      format: 'xlsx',
    },
  });

  // To get the result values as a single array (of effect name ids), we need a index running over all the effect names in the categories.
  let index = 0;

  const ENM_PROPERTIES = [
    'aspectRatio',
    'source',
    'variant',
    'synthesis',
    'synthesisReceiptIngredients',
    'surfaceChargeInWater',
    'surfaceChargeInMediumProtein',
    'surfaceChargeInWaterSerumFree',
    'crystallinity',
    'coatingOrFunctionalization',
    'bet',
    'agglomerationSizeMediumWithProtein',
    'agglomerationSizeMediumWithoutProteinOrWater',
    'agglomerationSizeMmadInAerosol',
    'testedConcentrations',
  ];

  const BIOLOGICAL_MODEL_PROPERTIES = [
    'origins',
    'tissueType',
    'exposurePathway',
    'application',
    'effectiveDose',
    'exposurePerDay',
    'exposurePerWeek',
    'totalRepeats',
    'postExposure',
    'guideline',
  ];

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <Form onSubmit={handleSubmit(submitQuery)}>
      <Row className="mb-3">
        <Col>
          <h3>Export Search Results</h3>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="export-datasets">
            <CardHeader tag="h5">Effects to Export</CardHeader>
            <CardBody>
              {false && (
                <Row>
                  <Col xs="2">
                    <FormGroup>
                      <Label>Included Information</Label>
                    </FormGroup>
                  </Col>
                  <Col xs="10">
                    <FormGroup check>
                      <ValidatedInput register={register} name="bibliography" id="checkbox1" type="checkbox" />
                      <Label for="checkbox1" check>
                        <Translate contentKey="coconApp.searchQuery.export.bibliography" />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {effectNamesPerCategory
                .filter(({ effectsNamesOfCategory }) => effectsNamesOfCategory.length > 0)
                .map(({ effectCategory, effectsNamesOfCategory }, i) => (
                  <>
                    {i > 0 && <hr />}
                    <Row>
                      <Col xs="2">
                        <FormGroup>
                          <Label>
                            <Translate contentKey={`coconApp.EffectCategory.${effectCategory}`}></Translate>{' '}
                            <Translate contentKey={`coconApp.effect.home.title`}></Translate>
                          </Label>
                          <br />
                          Select{' '}
                          <ButtonGroup size="sm">
                            <Button
                              onClick={(localIndex => () => {
                                effectsNamesOfCategory.map((effectName, i) => {
                                  setValue(`effects[${localIndex + i}].id`, effectName.id);
                                });
                              })(index)}
                            >
                              All
                            </Button>
                            <Button
                              onClick={(index => () => {
                                effectsNamesOfCategory.map((_, i) => {
                                  setValue(`effects[${index + i}].id`, false);
                                });
                              })(index)}
                            >
                              None
                            </Button>
                          </ButtonGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="10">
                        {effectsNamesOfCategory.map(effectName => (
                          <FormGroup inline check key={effectName.id}>
                            <ValidatedInput
                              defaultChecked={true}
                              register={register}
                              value={effectName.id}
                              name={`effects[${index++}].id`}
                              id={`${effectCategory}-${effectName.id}`}
                              type="checkbox"
                            />
                            <Label style={{ fontWeight: 'inherit' }} for={`${effectCategory}-${effectName.id}`} check>
                              {effectName.name}
                            </Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>
                  </>
                ))}
            </CardBody>
          </Card>
          <Card className="material-properties">
            <CardHeader tag="h5">
              <Translate contentKey="coconApp.materialProperties.detail.title" />
            </CardHeader>
            <CardBody>
              <Row className="mb-3">
                <Col xs="2">
                  <FormGroup>
                    Select{' '}
                    <ButtonGroup size="sm">
                      <Button
                        onClick={() => {
                          ENM_PROPERTIES.map(property => {
                            setValue(property, true);
                          });
                        }}
                      >
                        All
                      </Button>
                      <Button
                        onClick={() => {
                          ENM_PROPERTIES.map(property => {
                            setValue(property, false);
                          });
                        }}
                      >
                        None
                      </Button>
                    </ButtonGroup>
                  </FormGroup>
                </Col>
                <Col xs="10">
                  {ENM_PROPERTIES.map(property => (
                    <FormGroup inline key={property} check>
                      <ValidatedInput register={register} name={property} id={property} type="checkbox" />
                      <Label for={property} check>
                        <Translate contentKey={`coconApp.materialProperties.${property}`} />
                      </Label>
                    </FormGroup>
                  ))}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="biological-model">
            <CardHeader tag="h5">
              <Translate contentKey="coconApp.biologicalModel.detail.title" />
            </CardHeader>
            <CardBody>
              <Row className="mb-3">
                <Col xs="2">
                  <FormGroup>
                    Select{' '}
                    <ButtonGroup size="sm">
                      <Button
                        onClick={() => {
                          BIOLOGICAL_MODEL_PROPERTIES.map(property => {
                            setValue(property, true);
                          });
                        }}
                      >
                        All
                      </Button>
                      <Button
                        onClick={() => {
                          BIOLOGICAL_MODEL_PROPERTIES.map(property => {
                            setValue(property, false);
                          });
                        }}
                      >
                        None
                      </Button>
                    </ButtonGroup>
                  </FormGroup>
                </Col>
                <Col xs="10">
                  {BIOLOGICAL_MODEL_PROPERTIES.map(property => (
                    <FormGroup inline key={property} check>
                      <ValidatedInput register={register} name={property} id={property} type="checkbox" />
                      <Label for={property} check>
                        <Translate contentKey={`coconApp.biologicalModel.${property}`} />
                      </Label>
                    </FormGroup>
                  ))}
                </Col>
              </Row>
            </CardBody>

            <CardFooter style={{ padding: '0.5rem 1rem' }}>
              <Row style={{ justifyContent: 'end' }}>
                <Col xs="auto" style={{ alignSelf: 'center' }}>
                  <Translate contentKey={`coconApp.searchQuery.export.format`} />
                </Col>
                <Col xs="auto" style={{ alignSelf: 'center' }}>
                  <TypeaheadField
                    id="format"
                    name="format"
                    control={control}
                    defaultSelected={['xlsx']}
                    labelKey={(value: string) => translate('coconApp.searchQuery.export.' + value)}
                    options={['xlsx', 'csv']}
                  />
                </Col>
                <Col xs="auto" style={{ alignSelf: 'center' }}>
                  <Button disabled={false} color="primary" type="submit" data-cy="submit">
                    {false && (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                      </>
                    )}
                    <Translate contentKey="coconApp.searchQuery.export.button"></Translate>
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default ExportSearchResults;
