import { EffectCategoryOverviewCard } from 'app/entities/dataset/components/effect-category-overview-card';
import { EffectNameValue } from 'app/entities/dataset/components/effect-name-value';
import { IEffectName } from 'app/shared/model/effect-name.model';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';
import { sortBy, map, groupBy } from 'lodash';
import React from 'react';
import { Translate } from 'react-jhipster';
import ScrollSpy from 'react-ui-scrollspy';
import {
  Row,
  Col,
  Card,
  CardHeader,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Button,
} from 'reactstrap';
import { ISearchResult } from '../search-query-update';
import { IEffect } from 'app/shared/model/effect.model';
import { Link } from 'react-router-dom';

type IEffectsSearchResultProps = {
  searchResult: ISearchResult;
  effectNamesPerCategory: {
    effectCategory: EffectCategory;
    effectsNamesOfCategory: IEffectName[];
    effectsOfCategory: IEffect[];
  }[];
};

const EffectsSearchResult = ({ searchResult, effectNamesPerCategory }: IEffectsSearchResultProps) => {
  return (
    <>
      <Row className="mb-3">
        <Col>
          <h3>Found {searchResult.numberOfResults} relevant dataset(s)</h3>
        </Col>
      </Row>
      <Row>
        <Col md="9">
          <ScrollSpy updateHistoryStack={false} activeClass="active" offsetBottom={-20} offsetTop={20} scrollThrottle={80}>
            {effectNamesPerCategory.map(({ effectCategory, effectsNamesOfCategory, effectsOfCategory }) => {
              return (
                effectsOfCategory?.length > 0 && (
                  <div key={effectCategory} className="dataset-table-detail-container" id={effectCategory}>
                    <Card style={{ marginBottom: '1rem' }}>
                      <CardHeader tag="h5" className={`${effectCategory}-effect-header`}>
                        <Translate contentKey={`coconApp.EffectCategory.${effectCategory}`}></Translate>{' '}
                        <Translate contentKey={`coconApp.effect.home.title`}></Translate>
                      </CardHeader>
                      <>
                        <UncontrolledAccordion flush stayOpen>
                          {effectsNamesOfCategory.map(effectName => {
                            const effectsByName = sortBy(
                              effectsOfCategory.filter(e => e.name.id === effectName.id),
                              e => e.value,
                            );

                            return (
                              <AccordionItem key={`${effectName.id}`}>
                                <AccordionHeader targetId={`${effectName.id}`}>{effectName.name}</AccordionHeader>
                                <AccordionBody accordionId={`${effectName.id}`}>
                                  {map(
                                    groupBy(effectsByName, e => e.dataset?.biologicalModel?.effectiveDoseUnit),
                                    es => (
                                      <Row className="mb-3" key={es[0].dataset?.id}>
                                        <span style={{ flex: 0, alignSelf: 'center' }}>
                                          {es[0].dataset?.biologicalModel?.effectiveDoseUnit}:
                                        </span>{' '}
                                        {es.map(e => (
                                          <>
                                            <EffectNameValue key={e.id} {...e} name={undefined} />
                                            <UncontrolledPopover placement="top" target={`effect-${e.id}`} trigger="hover">
                                              <PopoverHeader>
                                                {e.dataset?.publication?.authors} {e.dataset?.publication?.year}
                                              </PopoverHeader>
                                              <PopoverBody>
                                                <Button
                                                  color="primary"
                                                  tag="a"
                                                  target="_blank"
                                                  href={`https://doi.org/${e.dataset?.publication?.doi}`}
                                                >
                                                  Open DOI
                                                </Button>{' '}
                                                <Button tag={Link} to={`/dataset/${e.dataset?.id}`}>
                                                  Dataset Details
                                                </Button>
                                              </PopoverBody>
                                            </UncontrolledPopover>
                                          </>
                                        ))}
                                      </Row>
                                    ),
                                  )}
                                </AccordionBody>
                              </AccordionItem>
                            );
                          })}
                        </UncontrolledAccordion>
                      </>
                    </Card>

                    {false && (
                      <EffectCategoryOverviewCard
                        datasetId={0}
                        effectCategory={effectCategory}
                        editable={false}
                        effects={effectsOfCategory}
                      />
                    )}
                  </div>
                )
              );
            })}
          </ScrollSpy>
        </Col>
        <Col md="3">
          {searchResult.numberOfResults > 0 && (
            <Card style={{ position: 'sticky', top: '5rem' }}>
              <CardHeader tag="h5">Effect Categories</CardHeader>
              <CardBody>
                <Nav card pills vertical>
                  {Object.keys(EffectCategory).map((effectCategory: EffectCategory) => {
                    const effectsOfCategory = searchResult.effects?.filter(effect => effect.name.category === effectCategory);

                    return (
                      effectsOfCategory?.length > 0 && (
                        <NavItem key={effectCategory}>
                          <NavLink onClick={onPress} data-to-scrollspy-id={effectCategory} href={`#${effectCategory}`}>
                            <Translate contentKey={`coconApp.EffectCategory.${effectCategory}`}></Translate>
                          </NavLink>
                        </NavItem>
                      )
                    );
                  })}
                </Nav>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

const onPress = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  const target = window.document.getElementById(e.currentTarget.href.split('#')[1]);
  if (target) {
    const headerOffset = 80;
    const elementPosition = target.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

export default EffectsSearchResult;
