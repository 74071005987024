export enum Shape {
  ROUND = 'ROUND',

  PLATELET = 'PLATELET',

  FIBERS = 'FIBERS',

  WHISKERS = 'WHISKERS',

  RODS = 'RODS',

  NEEDLES = 'NEEDLES',

  TUBES = 'TUBES',

  NANOWIRES = 'NANOWIRES',

  SPINDLES = 'SPINDLES',

  NANOHORNS = 'NANOHORNS',

  HOLLOW_BUBBLES = 'HOLLOW_BUBBLES',

  IRREGULAR = 'IRREGULAR',

  RATTLES = 'RATTLES',
}

export const getShapeSearchDimension = (shape: Shape): 'dimension1' | 'dimension2' => {
  switch (shape) {
    case Shape.PLATELET:
    case Shape.FIBERS:
    case Shape.WHISKERS:
    case Shape.RODS:
    case Shape.NEEDLES:
    case Shape.TUBES:
    case Shape.NANOWIRES:
    case Shape.SPINDLES:
    case Shape.NANOHORNS:
      return 'dimension2';
    default:
      return 'dimension1';
  }
};

export const hasShapesThatRequireDimension2 = (shapes: Shape[]): boolean => {
  return shapes.some(shape => getShapeSearchDimension(shape) === 'dimension2');
};
