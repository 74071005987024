import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Button, Card, CardBody, CardHeader, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AttributeNameValue } from './attribute-name-value';
import { IPathwayOfToxicity } from 'app/shared/model/pathway-of-toxicity.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { TypeaheadField } from 'app/entities/material-properties/components/typeahead-field';
import { useForm, FieldValues } from 'react-hook-form';
import { partialUpdateEntity } from '../dataset.reducer';

const ModalAssociateAssay = ({ datasetId, isOpen, toggle, defaultSelected, allPathwayOfToxicities }) => {
  const dispatch = useAppDispatch();

  const updating = useAppSelector(state => state.dataset.updating);
  const updateSuccess = useAppSelector(state => state.dataset.updateSuccess);

  useEffect(() => {
    if (updateSuccess && isOpen) {
      toggle();
    }
  }, [updateSuccess]);

  const { handleSubmit, reset, control } = useForm<FieldValues>({ mode: 'onTouched' });

  // https://github.com/react-hook-form/react-hook-form/issues/1005
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // this part is for stopping parent forms to trigger their submit
    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        // prevent any outer forms from receiving the event too
        event.stopPropagation();
      }
    }

    return handleSubmit(async values => {
      const entity = {
        id: datasetId,
        pots: values.pots.map(({ id }) => ({ id })),
      };

      await dispatch(partialUpdateEntity(entity));
    })(event);
  };

  const defaultValues = {
    pots: defaultSelected?.map(pot => ({
      id: pot.id,
      value: pot.name,
      label: pot.name,
    })),
  };

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Translate contentKey={`coconApp.dataset.pot`}></Translate>
      </ModalHeader>
      <ModalBody>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Form id={`ModalAssociatePoT`} onSubmit={onSubmit}>
          <TypeaheadField
            label={`Select ${translate('coconApp.dataset.pot')}`}
            id={`dataset-pot`}
            name="pots"
            control={control}
            defaultSelected={defaultValues.pots}
            multiple
            options={allPathwayOfToxicities.map(pot => ({ id: pot.id, value: pot.name, label: pot.name }))}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button
          color="primary"
          id="save-entity"
          data-cy="entityCreateSaveButton"
          type="submit"
          form={`ModalAssociatePoT`}
          disabled={updating}
        >
          <FontAwesomeIcon icon="save" />
          &nbsp;
          <Translate contentKey="entity.action.save">Save</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export type IPathwaysOfToxicityCard = {
  datasetId: number;
  editingEnabled?: boolean;
  pathwayOfToxicities: IPathwayOfToxicity[];
  allPathwayOfToxicities?: readonly IPathwayOfToxicity[];
};

export const PathwaysOfToxicityCard = ({
  datasetId,
  editingEnabled = false,
  pathwayOfToxicities = [],
  allPathwayOfToxicities,
}: IPathwaysOfToxicityCard) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (!editingEnabled && pathwayOfToxicities?.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardHeader tag="h5" className="pots">
        <Translate contentKey="coconApp.dataset.pot"></Translate>
      </CardHeader>

      <CardBody tag={Row}>
        {pathwayOfToxicities?.length > 0 && (
          <AttributeNameValue>
            <span style={{ paddingTop: '0.5rem' }}>{pathwayOfToxicities?.map(pot => pot.name).join(', ')}</span>
          </AttributeNameValue>
        )}
        {allPathwayOfToxicities && (
          <Col>
            <ModalAssociateAssay
              datasetId={datasetId}
              isOpen={modalIsOpen}
              toggle={() => setModalIsOpen(!modalIsOpen)}
              defaultSelected={pathwayOfToxicities}
              allPathwayOfToxicities={allPathwayOfToxicities}
            />
            <Button block color="primary" disabled={!editingEnabled} onClick={() => setModalIsOpen(true)}>
              <FontAwesomeIcon icon="pencil-alt" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit"></Translate>
              </span>
            </Button>
          </Col>
        )}
      </CardBody>
    </Card>
  );
};
