import uniq from 'underscore/modules/uniq.js';
import { getShapeSearchDimension, Shape } from '../model/enumerations/shape.model';

export const getShapeTranslationKey = (
  shape:
    | 'ROUND'
    | 'PLATELET'
    | 'FIBERS'
    | 'WHISKERS'
    | 'RODS'
    | 'NEEDLES'
    | 'TUBES'
    | 'NANOWIRES'
    | 'SPINDLES'
    | 'NANOHORNS'
    | 'HOLLOW_BUBBLES'
    | 'IRREGULAR'
    | 'RATTLES'
    | undefined,
  dimension: 'dimension1' | 'dimension2' | 'dimension3',
): string => {
  // eslint-disable-next-line default-case
  switch (dimension) {
    case 'dimension1':
      if (shape === Shape.PLATELET) {
        return 'coconApp.materialProperties.thickness';
      } else if (shape === Shape.FIBERS) {
        return 'coconApp.materialProperties.diameter';
      }
      return 'coconApp.materialProperties.dimension1';
    case 'dimension2':
      if (shape === Shape.PLATELET) {
        return 'coconApp.materialProperties.lateralSize';
      }
      return 'coconApp.materialProperties.dimension2';
    case 'dimension3':
      return 'coconApp.materialProperties.dimension3';
  }
};

export const getShapeSearchKey = (shapes: Shape[]): string[] => {
  if (shapes.length === 0) {
    return ['coconApp.materialProperties.dimension1'];
  } else {
    return uniq(shapes.map(shape => getShapeTranslationKey(shape, getShapeSearchDimension(shape))));
  }
};
