/* eslint-disable no-console */
import React from 'react';
import { Col } from 'reactstrap';
import { translate, Translate } from 'react-jhipster';

export const AttributeNameValue = ({
  contentKey,
  label,
  value,
  values,
  unitKey,
  children,
}: {
  contentKey?: string;
  label?: string;
  value?: string | number | boolean;
  values?: string[];
  unitKey?: string;
  children?;
}) => {
  if (!children && !values && (value === undefined || value === null || value === '')) {
    return null;
  }

  if (values && values.length === 0) {
    return null;
  }

  return (
    <Col xs="auto">
      <span className="form-label">{contentKey ? translate(contentKey) : label}</span>
      {value ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {value} {unitKey && <>{translate(unitKey)}</>}
        </div>
      ) : values ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {values.join(', ')} {unitKey && <>{translate(unitKey)}</>}
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>{children}</div>
      )}
    </Col>
  );
};
