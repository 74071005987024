/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Alert, Badge, Button, Col, Row, Table } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { toast } from 'react-toastify';
import sortBy from 'underscore/modules/sortBy.js';
import uniq from 'underscore/modules/uniq.js';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { uploadDataImport, reset } from '../data-management.reducer';
import { ApprovalState } from 'app/shared/model/enumerations/approval-state.model';

export const DataImport = () => {
  const dispatch = useAppDispatch();
  const successMessage = useAppSelector(state => state.dataManagement.successMessage);
  const isLoading = useAppSelector(state => state.dataManagement.loading);
  const importMessages = useAppSelector(state => state.dataManagement.importMessages);
  const dataImport = useAppSelector(state => state.dataManagement.dataImport);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [],
  );

  const handleValidSubmit = (values: any) => {
    dispatch(uploadDataImport(values));
  };

  return (
    <div>
      <Row className="">
        <Col md="4">
          <h2 id="settings-title">
            <Translate contentKey="data-management.data-import.title"></Translate>
          </h2>
          <ValidatedForm id="data-import-form" onSubmit={handleValidSubmit}>
            <ValidatedField
              type="file"
              name="file"
              label={translate('data-management.data-import.form.fileToImport.label')}
              id="fileToImport"
              validate={{
                required: { value: true, message: translate('data-management.data-import.messages.validate.fileToImport.required') },
              }}
              data-cy="fileToImport"
            />
            <ValidatedField
              label={translate('coconApp.dataImport.approvalState')}
              id="dataset-approvalState"
              name="approvalState"
              type="select"
            >
              <option value={ApprovalState.IMPORTED}>
                {translate('coconApp.ApprovalState.' + ApprovalState.DRAFT)} (
                {translate('coconApp.ApprovalState.' + ApprovalState.IMPORTED)})
              </option>
              <option value={ApprovalState.CURATED}>{translate('coconApp.ApprovalState.' + ApprovalState.CURATED)}</option>
            </ValidatedField>
            <Button disabled={isLoading} color="primary" type="submit" data-cy="submit">
              {isLoading && (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                </>
              )}
              <Translate contentKey="data-management.data-import.form.button"></Translate>
            </Button>
          </ValidatedForm>
        </Col>
      </Row>

      {dataImport && (
        <>
          <br />
          <Alert color="success">Sucessfully imported {dataImport.datasets.length} datasets from the following publications:</Alert>
          <Table striped size="sm">
            <thead>
              <tr>
                <th>Authors</th>
                <th>Year</th>
                <th>DOI</th>
                <th>Number of Datasets</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(
                uniq(
                  dataImport.datasets.map(ds => ds.publication),
                  false,
                  p => p.id,
                ),
                p => p.id,
              ).map((publication, index) => (
                <tr key={index}>
                  <td>{publication.authors}</td>
                  <td>{publication.year}</td>
                  <td>{publication.doi}</td>
                  <td>{dataImport.datasets.filter(ds => ds.publication.id === publication.id).length}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {importMessages?.length > 0 && (
        <>
          <br />
          <Alert color="warning">No data was imported due to the following problems.</Alert>
          <Table striped size="sm">
            <thead>
              <tr>
                <th>Severity</th>
                <th>Row (Column)</th>
                <th>Value</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(importMessages, a => parseInt(a.rowNumber, 10)).map(({ type, message, columnName, rowNumber, value }, index) => (
                <tr key={index}>
                  <td>
                    <Badge color={type === 'Error' ? 'danger' : 'warning'} pill>
                      {type}
                    </Badge>
                  </td>
                  <td>
                    {rowNumber} ({columnName})
                  </td>
                  <td>{value}</td>
                  <td>{message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default DataImport;
