import { IDataset } from 'app/shared/model/dataset.model';
import { Shape } from 'app/shared/model/enumerations/shape.model';
import { getShapeTranslationKey } from 'app/shared/util/i18n-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { AttributeNameValue } from './attribute-name-value';

export const MaterialPropertiesColumns = ({ datasetEntity }: { datasetEntity: IDataset }) => {
  return (
    <>
      {datasetEntity.material?.engineeredNanoMaterial && (
        <AttributeNameValue
          contentKey="coconApp.materialProperties.enm"
          value={`${datasetEntity.material?.engineeredNanoMaterial?.molecularFormula} (${datasetEntity.material?.engineeredNanoMaterial?.trivialName})`}
        />
      )}

      {datasetEntity.material?.shape && (
        <AttributeNameValue
          contentKey="coconApp.materialProperties.shape"
          value={translate('coconApp.Shape.' + datasetEntity.material?.shape)}
        />
      )}
      <AttributeNameValue contentKey="coconApp.materialProperties.aspectRatio" value={datasetEntity.material?.aspectRatio} />
      <AttributeNameValue
        contentKey={getShapeTranslationKey(datasetEntity.material?.shape, 'dimension1')}
        value={datasetEntity.material?.dimension1}
        unitKey="coconApp.materialProperties.dimension1Unit"
      />
      <AttributeNameValue
        contentKey={getShapeTranslationKey(datasetEntity.material?.shape, 'dimension2')}
        value={datasetEntity.material?.dimension2}
        unitKey="coconApp.materialProperties.dimension2Unit"
      />
      <AttributeNameValue
        contentKey={getShapeTranslationKey(datasetEntity.material?.shape, 'dimension3')}
        value={datasetEntity.material?.dimension3}
      />
      <AttributeNameValue contentKey="coconApp.materialProperties.source" value={datasetEntity.material?.source?.name} />
      <AttributeNameValue contentKey="coconApp.materialProperties.variant" value={datasetEntity.material?.variant} />
      <AttributeNameValue
        contentKey="coconApp.materialProperties.synthesisReceiptIngredients"
        value={datasetEntity.material?.synthesisReceiptIngredients}
      />

      <AttributeNameValue contentKey="coconApp.materialProperties.synthesis" value={datasetEntity.material?.synthesis} />

      {datasetEntity.material?.testedConcentrations?.length > 0 && (
        <AttributeNameValue
          contentKey="coconApp.materialProperties.testedConcentration"
          value={datasetEntity.material.testedConcentrations.map(c => c.value).join(', ')}
          unitKey="coconApp.materialProperties.testedConcentrationUnit"
        />
      )}
      <AttributeNameValue
        contentKey="coconApp.materialProperties.maxCalculatedDepos"
        value={datasetEntity.material?.maxCalculatedDepos}
        unitKey="coconApp.materialProperties.maxCalculatedDeposUnit"
      />

      <AttributeNameValue
        label={`SC: ${translate('coconApp.materialProperties.surfaceChargeInWater')}`}
        value={datasetEntity.material?.surfaceChargeInWater}
        unitKey="coconApp.materialProperties.surfaceChargeInWaterUnit"
      />
      <AttributeNameValue
        label={`SC: ${translate('coconApp.materialProperties.surfaceChargeInMediumProtein')}`}
        value={datasetEntity.material?.surfaceChargeInMediumProtein}
        unitKey="coconApp.materialProperties.surfaceChargeInMediumProteinUnit"
      />
      <AttributeNameValue
        label={`SC: ${translate('coconApp.materialProperties.surfaceChargeInWaterSerumFree')}`}
        value={datasetEntity.material?.surfaceChargeInWaterSerumFree}
        unitKey="coconApp.materialProperties.surfaceChargeInWaterSerumFreeUnit"
      />

      <AttributeNameValue
        contentKey="coconApp.materialProperties.bet"
        value={datasetEntity.material?.bet}
        unitKey="coconApp.materialProperties.betUnit"
      />

      {datasetEntity.material?.surfaceReactivity && (
        <AttributeNameValue
          contentKey="coconApp.materialProperties.surfaceReactivity"
          value={`${datasetEntity.material?.surfaceReactivity} ${
            datasetEntity.material?.surfaceReactivityUnit ? datasetEntity.material.surfaceReactivityUnit : ''
          }`}
        />
      )}

      <AttributeNameValue contentKey="coconApp.materialProperties.crystallinity" value={datasetEntity.material?.crystallinity} />

      <AttributeNameValue
        contentKey="coconApp.materialProperties.solubility"
        value={datasetEntity.material?.solubility}
        unitKey="coconApp.materialProperties.solubilityUnit"
      />
      <AttributeNameValue
        label={`AS: ${translate('coconApp.materialProperties.agglomerationSizeMediumWithProtein')}`}
        value={datasetEntity.material?.agglomerationSizeMediumWithProtein}
        unitKey="coconApp.materialProperties.agglomerationSizeUnit"
      />
      <AttributeNameValue
        label={`AS: ${translate('coconApp.materialProperties.agglomerationSizeMediumWithoutProteinOrWater')}`}
        value={datasetEntity.material?.agglomerationSizeMediumWithoutProteinOrWater}
        unitKey="coconApp.materialProperties.agglomerationSizeUnit"
      />
      <AttributeNameValue
        label={`AS: ${translate('coconApp.materialProperties.agglomerationSizeMmadInAerosol')}`}
        value={datasetEntity.material?.agglomerationSizeMmadInAerosol}
        unitKey="coconApp.materialProperties.agglomerationSizeUnit"
      />

      <AttributeNameValue
        contentKey="coconApp.materialProperties.coatingOrFunctionalization"
        value={datasetEntity.material?.coatingOrFunctionalization}
      />

      {datasetEntity.material?.classification && (
        <AttributeNameValue
          contentKey="coconApp.materialProperties.classification"
          value={translate('coconApp.MaterialClassification.' + datasetEntity.material?.classification)}
        />
      )}
    </>
  );
};
