import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TechnicalApplication from './technical-application';
import TechnicalApplicationUpdate from './technical-application-update';
import TechnicalApplicationDeleteDialog from './technical-application-delete-dialog';

const TechnicalApplicationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TechnicalApplication />} />
    <Route path="new" element={<TechnicalApplicationUpdate />} />
    <Route path=":id">
      <Route path="edit" element={<TechnicalApplicationUpdate />} />
      <Route path="delete" element={<TechnicalApplicationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TechnicalApplicationRoutes;
