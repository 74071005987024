import { IDataset } from 'app/shared/model/dataset.model';
import React from 'react';
import { translate } from 'react-jhipster';
import { AttributeNameValue } from './attribute-name-value';

export const BiologicalModelColumns = ({ datasetEntity }: { datasetEntity: IDataset }) => {
  return (
    <>
      <AttributeNameValue contentKey="coconApp.biologicalModel.origins" values={datasetEntity.biologicalModel?.origins.map(e => e.name)} />

      <AttributeNameValue contentKey="coconApp.biologicalModel.organs" values={datasetEntity.biologicalModel?.organs.map(e => e.name)} />

      <AttributeNameValue contentKey="coconApp.biologicalModel.types" values={datasetEntity.biologicalModel?.types.map(e => e.name)} />

      {datasetEntity.biologicalModel?.culture && (
        <AttributeNameValue
          contentKey="coconApp.biologicalModel.culture"
          value={translate('coconApp.Culture.' + datasetEntity.biologicalModel?.culture)}
        />
      )}

      {datasetEntity.biologicalModel?.exposurePathway && (
        <AttributeNameValue
          contentKey="coconApp.biologicalModel.exposurePathway"
          value={translate('coconApp.ExposurePathway.' + datasetEntity.biologicalModel?.exposurePathway)}
        />
      )}

      <AttributeNameValue contentKey="coconApp.biologicalModel.application" value={datasetEntity.biologicalModel?.application?.name} />

      <AttributeNameValue
        contentKey="coconApp.biologicalModel.exposurePerDay"
        value={datasetEntity.biologicalModel?.exposurePerDay}
        unitKey="coconApp.biologicalModel.exposurePerDayUnit"
      />

      <AttributeNameValue
        contentKey="coconApp.biologicalModel.exposurePerWeek"
        value={datasetEntity.biologicalModel?.exposurePerWeek}
        unitKey="coconApp.biologicalModel.exposurePerWeekUnit"
      />

      <AttributeNameValue
        contentKey="coconApp.biologicalModel.totalRepeats"
        value={datasetEntity.biologicalModel?.totalRepeats}
        unitKey="coconApp.biologicalModel.totalRepeatsUnit"
      />

      <AttributeNameValue
        contentKey="coconApp.biologicalModel.postExposure"
        value={datasetEntity.biologicalModel?.postExposure}
        unitKey="coconApp.biologicalModel.postExposureUnit"
      />

      <AttributeNameValue
        contentKey="coconApp.biologicalModel.effectiveDoseUnit"
        value={datasetEntity.biologicalModel?.effectiveDoseUnit}
      />

      <AttributeNameValue
        contentKey="coconApp.biologicalModel.effectiveDoseLabel"
        value={datasetEntity.biologicalModel?.effectiveDoseLabel}
      />

      <AttributeNameValue
        contentKey="coconApp.biologicalModel.effectiveDoseValue"
        value={datasetEntity.biologicalModel?.effectiveDoseValue}
      />

      <AttributeNameValue
        contentKey="coconApp.biologicalModel.storageSites"
        values={datasetEntity.biologicalModel?.storageSites.map(e => e.name)}
      />

      <AttributeNameValue contentKey="coconApp.biologicalModel.cotreatment" value={datasetEntity.biologicalModel?.cotreatment} />

      <AttributeNameValue contentKey="coconApp.biologicalModel.guidelines" value={datasetEntity.biologicalModel?.guidelines} />
    </>
  );
};
