import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Origin from './origin';
import OriginDetail from './origin-detail';
import OriginUpdate from './origin-update';
import OriginDeleteDialog from './origin-delete-dialog';

const OriginRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Origin />} />
    <Route path="new" element={<OriginUpdate />} />
    <Route path=":id">
      <Route index element={<OriginDetail />} />
      <Route path="edit" element={<OriginUpdate />} />
      <Route path="delete" element={<OriginDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OriginRoutes;
