import { IDataset } from 'app/shared/model/dataset.model';
import { ISearchQuery } from 'app/shared/model/search-query.model';

export interface ICellCulture {
  id?: number;
  name?: string;
  datasets?: IDataset[] | null;
  searchQueries?: ISearchQuery[] | null;
}

export const defaultValue: Readonly<ICellCulture> = {};
