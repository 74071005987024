import { ITissueType } from 'app/shared/model/tissue-type.model';
import { IOrgan } from 'app/shared/model/organ.model';
import { IOrigin } from 'app/shared/model/origin.model';
import { IStorageSite } from 'app/shared/model/storage-site.model';
import { IApplication } from 'app/shared/model/application.model';
import { IDataset } from 'app/shared/model/dataset.model';
import { AnimalOrCell } from 'app/shared/model/enumerations/animal-or-cell.model';
import { Culture } from 'app/shared/model/enumerations/culture.model';
import { ExposurePathway } from 'app/shared/model/enumerations/exposure-pathway.model';

export interface IBiologicalModel {
  id?: number;
  animalOrCell?: keyof typeof AnimalOrCell | null;
  culture?: keyof typeof Culture | null;
  exposurePerDay?: string | null;
  exposurePerWeek?: string | null;
  totalRepeats?: string | null;
  postExposure?: number | null;
  effectiveDoseLabel?: string | null;
  effectiveDoseValue?: string | null;
  effectiveDoseUnit?: string | null;
  cotreatment?: string | null;
  guidelines?: string | null;
  exposurePathway?: keyof typeof ExposurePathway | null;
  types?: ITissueType[] | null;
  organs?: IOrgan[] | null;
  origins?: IOrigin[] | null;
  storageSites?: IStorageSite[] | null;
  application?: IApplication | null;
  dataset?: IDataset | null;
}

export const defaultValue: Readonly<IBiologicalModel> = {};
