/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import axios from 'axios';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, InputGroup, Label, Row } from 'reactstrap';
import { Translate, ValidatedInput, translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FieldValues, useForm } from 'react-hook-form';
import { hasShapesThatRequireDimension2, Shape } from 'app/shared/model/enumerations/shape.model';
import { createEntity } from 'app/entities/search-query/search-query.reducer';
import { useState } from 'react';
import { IEffect } from 'app/shared/model/effect.model';
import { AnimalOrCell } from 'app/shared/model/enumerations/animal-or-cell.model';
import { TypeaheadField } from 'app/entities/material-properties/components/typeahead-field';
import { IAdvancedMaterial } from 'app/shared/model/advanced-material.model';
import { ITissueType } from 'app/shared/model/tissue-type.model';
import { RangeInput } from 'app/entities/material-properties/components/range-input';
import { ISearchQuery } from 'app/shared/model/search-query.model';
import { IApplication } from 'app/shared/model/application.model';
import { ICellCulture } from 'app/shared/model/cell-culture.model';
import { Highlighter } from 'react-bootstrap-typeahead';
import EffectsSearchResult from './components/effects-search-result';
import ExportSearchResults from './components/export-search-results';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';
import { IEffectName } from 'app/shared/model/effect-name.model';
import uniq from 'underscore/modules/uniq.js';
import { QualityScore } from 'app/shared/model/enumerations/quality-score.model';
import { getShapeSearchKey } from 'app/shared/util/i18n-utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getEntity, reset as resetEntity } from './search-query.reducer';
import { IOrigin } from 'app/shared/model/origin.model';
import { IOrgan } from 'app/shared/model/organ.model';
import { hasAdminAuthority } from 'app/shared/auth/private-route';

export type ISearchResult = {
  numberOfResults: number;
  effects: Array<IEffect>;
};

export const SearchQueryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const pageLocation = useLocation();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const loading = useAppSelector(state => state.searchQuery.loading);

  const [searchResult, setSearchResult] = useState<ISearchResult>();
  const [effectNamesPerCategory, setEffectNamesPerCategory] = useState<any>([]);

  const [searchOptions, setSearchOptions] = useState<any>({ options: {}, numberOfDatasetsPerOption: {} });

  const animalOrCellValues = Object.keys(AnimalOrCell);
  const qualityScoreValues = Object.keys(QualityScore);

  const isAdmin = useAppSelector(hasAdminAuthority);

  function prepareValuesForSubmission(values): ISearchQuery {
    // console.log('Current form values to submit', values);

    // Always save as new search query
    delete values.id;

    if (values.animalOrCell === 'ANIMAL') {
      delete values.cellCultures;
    } else if (values.animalOrCell === 'CELL') {
      delete values.origins;
    }

    if (values.materials === '') {
      delete values.materials;
    }

    if (values.materialSize) {
      values.materialSizeFrom = values.materialSize[0];
      values.materialSizeTo = values.materialSize[1];
    }
    delete values.materialSize;

    if (values.materialLength) {
      values.materialLengthFrom = values.materialLength[0];
      values.materialLengthTo = values.materialLength[1];
    }
    delete values.materialLength;

    if (values.agglomerationSize) {
      values.agglomerationSizeFrom = values.agglomerationSize[0];
      values.agglomerationSizeTo = values.agglomerationSize[1];
    }
    delete values.agglomerationSize;

    if (values.bet) {
      values.betFrom = values.bet[0];
      values.betTo = values.bet[1];
    }
    delete values.bet;

    // The API expects a set of organs, but we only give users a single
    // selection, so we need to convert it to an array here:
    if (values.organs) {
      values.organs = [values.organs];
    }

    return values;
  }

  function prepareValuesForForm({
    materialSizeFrom,
    materialSizeTo,
    materialLengthFrom,
    materialLengthTo,
    agglomerationSizeFrom,
    agglomerationSizeTo,
    betFrom,
    betTo,
    organs,
    ...values
  }: ISearchQuery) {
    const result: any = {
      ...values,
    };

    if (materialSizeFrom !== undefined && materialSizeTo !== undefined) {
      result.materialSize = [materialSizeFrom, materialSizeTo];
    }

    if (materialLengthFrom !== undefined && materialLengthTo !== undefined) {
      result.materialLength = [materialLengthFrom, materialLengthTo];
    }

    if (agglomerationSizeFrom !== undefined && agglomerationSizeTo !== undefined) {
      result.agglomerationSize = [agglomerationSizeFrom, agglomerationSizeTo];
    }

    if (betFrom !== undefined && betTo !== undefined) {
      result.bet = [betFrom, betTo];
    }

    // unwrap, see comment in prepareValuesForSubmission
    if (organs) {
      result.organs = organs[0];
    }

    return result;
  }

  const resetForm = () => {
    // Not sure why, but twice seems to work?
    reset({});
    reset({});
    setSearchResult(undefined);
  };

  const {
    handleSubmit,
    reset,
    control,
    register,
    watch,
    formState: { isDirty },
  } = useForm<FieldValues>({
    mode: 'onBlur',
    async defaultValues() {
      // console.log('Requesting default values for useForm');
      if (isNew) {
        dispatch(resetEntity());
        return Promise.resolve({});
      } else {
        const { data } = await dispatch(getEntity(id)).unwrap();
        return prepareValuesForForm(data);
      }
    },
  });

  const submitQuery = values => {
    const handleSearchResult =
      (navigateToId: number) =>
      ({ data }: { data: any }): void => {
        // console.log('handleSearchResult', data);

        const effects = Object.keys(EffectCategory).map((effectCategory: EffectCategory) => {
          const effectsOfCategory = data.effects?.filter(effect => effect.name.category === effectCategory);

          const effectsNamesOfCategory: IEffectName[] = uniq(
            effectsOfCategory.map(e => e.name),
            false,
            n => n.name,
          ).sort();
          return { effectCategory, effectsOfCategory, effectsNamesOfCategory };
        });

        setSearchResult(data);
        setEffectNamesPerCategory(effects);

        if (navigateToId) {
          navigate('/search-query/' + navigateToId + '/edit' + pageLocation.search, {
            state: { searchResults: data, effectNamesPerCategory: effects },
          });
        }
      };

    if (isDirty) {
      dispatch(createEntity(prepareValuesForSubmission(values)))
        .unwrap()
        .then(({ data }) => {
          // to clear the isDirty flag of the form:
          // console.log('Resetting form values', data);

          reset(prepareValuesForForm(data));

          return axios.post(`api/search-queries/${data.id}/perform`).then(handleSearchResult(data.id));
        });
    } else {
      axios.post(`api/search-queries/${id}/perform`).then(handleSearchResult(undefined));
    }
  };

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      // Clear all now invalid search results

      // console.log('Form values changed, requesting search options');
      const requestUrl = `api/search-queries/options`;
      axios.post(requestUrl, prepareValuesForSubmission(values)).then(({ data }) => {
        const options = {};
        const numberOfDatasetsPerOption = {};

        Object.keys(data).forEach(key => {
          options[key] = data[key].map(o => o.value);
          numberOfDatasetsPerOption[key] = {};

          data[key].forEach(entry => {
            const optionKey = entry.value.id || entry.value;
            numberOfDatasetsPerOption[key][`${optionKey}`] = entry.numberOfDatasets;
          });
        });

        // console.log(options, numberOfDatasetsPerOption);

        setSearchOptions({ options, numberOfDatasetsPerOption });
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // console.log('pageLocation state', pageLocation.state);
    // @ts-ignore
    if (pageLocation.state?.searchResults) {
      // @ts-ignore
      setSearchResult(pageLocation.state.searchResults);
      // @ts-ignore
      setEffectNamesPerCategory(pageLocation.state.effectNamesPerCategory);
    }
  }, []);

  useEffect(() => {
    if (isDirty) {
      setSearchResult(undefined);
      setEffectNamesPerCategory(undefined);
    }
  }, [isDirty]);

  // console.log('id', id);
  // console.log('is dirty? ', isDirty);
  // console.log('search results', searchResult);

  return (
    <div>
      <h2 id="settings-title">
        <Translate contentKey="query.query-composer.title"></Translate>
      </h2>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Form onSubmit={handleSubmit(submitQuery)}>
        <Card className="material-properties">
          <CardHeader tag="h5">
            <Translate contentKey="coconApp.materialProperties.detail.title" />
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="2">
                <TypeaheadField
                  id="materials"
                  name="materials"
                  control={control}
                  label={translate('query.query-composer.form.materials.label')}
                  multiple
                  placeholder="All"
                  labelKey={(c: IAdvancedMaterial) =>
                    c.trivialName ? `${c.molecularFormula} (${c.trivialName})` : `${c.molecularFormula}`
                  }
                  renderMenuItemChildren={(c: IAdvancedMaterial, { text }) => (
                    <>
                      <Highlighter search={text}>
                        {c.trivialName ? `${c.molecularFormula} (${c.trivialName})` : `${c.molecularFormula}`}
                      </Highlighter>
                      <div style={{ float: 'right' }}>
                        <small>{searchOptions.numberOfDatasetsPerOption.materials[c.id].toLocaleString()}&nbsp;Datasets</small>
                      </div>
                    </>
                  )}
                  options={searchOptions.options.materials || []}
                />
              </Col>
              <Col md="2">
                <TypeaheadField
                  id="material-properties-variants"
                  name="variant"
                  control={control}
                  placeholder="All"
                  label={translate('coconApp.materialProperties.variant')}
                  multiple
                  renderMenuItemChildren={(value: string, { text }) => (
                    <>
                      <Highlighter search={text}>{value}</Highlighter>
                      <div style={{ float: 'right' }}>
                        <small>{searchOptions.numberOfDatasetsPerOption.variants[value].toLocaleString()}&nbsp;Datasets</small>
                      </div>
                    </>
                  )}
                  options={searchOptions.options.variants || []}
                />
              </Col>
              <Col md="2">
                <TypeaheadField
                  id="material-properties-synthesis"
                  name="synthesis"
                  control={control}
                  placeholder="All"
                  label={translate('coconApp.materialProperties.synthesis')}
                  multiple
                  renderMenuItemChildren={(value: string, { text }) => (
                    <>
                      <Highlighter search={text}>{value}</Highlighter>
                      <div style={{ float: 'right' }}>
                        <small>{searchOptions.numberOfDatasetsPerOption.synthesis[value].toLocaleString()}&nbsp;Datasets</small>
                      </div>
                    </>
                  )}
                  options={searchOptions.options.synthesis || []}
                />
              </Col>
              <Col md="2">
                <TypeaheadField
                  id="materialShape"
                  name="materialShape"
                  rules={{
                    validate: values =>
                      !values || values.length < 1 ? 'Please select a ' + translate('coconApp.materialProperties.shape') : undefined,
                  }}
                  control={control}
                  label={translate('coconApp.materialProperties.shape')}
                  multiple
                  placeholder="Select one or more shapes"
                  labelKey={(shape: string) => translate('coconApp.Shape.' + shape)}
                  renderMenuItemChildren={(value: string, { text }) => (
                    <>
                      <Highlighter search={text}>{translate('coconApp.Shape.' + value)}</Highlighter>
                      <div style={{ float: 'right' }}>
                        <small>{searchOptions.numberOfDatasetsPerOption.shapes[value].toLocaleString()}&nbsp;Datasets</small>
                      </div>
                    </>
                  )}
                  options={searchOptions.options.shapes || []}
                />
              </Col>
              {(watch('materialShape') || watch('crystallinity')) && (
                <Col md="2">
                  <TypeaheadField
                    id="material-properties-crystallinity"
                    name="crystallinity"
                    control={control}
                    placeholder="All"
                    label={translate('coconApp.materialProperties.crystallinity')}
                    multiple
                    renderMenuItemChildren={(value: string, { text }) => (
                      <>
                        <Highlighter search={text}>{value}</Highlighter>
                        <div style={{ float: 'right' }}>
                          <small>{searchOptions.numberOfDatasetsPerOption.crystallinity[value].toLocaleString()}&nbsp;Datasets</small>
                        </div>
                      </>
                    )}
                    options={searchOptions.options.crystallinity || []}
                  />
                </Col>
              )}
              {(watch('materialShape') || watch('coatingOrFunctionalization')) && (
                <Col md="2">
                  <TypeaheadField
                    id="material-properties-coatingOrFunctionalization"
                    name="coatingOrFunctionalization"
                    control={control}
                    placeholder="All"
                    label={translate('coconApp.materialProperties.coatingOrFunctionalization')}
                    multiple
                    renderMenuItemChildren={(value: string, { text }) => (
                      <>
                        <Highlighter search={text}>{value}</Highlighter>
                        <div style={{ float: 'right' }}>
                          <small>
                            {searchOptions.numberOfDatasetsPerOption.coatingOrFunctionalization[value].toLocaleString()}&nbsp;Datasets
                          </small>
                        </div>
                      </>
                    )}
                    options={searchOptions.options.coatingOrFunctionalization || []}
                  />
                </Col>
              )}
            </Row>
            {watch('materialShape') && (
              <Row>
                <Col md="4">
                  <RangeInput
                    id="search-query-materialSize"
                    name="materialSize"
                    maxval={10000}
                    control={control}
                    label={`${translate('coconApp.materialProperties.dimension1')} [${translate(
                      'coconApp.materialProperties.dimension1Unit',
                    )}]`}
                    tooltip="coconApp.searchQuery.tooltips.materialSize"
                  />
                </Col>
                {hasShapesThatRequireDimension2(watch('materialShape')) && (
                  <Col md="3">
                    <RangeInput
                      id="search-query-materialLength"
                      name="materialLength"
                      maxval={20000}
                      control={control}
                      label={`${translate('coconApp.searchQuery.materialLength')} [${translate(
                        'coconApp.materialProperties.dimension1Unit',
                      )}]`}
                      tooltip="coconApp.searchQuery.tooltips.materialLength"
                    />
                  </Col>
                )}
                <Col md="3">
                  <RangeInput
                    id="search-query-agglomerationSize"
                    name="agglomerationSize"
                    maxval={10000}
                    control={control}
                    label={`${translate('coconApp.materialProperties.agglomerationSize')} [${translate(
                      'coconApp.materialProperties.agglomerationSizeUnit',
                    )}]`}
                  />
                </Col>
                <Col md="3">
                  <RangeInput
                    id="search-query-bet"
                    name="bet"
                    maxval={2000}
                    control={control}
                    label={`${translate('coconApp.searchQuery.bet')} [${translate('coconApp.materialProperties.betUnit')}]`}
                    tooltip="coconApp.searchQuery.tooltips.bet"
                  />
                </Col>
                {(watch('materialShape') || watch('qualityScore')) && (
                  <Col md="2">
                    <TypeaheadField
                      id="qualityScore"
                      name="qualityScore"
                      control={control}
                      placeholder="All"
                      multiple
                      label={translate('coconApp.searchQuery.qualityScore')}
                      labelKey={(qualityScore: string) => translate('coconApp.QualityScore.' + qualityScore)}
                      tooltip="coconApp.searchQuery.tooltips.qualityScore"
                      options={qualityScoreValues}
                    />
                  </Col>
                )}
              </Row>
            )}
          </CardBody>
        </Card>
        <Card className="biological-model">
          <CardHeader tag="h5">
            <Translate contentKey="coconApp.biologicalModel.detail.title" />
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="2">
                <TypeaheadField
                  id="animalOrCell"
                  name="animalOrCell"
                  control={control}
                  rules={{
                    validate: values =>
                      !values || values.length < 1 ? 'Please select the ' + translate('coconApp.searchQuery.animalOrCell') : undefined,
                  }}
                  label={translate('coconApp.searchQuery.animalOrCell')}
                  placeholder="Select a Type of Study"
                  labelKey={(animalOrCell: string) => translate('coconApp.InVivoOrInVitro.' + animalOrCell)}
                  options={animalOrCellValues}
                />
              </Col>
              {watch('animalOrCell') === 'ANIMAL' && (
                <Col md="3">
                  <TypeaheadField
                    id="biological-model-origins"
                    name="origins"
                    control={control}
                    placeholder="All"
                    label={translate('coconApp.searchQuery.origins')}
                    multiple
                    labelKey={(option: IOrigin) => option.name}
                    renderMenuItemChildren={(option: IOrigin, { text }) => (
                      <>
                        <Highlighter search={text}>{option.name}</Highlighter>
                        <div style={{ float: 'right' }}>
                          <small>{searchOptions.numberOfDatasetsPerOption.origins[option.id].toLocaleString()}&nbsp;Datasets</small>
                        </div>
                      </>
                    )}
                    options={searchOptions.options.origins || []}
                  />
                </Col>
              )}
              {watch('animalOrCell') === 'CELL' && (
                <Col md="3">
                  <TypeaheadField
                    key={watch('animalOrCell')}
                    id="biological-model-cellCultures"
                    name="cellCultures"
                    control={control}
                    placeholder="All"
                    label={translate('coconApp.searchQuery.cellCultures')}
                    multiple
                    labelKey={(option: ICellCulture) => `${option.name}`}
                    renderMenuItemChildren={(option: ICellCulture, { text }) => (
                      <>
                        <Highlighter search={text}>{option.name}</Highlighter>
                        <div style={{ float: 'right' }}>
                          <small>{searchOptions.numberOfDatasetsPerOption.cellCultures[option.id].toLocaleString()}&nbsp;Datasets</small>
                        </div>
                      </>
                    )}
                    options={searchOptions.options.cellCultures || []}
                  />
                </Col>
              )}
              {watch('animalOrCell') && (
                <Col md="3">
                  <TypeaheadField
                    id="organ"
                    name="organs"
                    control={control}
                    placeholder="All"
                    label={translate('coconApp.searchQuery.organ')}
                    labelKey={(option: IOrgan) => option.name}
                    renderMenuItemChildren={(option: IOrgan, { text }) => (
                      <>
                        <Highlighter search={text}>{option.name}</Highlighter>
                        <div style={{ float: 'right' }}>
                          <small>{searchOptions.numberOfDatasetsPerOption.organs[option.id].toLocaleString()}&nbsp;Datasets</small>
                        </div>
                      </>
                    )}
                    options={searchOptions.options.organs || []}
                  />
                </Col>
              )}
              {watch('animalOrCell') && (
                <Col md="3">
                  <TypeaheadField
                    id="tissueType"
                    name="tissueTypes"
                    control={control}
                    placeholder="All"
                    label={translate('coconApp.searchQuery.tissueType')}
                    multiple
                    labelKey={(option: ITissueType) => option.name}
                    renderMenuItemChildren={(option: ITissueType, { text }) => (
                      <>
                        <Highlighter search={text}>{option.name}</Highlighter>
                        <div style={{ float: 'right' }}>
                          <small>{searchOptions.numberOfDatasetsPerOption.tissueTypes[option.id].toLocaleString()}&nbsp;Datasets</small>
                        </div>
                      </>
                    )}
                    options={searchOptions.options.tissueTypes || []}
                  />
                </Col>
              )}
            </Row>
            <Row>
              {watch('animalOrCell') && (
                <Col md="3">
                  <TypeaheadField
                    id="biological-model-exposurePathway"
                    name="exposurePathway"
                    control={control}
                    placeholder="All"
                    label={translate('coconApp.searchQuery.exposurePathway')}
                    multiple
                    labelKey={(exposurePathway: string) => translate('coconApp.ExposurePathway.' + exposurePathway)}
                    renderMenuItemChildren={(value: string, { text }) => (
                      <>
                        <Highlighter search={text}>{translate('coconApp.ExposurePathway.' + value)}</Highlighter>
                        <div style={{ float: 'right' }}>
                          <small>{searchOptions.numberOfDatasetsPerOption.exposurePathways[value].toLocaleString()}&nbsp;Datasets</small>
                        </div>
                      </>
                    )}
                    options={searchOptions.options.exposurePathways || []}
                  />
                </Col>
              )}
              {watch('animalOrCell') && (
                <Col md="3">
                  <TypeaheadField
                    id="biological-model-application"
                    name="applications"
                    control={control}
                    placeholder="All"
                    label={translate('coconApp.searchQuery.applications')}
                    multiple
                    labelKey={(option: IApplication) => option.name}
                    renderMenuItemChildren={(option: IApplication, { text }) => (
                      <>
                        <Highlighter search={text}>{option.name}</Highlighter>
                        <div style={{ float: 'right' }}>
                          <small>{searchOptions.numberOfDatasetsPerOption.applications[option.id].toLocaleString()}&nbsp;Datasets</small>
                        </div>
                      </>
                    )}
                    options={searchOptions.options.applications || []}
                  />
                </Col>
              )}
              {watch('animalOrCell') && (
                <Col md="6">
                  <FormGroup>
                    <Label>Guidelines</Label>
                    <div>
                      <ValidatedInput
                        defaultChecked={false}
                        register={register}
                        name="onlyGuidelineExperiments"
                        id="onlyGuidelineExperiments"
                        type="checkbox"
                      />{' '}
                      <Label for="onlyGuidelineExperiments" check>
                        Search guideline experiments only
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Row>
          </CardBody>
          <CardFooter style={{ padding: '0.5rem 1rem' }}>
            <Row>
              <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
                <Button disabled={loading} color="secondary" onClick={resetForm}>
                  <Translate contentKey="query.query-composer.form.reset"></Translate>
                </Button>{' '}
                <Button disabled={loading} color="primary" type="submit" data-cy="submit">
                  {loading && (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                    </>
                  )}
                  <Translate contentKey="query.query-composer.form.button"></Translate>
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
        <br />
      </Form>
      {searchResult && !isDirty && (
        <>
          <EffectsSearchResult searchResult={searchResult} effectNamesPerCategory={effectNamesPerCategory} />
          {isAdmin && searchResult.numberOfResults !== 0 && (
            <ExportSearchResults searchQueryId={id} effectNamesPerCategory={effectNamesPerCategory} />
          )}
          <br />
          <Row>
            <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
              <Button disabled={loading} color="secondary" onClick={resetForm}>
                <Translate contentKey="query.query-composer.form.start-over"></Translate>
              </Button>
            </Col>
          </Row>
        </>
      )}
      <br />
    </div>
  );
};

export default SearchQueryUpdate;
