/* eslint-disable no-console */
import React from 'react';
import { Translate } from 'react-jhipster';
import { IDataset } from 'app/shared/model/dataset.model';
import { maxBy } from 'lodash';
import { ApprovalState } from 'app/shared/model/enumerations/approval-state.model';

export function ReviewState({ dataset }: { dataset?: IDataset; state?: ApprovalState }) {
  const latest = getReviewState(dataset);
  return <Translate key={latest} contentKey={`coconApp.ApprovalState.${latest}`} />;
}

export function getReviewState(dataset: IDataset) {
  if (!dataset.approvalHistories || dataset.approvalHistories.length === 0) {
    return ApprovalState.DRAFT;
  }
  return maxBy(dataset.approvalHistories, ah => ah.timestamp).approvalState;
}
