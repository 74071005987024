import { IDataset } from 'app/shared/model/dataset.model';

export interface IDataImport {
  id?: number;
  timestamp?: string | null;
  file?: string;
  datasets?: IDataset[] | null;
}

export const defaultValue: Readonly<IDataImport> = {};
