/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Spinner,
  Form,
  CardBody,
  Card,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem,
  CardHeader,
  FormGroup,
  Label,
} from 'reactstrap';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, reset as resetEntity, getEntities } from './biological-model.reducer';
import { createEntity as createTissueTypeEntity, getEntities as getTissueTypes } from 'app/entities/tissue-type/tissue-type.reducer';

import { createEntity as createOriginEntity, getEntities as getOrigins } from 'app/entities/origin/origin.reducer';

import { createEntity as createOrganEntity, getEntities as getOrgans } from 'app/entities/organ/organ.reducer';
import { createEntity as createApplicationEntity, getEntities as getApplications } from 'app/entities/application/application.reducer';

import { partialUpdateEntity as partialUpdateDatasetEntity } from 'app/entities/dataset/dataset.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { AnimalOrCell } from 'app/shared/model/enumerations/animal-or-cell.model';
import { Culture } from 'app/shared/model/enumerations/culture.model';
import { ExposurePathway } from 'app/shared/model/enumerations/exposure-pathway.model';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { sortBy, sortedUniq, without } from 'lodash';
import { TypeaheadField } from '../material-properties/components/typeahead-field';
import { ValidatedFieldInputGroup } from 'app/shared/validated-field-input-group';
import { ModalCreateEntity } from 'app/shared/components/modal-create-entity';

import { getEntities as getStorageSites } from 'app/entities/storage-site/storage-site.reducer';

export const BiologicalModelUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const pageLocation = useLocation();

  const { id: datasetId } = useParams<'id'>();
  const { biologicalModelId: id } = useParams<'biologicalModelId'>();
  const isNew = id === undefined;

  // We start with undefined and use to track it if we have dispatched all
  // loading of dependencies, so we can set the default values once we
  // have these all.
  const [loadingOfDependenciesStarted, setLoadingOfDependenciesStarted] = useState<boolean>(undefined);

  const biologicalModelEntity = useAppSelector(state => state.biologicalModel.entity);

  const tissueTypes = useAppSelector(state => state.tissueType.entities);
  const origins = useAppSelector(state => state.origin.entities);
  const organs = useAppSelector(state => state.organ.entities);
  const applications = useAppSelector(state => state.application.entities);
  const storageSites = useAppSelector(state => state.storageSite.entities);

  const effectiveDoseLabelValues = useAppSelector(state =>
    without(sortedUniq(sortBy(state.biologicalModel.entities.map(materialProperties => materialProperties.effectiveDoseLabel))), null, ''),
  );

  const effectiveDoseUnitValues = useAppSelector(state =>
    without(sortedUniq(sortBy(state.biologicalModel.entities.map(materialProperties => materialProperties.effectiveDoseUnit))), null, ''),
  );

  const cotreatmentValues = useAppSelector(state =>
    without(sortedUniq(sortBy(state.biologicalModel.entities.map(materialProperties => materialProperties.cotreatment))), null, ''),
  );

  const guidelinesValues = useAppSelector(state =>
    without(sortedUniq(sortBy(state.biologicalModel.entities.map(materialProperties => materialProperties.guidelines))), null, ''),
  );

  const dependenciesAreStillLoading = useAppSelector(
    state =>
      state.biologicalModel.loadingEntity ||
      state.biologicalModel.loadingEntities ||
      state.tissueType.loading ||
      state.origin.loading ||
      state.organ.loading ||
      state.application.loading ||
      state.storageSite.loading,
  );

  const updating = useAppSelector(state => state.biologicalModel.updating);
  const animalOrCellValues = Object.keys(AnimalOrCell);
  const cultureValues = Object.keys(Culture);
  const exposurePathwayValues = Object.keys(ExposurePathway);

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }
    // needed for typehead
    dispatch(getEntities({}));
    dispatch(getOrigins({}));
    dispatch(getOrgans({}));
    dispatch(getTissueTypes({}));
    dispatch(getApplications({}));
    dispatch(getStorageSites({}));
    setLoadingOfDependenciesStarted(true);
  }, []);

  const saveEntity = values => {
    const entity = {
      ...biologicalModelEntity,
      ...values,
    };

    if (entity.exposurePathway === '') {
      delete entity.exposurePathway;
    }

    if (isNew) {
      // Instead of creating just a new entity, we patch the dataset to get
      // the one-to-one association updated as well. We then re-fetch the
      // material properties so that the redux store is updated. Finally,
      // we replace the browser history.

      dispatch(
        partialUpdateDatasetEntity({
          id: datasetId,
          biologicalModel: entity,
        }),
      )
        .unwrap()
        .then(({ data: { biologicalModel } }) =>
          dispatch(getEntity(biologicalModel.id))
            .unwrap()
            .then(() =>
              navigate(`/dataset/${datasetId}/biological-model/${biologicalModel.id}/edit${pageLocation.search}`, { replace: true }),
            ),
        );
    } else {
      dispatch(
        partialUpdateDatasetEntity({
          id: datasetId,
          biologicalModel: entity,
        }),
      );
      dispatch(getEntity(id));
    }
  };

  const defaultValues = isNew
    ? {}
    : {
        animalOrCell: 'ANIMAL',
        culture: 'MONOLAYER',
        ...biologicalModelEntity,
      };

  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    formState: { errors, touchedFields, dirtyFields },
  } = useForm<FieldValues>({ mode: 'onTouched', defaultValues });

  useEffect(() => {
    if (loadingOfDependenciesStarted && !dependenciesAreStillLoading) {
      console.log('All dependencies finished loading! Setting default form values now.');
      setLoadingOfDependenciesStarted(false);
      reset(defaultValues);
    } else if (loadingOfDependenciesStarted === false && dependenciesAreStillLoading) {
      console.log(
        'A dependency has changed! Resetting the form with the current (possibly unsaved) values, so that new options are picked up.',
      );
      reset(getValues());
    }
  }, [loadingOfDependenciesStarted, dependenciesAreStillLoading]);

  if (loadingOfDependenciesStarted === undefined || (loadingOfDependenciesStarted && dependenciesAreStillLoading)) {
    return (
      <Row className="justify-content-center">
        <Spinner type="grow">Loading...</Spinner>
      </Row>
    );
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem active tag={Link} to={`/dataset${pageLocation.search}`}>
          Datasets
        </BreadcrumbItem>
        <BreadcrumbItem active tag={Link} to={`/dataset/${datasetId}/edit${pageLocation.search}`}>
          {datasetId}
        </BreadcrumbItem>
      </Breadcrumb>
      {/* <Prompt when={Object.keys(dirtyFields).length > 0} message="There are unsaved changes. Are you sure you want to leave?" /> */}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Form onSubmit={handleSubmit(saveEntity)}>
        <Row>
          <Col md="8">
            <h2 id="coconApp.biologicalModel.home.createOrEditLabel" data-cy="MaterialPropertiesCreateUpdateHeading">
              <Translate contentKey="coconApp.biologicalModel.home.createOrEditLabel">Create or edit a Biological Model</Translate>
            </h2>
          </Col>
        </Row>

        <Card className="biological-model">
          <CardBody>
            <CardTitle tag="h5">Biological Model</CardTitle>
            <Row>
              <Col md="2">
                <ValidatedField
                  label={translate('coconApp.biologicalModel.animalOrCell')}
                  id="biological-model-animalOrCell"
                  name="animalOrCell"
                  data-cy="animalOrCell"
                  type="select"
                  register={register}
                  error={errors.animalOrCell as FieldError}
                  isTouched={touchedFields.animalOrCell}
                  isDirty={dirtyFields.animalOrCell}
                >
                  {animalOrCellValues.map(animalOrCell => (
                    <option value={animalOrCell} key={animalOrCell}>
                      {translate('coconApp.AnimalOrCell.' + animalOrCell)}
                    </option>
                  ))}
                </ValidatedField>
              </Col>
              <Col md="3">
                <TypeaheadField
                  id="biological-model-origin"
                  name="origins"
                  control={control}
                  label={translate('coconApp.biologicalModel.origins')}
                  multiple
                  labelKey="name"
                  options={origins}
                />
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalCreateEntity createEntity={createOriginEntity} entityKey="origin" />
                </FormGroup>
              </Col>
              <Col md="3">
                <TypeaheadField
                  id="biological-model-organ"
                  name="organs"
                  control={control}
                  label={translate('coconApp.biologicalModel.organs')}
                  multiple
                  labelKey="name"
                  options={organs}
                />
              </Col>

              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalCreateEntity createEntity={createOrganEntity} entityKey="organ" />
                </FormGroup>
              </Col>
              <Col md="2">
                <TypeaheadField
                  id="biological-model-types"
                  name="types"
                  control={control}
                  label={translate('coconApp.biologicalModel.types')}
                  multiple
                  labelKey="name"
                  options={tissueTypes}
                />
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalCreateEntity createEntity={createTissueTypeEntity} entityKey="tissueType" />
                </FormGroup>
              </Col>
              <Col md="2">
                <ValidatedField
                  label={translate('coconApp.biologicalModel.culture')}
                  id="biological-model-culture"
                  name="culture"
                  data-cy="culture"
                  type="select"
                  register={register}
                  error={errors.culture as FieldError}
                  isTouched={touchedFields.culture}
                  isDirty={dirtyFields.culture}
                >
                  {cultureValues.map(culture => (
                    <option value={culture} key={culture}>
                      {translate('coconApp.Culture.' + culture)}
                    </option>
                  ))}
                </ValidatedField>
              </Col>
              <Col md="2">
                <ValidatedField
                  label={translate('coconApp.biologicalModel.exposurePathway')}
                  id="biological-model-exposurePathway"
                  name="exposurePathway"
                  data-cy="exposurePathway"
                  type="select"
                  register={register}
                  error={errors.exposurePathway as FieldError}
                  isTouched={touchedFields.exposurePathway}
                  isDirty={dirtyFields.exposurePathway}
                >
                  <option value="" key="0" />

                  {exposurePathwayValues.map(exposurePathway => (
                    <option value={exposurePathway} key={exposurePathway}>
                      {translate('coconApp.ExposurePathway.' + exposurePathway)}
                    </option>
                  ))}
                </ValidatedField>
              </Col>
              <Col md="2">
                <TypeaheadField
                  id="biological-model-application"
                  name="application"
                  control={control}
                  label={translate('coconApp.biologicalModel.application')}
                  labelKey="name"
                  options={applications}
                />
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <ModalCreateEntity createEntity={createApplicationEntity} entityKey="application" />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardHeader>
            <Translate contentKey="coconApp.biologicalModel.treatmentDesign" />
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="2">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.biologicalModel.exposurePerDay')}
                  id="biological-model-exposurePerDay"
                  name="exposurePerDay"
                  data-cy="exposurePerDay"
                  register={register}
                  error={errors.exposurePerDay as FieldError}
                  isTouched={touchedFields.exposurePerDay}
                  isDirty={dirtyFields.exposurePerDay}
                  inputGroupText={translate('coconApp.biologicalModel.exposurePerDayUnit')}
                />
              </Col>
              <Col md="2">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.biologicalModel.exposurePerWeek')}
                  id="biological-model-exposurePerWeek"
                  name="exposurePerWeek"
                  data-cy="exposurePerWeek"
                  register={register}
                  error={errors.exposurePerWeek as FieldError}
                  isTouched={touchedFields.exposurePerWeek}
                  isDirty={dirtyFields.exposurePerWeek}
                  inputGroupText={translate('coconApp.biologicalModel.exposurePerWeekUnit')}
                />
              </Col>
              <Col md="2">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.biologicalModel.totalRepeats')}
                  id="biological-model-totalRepeats"
                  name="totalRepeats"
                  data-cy="totalRepeats"
                  register={register}
                  error={errors.totalRepeats as FieldError}
                  isTouched={touchedFields.totalRepeats}
                  inputGroupText={translate('coconApp.biologicalModel.totalRepeatsUnit')}
                />
              </Col>
              <Col md="2">
                <ValidatedFieldInputGroup
                  label={translate('coconApp.biologicalModel.postExposure')}
                  id="biological-model-postExposure"
                  name="postExposure"
                  data-cy="postExposure"
                  type="number"
                  step="any"
                  register={register}
                  error={errors.postExposure as FieldError}
                  isTouched={touchedFields.postExposure}
                  isDirty={dirtyFields.postExposure}
                  inputGroupText={translate('coconApp.biologicalModel.postExposureUnit')}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody>
            <Row>
              <Col md="2">
                <TypeaheadField
                  label={translate('coconApp.biologicalModel.effectiveDoseLabel')}
                  id="biological-model-effectiveDoseLabel"
                  name="effectiveDoseLabel"
                  control={control}
                  allowNew
                  options={effectiveDoseLabelValues}
                />
              </Col>
              <Col md="2">
                <ValidatedField
                  label={translate('coconApp.biologicalModel.effectiveDoseValue')}
                  id="biological-model-effectiveDoseValue"
                  name="effectiveDoseValue"
                  data-cy="effectiveDoseValue"
                  type="text"
                  register={register}
                  error={errors.effectiveDoseValue as FieldError}
                  isTouched={touchedFields.effectiveDoseValue}
                  isDirty={dirtyFields.effectiveDoseValue}
                />
              </Col>
              <Col md="2">
                <TypeaheadField
                  label={translate('coconApp.biologicalModel.effectiveDoseUnit')}
                  id="biological-model-effectiveDoseUnit"
                  name="effectiveDoseUnit"
                  control={control}
                  allowNew
                  options={effectiveDoseUnitValues}
                />
              </Col>
              <Col md="3">
                <TypeaheadField
                  id="biological-model-storageSites"
                  name="storageSites"
                  control={control}
                  label={translate('coconApp.biologicalModel.storageSites')}
                  multiple
                  labelKey="name"
                  options={storageSites}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <TypeaheadField
                  label={translate('coconApp.biologicalModel.cotreatment')}
                  id="biological-model-cotreatment"
                  name="cotreatment"
                  control={control}
                  allowNew
                  options={cotreatmentValues}
                />
              </Col>
              <Col md="2">
                <TypeaheadField
                  label={translate('coconApp.biologicalModel.guidelines')}
                  id="biological-model-guidelines"
                  name="guidelines"
                  control={control}
                  allowNew
                  options={guidelinesValues}
                />
              </Col>
              <Col></Col>
              <Col xs="auto" className="ms-auto" style={{ alignSelf: 'end' }}>
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </>
  );
};

export default BiologicalModelUpdate;
