/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Dataset from './dataset';
import DatasetDetail from './dataset-detail';
import DatasetUpdate from './dataset-update';
import DatasetDeleteDialog from './dataset-delete-dialog';
import DatasetDuplicateDialog from './dataset-duplicate-dialog';

import ApprovalHistory from '../approval-history/approval-history';
import { EffectCategory, EffectCategoryPath } from 'app/shared/model/enumerations/effect-category.model';
import MaterialPropertiesUpdate from '../material-properties/material-properties-update';
import BiologicalModelUpdate from '../biological-model/biological-model-update';
import CommentUpdate from '../comment/comment-update';
import CommentDetail from '../comment/comment-detail';
import CommentDeleteDialog from '../comment/comment-delete-dialog';
import Comment from '../comment/comment';
import EffectUpdate from './effect/effect-update';
import Effect from './effect/effect';
import EffectDeleteDialog from './effect/effect-delete-dialog';

const DatasetRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Dataset />} />
    <Route path="new" element={<DatasetUpdate />} />
    <Route path=":id">
      <Route index element={<DatasetDetail />} />
      <Route path="edit" element={<DatasetUpdate />} />
      <Route path="material-properties">
        <Route path="new" element={<MaterialPropertiesUpdate />} />
        <Route path=":materialPropertiesId">
          <Route path="edit" element={<MaterialPropertiesUpdate />} />
        </Route>
      </Route>
      <Route path="biological-model">
        <Route path="new" element={<BiologicalModelUpdate />} />
        <Route path=":biologicalModelId">
          <Route path="edit" element={<BiologicalModelUpdate />} />
        </Route>
      </Route>
      <Route path="comment">
        <Route index element={<Comment />} />
        <Route path="new" element={<CommentUpdate />} />
        <Route path=":commentId">
          <Route index element={<CommentDetail />} />
          <Route path="edit" element={<CommentUpdate />} />
          <Route path="delete" element={<CommentDeleteDialog />} />
        </Route>
      </Route>
      <Route path="approval-history" element={<ApprovalHistory />} />
      <Route path="delete" element={<DatasetDeleteDialog />} />
      <Route path="duplicate" element={<DatasetDuplicateDialog />} />

      {Object.keys(EffectCategory).map((effectCategory: EffectCategory) => (
        <Route key={effectCategory} path={`${EffectCategoryPath[effectCategory]}`}>
          <Route path={`new`} element={<EffectUpdate category={effectCategory} />} />
          <Route path={`:effectId/edit`} element={<EffectUpdate category={effectCategory} />} />
          <Route index element={<Effect category={effectCategory} />} />
          <Route path={`:effectId/delete`} element={<EffectDeleteDialog />} />
        </Route>
      ))}
    </Route>
  </ErrorBoundaryRoutes>
);

export default DatasetRoutes;
