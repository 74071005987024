import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { getFilterState, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './publication.reducer';
import { omit } from 'lodash';

export const Publication = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'authors'), pageLocation.search),
  );

  const [filterState, setFilterState] = useState(getFilterState(pageLocation.search));

  const filterQueryString = Object.entries(filterState)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const publicationList = useAppSelector(state => state.publication.entities);
  const loading = useAppSelector(state => state.publication.loading);
  const totalItems = useAppSelector(state => state.publication.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: filterQueryString,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&${filterQueryString}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, filterQueryString]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
    setFilterState(getFilterState(pageLocation.search));
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleFilterChanged = (key: string) => event => {
    event.preventDefault();
    const value = event.target[key].value;
    const objectKey = `${key}.contains`;

    if (value && value.length > 0) {
      setFilterState({
        ...filterState,
        [objectKey]: value,
      });
    } else {
      setFilterState(omit(filterState, objectKey));
    }
  };

  const clearFilters = () => {
    setFilterState({});
  };

  return (
    <div>
      <h2 id="publication-heading" data-cy="PublicationHeading">
        <Translate contentKey="coconApp.publication.home.title">Publications</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="coconApp.publication.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/publication/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="coconApp.publication.home.createLabel">Create new Publication</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <Table size="sm" responsive>
          <thead>
            <tr>
              <th className="hand">
                <span style={{ paddingRight: '1em' }} onClick={sort('authors')}>
                  <Translate contentKey="coconApp.publication.authors">Authors</Translate> <FontAwesomeIcon icon="sort" />
                </span>
                <form style={{ display: 'inline' }} onSubmit={handleFilterChanged('authors')}>
                  <Input
                    style={{ display: 'inline', width: 'unset' }}
                    name="authors"
                    defaultValue={filterState['authors.contains']}
                    bsSize="sm"
                    placeholder="Filter..."
                  />
                </form>
              </th>
              <th className="hand" onClick={sort('year')}>
                <Translate contentKey="coconApp.publication.year">Year</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand">
                <span style={{ paddingRight: '1em' }} onClick={sort('doi')}>
                  <Translate contentKey="coconApp.publication.doi">Doi</Translate> <FontAwesomeIcon icon="sort" />
                </span>
                <form style={{ display: 'inline' }} onSubmit={handleFilterChanged('doi')}>
                  <Input
                    style={{ display: 'inline', width: 'unset' }}
                    name="doi"
                    defaultValue={filterState['doi.contains']}
                    bsSize="sm"
                    placeholder="Filter..."
                  />
                </form>
              </th>
              <th />
            </tr>
          </thead>
          {publicationList && publicationList.length > 0 && (
            <tbody>
              {publicationList.map((publication, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{publication.authors}</td>
                  <td>{publication.year}</td>
                  <td>
                    <a href={`https://doi.org/${publication.doi}`} target="_blank" rel="noreferrer">
                      {publication.doi}
                    </a>
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/dataset?publicationId.equals=${publication.id}`}
                        color="primary"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Show Datasets</span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${publication.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="info"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/publication/${publication.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {!(publicationList && publicationList.length > 0) && !loading ? (
          <div className="alert alert-warning">
            <Translate contentKey="coconApp.publication.home.notFound">No Publications found</Translate>
          </div>
        ) : null}
      </div>
      {totalItems ? (
        <div className={publicationList && publicationList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Publication;
