import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Organ from './organ';
import OrganDetail from './organ-detail';
import OrganUpdate from './organ-update';
import OrganDeleteDialog from './organ-delete-dialog';

const OrganRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Organ />} />
    <Route path="new" element={<OrganUpdate />} />
    <Route path=":id">
      <Route index element={<OrganDetail />} />
      <Route path="edit" element={<OrganUpdate />} />
      <Route path="delete" element={<OrganDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrganRoutes;
