import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';

import { getEntities } from './approval-history.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ApprovalHistory = () => {
  const dispatch = useAppDispatch();

  const { id: datasetId } = useParams<'id'>();

  const approvalHistoryList = useAppSelector(state => state.approvalHistory.entities);
  const loading = useAppSelector(state => state.approvalHistory.loading);

  useEffect(() => {
    dispatch(getEntities({ datasetId }));
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem active tag={Link} to={`/dataset${location.search}`}>
          Datasets
        </BreadcrumbItem>
        <BreadcrumbItem active tag={Link} to={`/dataset/${datasetId}/edit${location.search}`}>
          {datasetId}
        </BreadcrumbItem>
      </Breadcrumb>
      <h2 id="approval-history-heading" data-cy="ApprovalHistoryHeading">
        <Translate contentKey="coconApp.approvalHistory.home.title">Approval Histories</Translate>
      </h2>
      <div className="table-responsive">
        {approvalHistoryList && approvalHistoryList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="coconApp.approvalHistory.timestamp">Timestamp</Translate>
                </th>
                <th>
                  <Translate contentKey="coconApp.approvalHistory.approvalState">Approval State</Translate>
                </th>
                <th>
                  <Translate contentKey="coconApp.approvalHistory.user">User</Translate>
                </th>
              </tr>
            </thead>
            <tbody>
              {approvalHistoryList.map((approvalHistory, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    {approvalHistory.timestamp ? (
                      <TextFormat type="date" value={approvalHistory.timestamp} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    <Translate contentKey={`coconApp.ApprovalState.${approvalHistory.approvalState}`} />
                  </td>
                  <td>
                    {approvalHistory.user?.firstName} {approvalHistory.user?.lastName}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="coconApp.approvalHistory.home.notFound">No Approval Histories found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ApprovalHistory;
