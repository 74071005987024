/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { EffectCategory } from 'app/shared/model/enumerations/effect-category.model';

import { getEntities } from 'app/entities/dataset/effect/effect.reducer';

export type EffectProps = {
  category: EffectCategory;
};

export const Effect = (props: EffectProps) => {
  const dispatch = useAppDispatch();

  const { id: datasetId } = useParams<'id'>();

  // TODO filter by category, when getting the entities?
  const effectList = useAppSelector(state => state.effect.entities).filter(effect => effect.name.category === props.category);
  const loading = useAppSelector(state => state.effect.loading);

  useEffect(() => {
    dispatch(getEntities({ datasetId }));
  }, []);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem active tag={Link} to={`/dataset${location.search}`}>
          Datasets
        </BreadcrumbItem>
        <BreadcrumbItem active tag={Link} to={`/dataset/${datasetId}/edit${location.search}`}>
          {datasetId}
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardHeader tag="h5" className={`${props.category}-effect-header`}>
          <Translate contentKey={`coconApp.EffectCategory.${props.category}`}></Translate>{' '}
          <Translate contentKey={`coconApp.effect.home.title`}></Translate>
        </CardHeader>
        <CardBody>
          {effectList && effectList.length > 0 ? (
            <Table size="sm" responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey={`coconApp.effect.name`}></Translate>
                  </th>
                  <th>
                    <Translate contentKey={`coconApp.effect.value`}>Value</Translate>
                  </th>
                  <th>
                    <Translate contentKey={`coconApp.effect.observedLevel`}>Observed Level</Translate>
                  </th>
                  <th>
                    <Translate contentKey={`coconApp.effect.comment`}>Comment</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {effectList.map((effect, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{effect.name.name}</td>
                    <td>{effect.value}</td>
                    <td>
                      <Translate contentKey={`coconApp.ObservedAdverseEffectLevel.${effect.observedLevel}`} />
                    </td>
                    <td>{effect.comment}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${effect.id}/edit${location.search}`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${effect.id}/delete${location.search}`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey={`coconApp.effect.home.notFound`}></Translate>
              </div>
            )
          )}

          <span className="d-flex justify-content-end">
            <Link
              to={`new${location.search}`}
              className="btn btn-primary jh-create-entity"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey={`coconApp.effect.home.createLabel`}></Translate>
            </Link>
          </span>
        </CardBody>
      </Card>
    </div>
  );
};

export default Effect;
