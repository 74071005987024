import publication from 'app/entities/publication/publication.reducer';
import relevanceCriteria from 'app/entities/relevance-criteria/relevance-criteria.reducer';
import animalSpecies from 'app/entities/animal-species/animal-species.reducer';
import cellCulture from 'app/entities/cell-culture/cell-culture.reducer';
import referenceMaterial from 'app/entities/reference-material/reference-material.reducer';
import technicalApplication from 'app/entities/technical-application/technical-application.reducer';
import dataset from 'app/entities/dataset/dataset.reducer';
import advancedMaterial from 'app/entities/advanced-material/advanced-material.reducer';
import materialSource from 'app/entities/material-source/material-source.reducer';
import tissueType from 'app/entities/tissue-type/tissue-type.reducer';
import materialProperties from 'app/entities/material-properties/material-properties.reducer';
import origin from 'app/entities/origin/origin.reducer';
import organ from 'app/entities/organ/organ.reducer';
import application from 'app/entities/application/application.reducer';
import biologicalModel from 'app/entities/biological-model/biological-model.reducer';
import assay from 'app/entities/assay/assay.reducer';
import effect from 'app/entities/dataset/effect/effect.reducer';
import effectName from 'app/entities/effect-name/effect-name.reducer';
import pathwayOfToxicity from 'app/entities/pathway-of-toxicity/pathway-of-toxicity.reducer';
import adverseOutcomePathway from 'app/entities/adverse-outcome-pathway/adverse-outcome-pathway.reducer';
import comment from 'app/entities/comment/comment.reducer';
import dataImport from 'app/entities/data-import/data-import.reducer';
import approvalHistory from 'app/entities/approval-history/approval-history.reducer';
import storageSite from 'app/entities/storage-site/storage-site.reducer';
import searchQuery from 'app/entities/search-query/search-query.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  publication,
  relevanceCriteria,
  animalSpecies,
  cellCulture,
  referenceMaterial,
  technicalApplication,
  dataset,
  advancedMaterial,
  materialSource,
  tissueType,
  materialProperties,
  origin,
  organ,
  application,
  biologicalModel,
  assay,
  effect,
  effectName,
  pathwayOfToxicity,
  adverseOutcomePathway,
  comment,
  dataImport,
  approvalHistory,
  storageSite,
  searchQuery,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
