import { IDataset } from 'app/shared/model/dataset.model';

export interface IPublication {
  id?: number;
  authors?: string;
  year?: number;
  doi?: string;
  datasets?: IDataset[] | null;
}

export const defaultValue: Readonly<IPublication> = {};
