import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RelevanceCriteria from './relevance-criteria';
import RelevanceCriteriaUpdate from './relevance-criteria-update';
import RelevanceCriteriaDeleteDialog from './relevance-criteria-delete-dialog';

const RelevanceCriteriaRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RelevanceCriteria />} />
    <Route path="new" element={<RelevanceCriteriaUpdate />} />
    <Route path=":id">
      <Route path="edit" element={<RelevanceCriteriaUpdate />} />
      <Route path="delete" element={<RelevanceCriteriaDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RelevanceCriteriaRoutes;
