export enum MaterialClassification {
  HARN = 'HARN',

  GBP = 'GBP',

  GRAS = 'GRAS',

  IARC_2A = 'IARC_2A',

  IARC_2B = 'IARC_2B',

  IARC_3 = 'IARC_3',
}
