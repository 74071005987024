/* eslint-disable no-console */
import React from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import Typeahead, { TypeaheadComponentProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import { getOptionLabel } from 'react-bootstrap-typeahead/types/utils';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { InputGroup, InputGroupText, Label } from 'reactstrap';

type TypeaheadFieldProps = TypeaheadComponentProps & {
  id: string;
  name: string;
  control: Control<FieldValues, any>;
  label?;
  valueAsNumber?;
  transformResult?;
  rules?;
  inputGroupText?: string;
};

function transformResultDefault(e, valueAsNumber) {
  const result = e.map(entry => {
    if (entry.customOption) {
      return { value: valueAsNumber ? parseFloat(entry.label) : entry.label, name: entry.label };
    } else {
      return entry;
    }
  });
  return result;
}

export const TypeaheadFieldMultiEdit = ({
  id,
  control,
  name,
  rules,
  inputGroupText,
  label,
  transformResult = transformResultDefault,
  ...typeaheadProps
}: TypeaheadFieldProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <>
          {label && (
            <Label htmlFor={id} className="form-label">
              {label}
            </Label>
          )}
          <InputGroup>
            <Typeahead
              {...field}
              flip
              id={id}
              data-cy={name}
              className={fieldState.invalid ? 'is-invalid' : ''}
              aria-describedby={`${id}-typeaheadError`}
              newSelectionPrefix="Add new entry: "
              emptyLabel={'Start typing to add a new entry'}
              clearButton
              onChange={e => field.onChange(transformResult(e, typeaheadProps.valueAsNumber))}
              isValid={fieldState.isTouched && !fieldState.error}
              {...typeaheadProps}
              defaultSelected={
                typeaheadProps.defaultSelected &&
                (typeaheadProps.defaultSelected[0] === null || typeaheadProps.defaultSelected[0] === undefined)
                  ? []
                  : typeaheadProps.defaultSelected
              }
              inputProps={typeaheadProps.inputProps}
              multiple
              allowNew
            ></Typeahead>
            {inputGroupText && <InputGroupText>{inputGroupText}</InputGroupText>}
          </InputGroup>
          <p id={`${id}-typeaheadError`} className="invalid-feedback">
            {fieldState.error?.message}
          </p>
        </>
      )}
    />
  );
};
